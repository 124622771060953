import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React, { useState } from 'react';
import SocialAsistanceCategories from './modules/views/SocialAsistanceCategories';
import ProductSmokingHero from './modules/views/ProductSmokingHero';
import AppFooter from './modules/views/AppFooter';
import MainVisitCardComponent from './modules/views/MainVisitCardComponent';
import AdoptedChildrenReport from './modules/views/AdoptedChildrenReport';
import MainAppBar from './modules/views/MainAppBar';

function Main(props) {
  const [portalAPI] = useState(props.portalAPI)
  const [keycloakAPI] = useState(props.keycloakAPI)
  return (
    <React.Fragment>
      <MainAppBar />
      <MainVisitCardComponent
        portalAPI={portalAPI}
        keycloakAPI={keycloakAPI}
      />

      {/* <SocialAsistanceCategories/> */}
      <AdoptedChildrenReport
        portalAPI={portalAPI}
        keycloakAPI={keycloakAPI}
      />
      {/* <ProductSmokingHero/> */}
      <AppFooter />
    </React.Fragment>
  )
}
export default withRoot(Main);