import React, {useState, useEffect} from 'react';
import Card from '@material-ui/core/Card';
import MTable from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
var request = require("request-promise")

export default (props) => {
  const [portalAPI] = useState(props.portalAPI)
  const [keycloakAPI] = useState(props.keycloakAPI)
  // Отображение данных анкеты ребенка
  const [viewAddress, setViewAddress] = useState(null)
  const [childData, setChildData] = useState(null)
  const [childPhoto, setChildPhoto] = useState(null)

  useEffect(()=>{
    console.log("CHILD DATA Props", props)
    // setChildData(props.childData)
    // setChildPhoto(props.childPhoto)
    setViewAddress(props.viewAddress)
    fetchChildData(props.childId)
    fetchChildPhoto(props.childId)
  },[])

  async function fetchChildData(childId){
    // console.log("FETCH CHILD DATA", childId)
    await request(
      {
        "headers": { "content-type": "application/json" },
        "url": portalAPI + "/ChildrenAdoptionApi/api/AvailableChildren/GetChildDetails?childId=" + childId,
        "method": "GET"
      }
    )
    .then (function (response){
      let parsedData = JSON.parse(response)
      // console.log("CHILD", parsedData)
      setChildData(parsedData)
    })
    .catch(function (error){
      return console.log("Request error: ", error)
    })
  }
  function fetchChildPhoto(childId){
    // console.log("FETCH CHILD PHOTO", childId)
    setChildPhoto(portalAPI + "/ChildrenAdoptionApi/api/AvailableChildren/GetPhoto?childId="+childId)
  }
  // console.log("CAND PROPS", props)
  if(childData !== null){
    return(
      <Grid>
        <Card>
          <MTable size="small">
            <TableHead style={{backgroundColor: "#282828"}}>
              <TableRow>
                <TableCell style={{fontWeight:"bold", color:"white", fontSize: 16}}>Данные ребенка</TableCell>
                <TableCell style={{fontWeight:"bold"}}></TableCell>
              </TableRow>
            </TableHead>
          </MTable>
          <MTable size="small">
            <TableHead>
            <TableRow>
                <TableCell style={{fontWeight:"bold", fontSize: 16}}>УСР ребенка</TableCell>
                <TableCell style={{fontWeight:"bold", fontSize: 16}}>{childData.OrgName}</TableCell>
              </TableRow>
            </TableHead>
          </MTable>
          <MTable>
            <TableBody>
              <TableRow>
                <TableCell>ПИН</TableCell>
                <TableCell>{childData.PIN}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Дата регистрации</TableCell>
                <TableCell>{childData.RegDate}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Фамилия</TableCell>
                <TableCell>{childData.LastName}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Имя</TableCell>
                <TableCell>{childData.FirstName}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Возраст</TableCell>
                <TableCell>{childData.Age}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Пол</TableCell>
                <TableCell>{childData.Gender}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Национальность</TableCell>
                <TableCell>{childData.Nationality}</TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell>Статус сирота</TableCell>
                <TableCell>{childData.OrphanType}</TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell>Возможные формы устройства ребенка</TableCell>
                <TableCell>{childData.PossibleAdoptionType}</TableCell>
              </TableRow>
              
              <TableRow>
                <TableCell>Приметы</TableCell>
                <TableCell>{childData.PrimetyRebenka}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Характер</TableCell>
                <TableCell>{childData.Character}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Диагноз</TableCell>
                <TableCell>{childData.Diagnosis}</TableCell>
              </TableRow>
              {viewAddress === true &&
                <TableRow>
                  <TableCell>Адрес</TableCell>
                  <TableCell>{childData.Address}</TableCell>
                </TableRow>
              }
              {viewAddress === true &&
              <TableRow>
                  <TableCell>Детское учреждение</TableCell>
                  <TableCell>{childData.HouseName}</TableCell>
                </TableRow>
              }
              <TableRow>
                <TableCell style={{backgroundColor: "#D7E3DD"}}>Причины отсутствия родительского попечительства</TableCell>
                <TableCell style={{backgroundColor: "#D7E3DD"}}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Смерть родителей</TableCell>
                <TableCell>
                  <Checkbox
                    style={{maxHeight: 15}}
                    checked={childData.OrphanageReason.OrphanageReason1}
                  />
                  </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Ограничение в родительских правах</TableCell>
                <TableCell>
                  <Checkbox
                    style={{maxHeight: 15}}
                    checked={childData.OrphanageReason.OrphanageReason2}
                  />
                  </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Местонахождение не известно</TableCell>
                <TableCell>
                  <Checkbox
                    style={{maxHeight: 15}}
                    checked={childData.OrphanageReason.OrphanageReason3}
                  />
                  </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Лишение родительских прав</TableCell>
                <TableCell>
                  <Checkbox
                    style={{maxHeight: 15}}
                    checked={childData.OrphanageReason.OrphanageReason4}
                  />
                  </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Заявление о согласии на усыновление/удочерение</TableCell>
                <TableCell>
                  <Checkbox
                    style={{maxHeight: 15}}
                    checked={childData.OrphanageReason.OrphanageReason5}
                  />
                  </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Оставленный/брошенный</TableCell>
                <TableCell>
                  <Checkbox
                    style={{maxHeight: 15}}
                    checked={childData.OrphanageReason.OrphanageReason6}
                  />
                  </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>В связи с признанием родителей недееспособными</TableCell>
                <TableCell>
                  <Checkbox
                    style={{maxHeight: 15}}
                    checked={childData.OrphanageReason.OrphanageReason7}
                  />
                  </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Родители в местах лишения свободы</TableCell>
                <TableCell>
                  <Checkbox
                    style={{maxHeight: 15}}
                    checked={childData.OrphanageReason.OrphanageReason8}
                  />
                  </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Информация о несовершеннолетних братьях и сестрах</TableCell>
                {Object.keys(childData.Brothers).length === 0
                ?
                <TableCell>Отсутствуют</TableCell>
                :
                <TableCell>
                  {childData.Brothers.map(brother =>
                    <TableRow>{brother}</TableRow>
                  )}
                </TableCell>
                }
              </TableRow>
              <TableRow>
                <TableCell>Информация о родителе, опекуна, совершеннолетнем родственнике</TableCell>
                {Object.keys(childData.Relatives).length === 0
                ?
                <TableCell>Отсутствуют</TableCell>
                :
                <TableCell>
                {childData.Relatives.map(relative =>
                  <TableRow>{relative}</TableRow>
                )}
                </TableCell>
                }
              </TableRow>
              <TableRow>
                <TableCell>История событий ребенка</TableCell>
                {Object.keys(childData.Events).length === 0
                ?
                <TableCell>Отсутствует</TableCell>
                :
                <TableCell>
                  {childData.Events.map(event =>
                    <TableRow>{event}</TableRow>
                  )}
                </TableCell>
                }
              </TableRow>
              <TableRow>
                <TableCell>Фото</TableCell>
                {childPhoto !== null 
                  ?
                  <TableCell><img src={childPhoto} alt="Photo" width="auto" height="300"/></TableCell>
                  :
                  <TableCell><CircularProgress/></TableCell>
                }
              </TableRow>
            </TableBody>
          </MTable>
        </Card>
      </Grid> 
    )
  }
  else{
    return(
      <div align="center" style={{paddingTop: 250}}>
        <CircularProgress/>
      </div>
    )
  }
};
