import React, {useState, useEffect} from 'react';
import { makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MTable from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from 'sweetalert' // https://sweetalert.js.org/guides/
import Buttons from '../Buttons.json';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialSelect from '@material-ui/core/Select';
var request = require("request-promise");

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 250
  }
}))

export default (props) => {
  const [portalAPI] = useState(props.portalAPI)
  const [keycloakAPI] = useState(props.keycloakAPI)
  // Результаты встречи
  const [userRole, setUserRole] = useState(null)
  const [userId, setUserId] = useState(null)
  const [fieldValue, setFieldValue] = useState({})
  const [buttons, setButtons] = useState(null)
  const [task, setTask] = useState(10)
  const [meetUpRequestsList, setMeetUpRequestsList] = useState(null)
  // const [meetUpRequestId, setMeetUpRequestId] = useState(null)
  const [meetUpResultsList, setMeetUpResultsList] = useState(null)
  const [meetUpRequestItemId, setMeetUpRequestItemId] = useState(null)
  

  const classes = useStyles()
  useEffect(()=>{
      let uRole = props.userRole
      setUserRole(uRole)
      setUserId(props.userId)
      if(props.taskType === "candidateMeetUpResult"){
        fetchMeetUpRequestsByUserId(props.userId)
        setButtons(Buttons[props.userRole].ViewMeetUpResult)
        setTask(props.taskType)
      }
      else if(props.taskType === "viewCandidateMeetUpResultsByOrgId"){
        fetchMeetUpRequestsByOrgId(props.userOrgId)
        setButtons(Buttons[props.userRole].ViewMeetUpResultsList)
        setTask(props.taskType)
      }
  },[])
  async function fetchMeetUpRequestsByUserId(userId){
    await request(
      {
        "headers": { "content-type": "application/json" },
        "url": portalAPI + "/ChildrenAdoptionApi/api/MeetupRequests/GetByUserId?userId=" + userId,
        "method": "GET"
      }
    )
    .then (function (response){
      let parsedData = JSON.parse(response)
      console.log("MEETUP", parsedData)
      setMeetUpRequestsList(parsedData)
    })
    .catch(function (error){
      return console.log("Request error: ", error)
    })
  }
  async function fetchMeetUpRequestsByOrgId(userOrgId){
    await request(
      {
        "headers": { "content-type": "application/json" },
        "url": portalAPI + "/ChildrenAdoptionApi/api/MeetupRequests/GetVisitResultsByOrgId?orgId=" + userOrgId,
        "method": "GET"
      }
    )
    .then (function (response){
      let parsedData = JSON.parse(response)
      console.log("MEETUP", parsedData)
      setMeetUpResultsList(parsedData)
    })
    .catch(function (error){
      return console.log("Request error: ", error)
    })
  }
  function handleChange(event){
    // console.log("EVENT", event)
    fieldValue[event.target.name] = event.target.value
    setFieldValue(fieldValue)
    // console.log("FIELDVALUE", fieldValue)
  }
  function getMeetUpResultFields(){
    let meetUpFields = {
      MeetupRequestItemId: meetUpRequestItemId,
      RegDate: fieldValue["RegDate"],
      CandidateSolutionId: fieldValue["CandidateSolutionId"],
      RefuseReason: fieldValue["RefuseReason"]
    }
    return meetUpFields
  }
  async function buttonClick(name, candidateMeetUpRequest){  
    console.log("Button ", name, candidateMeetUpRequest) 
    if(name === "fillMeetUpResult"){
      // Заполнить результат встречи
      // let deadline = new Date(candidateMeetUpRequest.Deadline)
      // let remainingTime =  deadline.getTime() - Date.now()
      // console.log("REMAINING", remainingTime)
      // if(remainingTime > 0){
      //   swal({
      //     text: "Вы пока не можете заполнить результат встречи!",
      //     icon: "warning",
      //   })
      // }
      // else{
        for(let i=0; i<candidateMeetUpRequest.MeetupRequestItems.length; i++){
          if(candidateMeetUpRequest.MeetupRequestItems[i].PortalUserId === userId){
            if(candidateMeetUpRequest.MeetupRequestItems[i].DirectionId === null){
              // Проверка скачивал ли кандидат направление
              swal({
                text: "Сначала вы должны скачать направление и посетить ребенка!",
                icon: "warning",
              })
            }
            else{
              // Кандидат скачал направление и посетил ребенка
              // if(i === 0){
                // Кандидат 1й в очереди
                setTask("fillCandidateMeetUpResult")
                setButtons(Buttons[userRole].FillMeetUpResult)
                fieldValue["RegDate"] = parseDateComponent(new Date())
                setFieldValue(fieldValue)
                // setMeetUpRequestId(candidateMeetUpRequest.Id)
                setMeetUpRequestItemId(candidateMeetUpRequest.MeetupRequestItems[i].Id)
              // }
              // else if(i > 0){
              //   // Кандидат 2й и более в очереди
              //   if(candidateMeetUpRequest.MeetupRequestItems[i-1].VisitResult !== null){
              //     // Проверка результата всчтречи предыдущего кандидата
              //     let prevCandSolution = candidateMeetUpRequest.MeetupRequestItems[i-1].VisitResult.CandidateSolutionId
              //     if(prevCandSolution === "0d7921d2-e8ac-40d0-aad6-93ff5bd7be66" || prevCandSolution === "00000000-0000-0000-0000-000000000000"){
              //       // // Предыдущий кандидат отказался
              //       setTask("fillCandidateMeetUpResult")
              //       setButtons(Buttons[userRole].FillMeetUpResult)
              //       fieldValue["RegDate"] = parseDateComponent(new Date())
              //       setFieldValue(fieldValue)
              //       // setMeetUpRequestId(candidateMeetUpRequest.Id)
              //       setMeetUpRequestItemId(candidateMeetUpRequest.MeetupRequestItems[i].Id)
              //     }
              //   }
              //   else{
              //     swal({
              //       text: "Вы пока не можете заполнить результат встречи!",
              //       icon: "warning",
              //     })
              //   }
              // }
              // else{
              //   swal({
              //     text: "Вы пока не можете заполнить результат встречи!",
              //     icon: "warning",
              //   })
              // }
            }
          }
        }
      // }
    }
    else if(name === "saveMeetUpResult"){
      swal({
        text: "ВНИМАНИЕ заполняйте результат встречи только после того, как вы посетили ребенка и приняли окончательное решение, вы уверены, что хотите сохранить результат?",
        icon: "warning",
        buttons: {yes: "Да", cancel: "Отмена"}
      })
      .then((click)=>{
        if(click === "yes"){
          request(
            {
              "headers": { "content-type": "application/json" },
              "url": portalAPI + "/ChildrenAdoptionApi/api/MeetupRequests/GetVisitResult?meetupRequestItemId=" + meetUpRequestItemId,
              "method": "GET"
            }
          )
          .then (function (response){
            let parsedData = JSON.parse(response)
            console.log("MEETUPRES", parsedData)
            if(parsedData === null){
              let meetUpRequestsBody = getMeetUpResultFields()
              console.log("BODY", meetUpRequestsBody)
              if(fieldValue["CandidateSolutionId"] === "0D7921D2-E8AC-40D0-AAD6-93FF5BD7BE66" && 
              (fieldValue["RefuseReason"] === undefined || fieldValue["RefuseReason"] === "")){
                swal({
                  text: "Заполните поле \"Причина отказа\"!",
                  icon: "warning"
                })
              }
              else{
                if(fieldValue["CandidateSolutionId"] !== undefined && fieldValue["CandidateSolutionId"] !== null && fieldValue["CandidateSolutionId"] !== "00000000-0000-0000-0000-000000000000"){
                  request(
                  {
                    "headers": { "content-type": "application/json"},
                    "url": portalAPI + "/ChildrenAdoptionApi/api/MeetupRequests/SetVisitResult",
                    "method": "POST",
                    "body": JSON.stringify(meetUpRequestsBody)
                  })
                  .then (function (response){
                    let parsedData = JSON.parse(response)
                    console.log("RESULTRESP", parsedData)
                    swal({
                      text: "Результат встречи сохранен",
                      icon: "success",
                    })
                  })
                  .catch(function (error){
                    return console.log("Request error: ", error)
                  })
                }
                else{
                  swal({
                    text: "Заполните поле \"Результат\"!",
                    icon: "warning"
                  })
                }
              }
            }
            else{
              swal({
                text: "Вы уже заполняли результат встречи!",
                icon: "warning",
              })
            }
          })
          .catch(function (error){
            return console.log("Request error: ", error)
          })
        }
      })
      
    }
    else if(name === "fillMeetUpResultBack"){
      fetchMeetUpRequestsByUserId(userId)
      setButtons(Buttons[userRole].ViewMeetUpResult)
      setTask("candidateMeetUpResult")
    }
    else{
      console.log("Button ", name, candidateMeetUpRequest)
    }
  }
  function parseDate(date){
    var newDate = new Date(date)
    var dd = String(newDate.getDate()).padStart(2, '0')
    var mm = String(newDate.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = newDate.getFullYear()
    var hour = newDate.getHours()
    var minutes = newDate.getMinutes()
    var convertedDate = dd + '-' + mm + '-' + yyyy + '  ' + hour + ':' + minutes
    return convertedDate
  }
  function parseDateComponent(date){
    var newDate = new Date(date)
    var dd = String(newDate.getDate()).padStart(2, '0')
    var mm = String(newDate.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = newDate.getFullYear()
    var convertedDate = yyyy + '-' + mm + '-' + dd
    return convertedDate
  }
  function getCandidateREsultById(id){
    if(id === "0D7921D2-E8AC-40D0-AAD6-93FF5BD7BE66"){
      return "Отказ от усыновления (удочерения)"
    }
    else{
      return "Согласен на установление усыновления (удочерения), опеки (попечительства)"
    }
  }

  console.log("FIELDS", fieldValue)
  if(meetUpRequestsList !== null && task === "candidateMeetUpResult"){
    if(Object.keys(meetUpRequestsList).length === 0){
      return(
        <div align="center" style={{paddingTop: 250, fontSize: 20}}>
          Список результатов пуст
        </div>
      )
    }
    else{
      console.log("MEETLIST", meetUpRequestsList)
      return(
        meetUpRequestsList.map((meetUpRequest, index) =>{
          return(
            <Grid>
              <Paper>
              <MTable size="small" >
                  <TableHead style={{backgroundColor: "#D7E3DD"}}>
                    <TableRow>
                      <TableCell style={{fontWeight:"bold"}}>№</TableCell>
                      <TableCell style={{fontWeight:"bold"}}>Дата запроса</TableCell>
                      <TableCell style={{fontWeight:"bold"}}>Дата окнчания</TableCell>
                      <TableCell style={{fontWeight:"bold"}}>Количество заявок</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{parseDate(meetUpRequest.CreatedAt)}</TableCell>
                      <TableCell>{parseDate(meetUpRequest.Deadline)}</TableCell>
                      <TableCell>{meetUpRequest.MeetupRequestItems.length}</TableCell>
                    </TableRow>
                  </TableBody>
                </MTable> 
                {buttons.map((button, index)=>
                  <Button
                    key={index}
                    name={button.name}
                    variant="outlined"
                    value={button.name}                               
                    onClick = {() => buttonClick(button.name, meetUpRequest)}
                    style={{
                        margin: 1,
                        fontSize: 11,
                        backgroundColor: button.backgroundColor
                    }}
                  >{button.label}
                  </Button>
                )}
              </Paper>
              <br></br>
            </Grid> 
          )
        })
      )
    }
  }
  else if(task === "fillCandidateMeetUpResult"){
    return(
      <Grid>
        <Grid container direction="row" justify="flex-start" spacing={0}>
          <Grid item xs={10}>
            <Paper>
              <MTable>
                <TableHead style={{backgroundColor: "#282828"}}>
                  <TableRow style={{maxHeight: 25}}>
                    <TableCell style={{fontSize: 16, color: "white"}}>Форма ввода результата встреч</TableCell>
                  </TableRow>
                </TableHead>
              </MTable>
              <MTable>
                <TableBody>
                  <TableRow>
                    <TableCell>Дата</TableCell>
                    <TableCell>
                      <TextField
                        type="date"
                        name = "RegDate"
                        onBlur = {handleChange}
                        style={{minWidth: 250}}
                        disabled
                        defaultValue = {fieldValue["RegDate"]}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Результат</TableCell>
                    <TableCell>
                      <FormControl className={classes.formControl}>
                        <MaterialSelect
                          style={{height:40}}
                          name="CandidateSolutionId"                      
                          value={fieldValue["CandidateSolutionId"]}
                          onChange={handleChange}
                        >
                          <MenuItem value={"46CB0B76-2A82-4E20-9F55-CDFAE5393BDF"}>Согласен на установление усыновления (удочерения), опеки (попечительства)</MenuItem>
                          <MenuItem value={"0D7921D2-E8AC-40D0-AAD6-93FF5BD7BE66"}>Отказ от усыновления (удочерения)</MenuItem>
                        </MaterialSelect>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Причина отказа</TableCell>
                    <TableCell>
                      <TextField
                        multiline
                        name = "RefuseReason"
                        onBlur = {handleChange}
                        style={{minWidth: 250}}
                        defaultValue = {fieldValue["RefuseReason"]}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </MTable>
              <Grid container  direction="row" justify="flex-start" alignItems="flex-start">
                {buttons.map((button, index) =>
                  <Button
                    key={index}
                    name={button.name}
                    variant="outlined"
                    value={button.name}                               
                    onClick = {() => buttonClick(button.name, null)}
                    style={{
                      margin: 3, 
                      backgroundColor: button.backgroundColor,
                      height: 32, 
                      fontSize: 12
                    }}
                  >{button.label}
                  </Button>
                )}
              </Grid>  
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  else if(meetUpResultsList !== null && task === "viewCandidateMeetUpResultsByOrgId"){
    return(
      meetUpResultsList.map((meetUpResults, index) =>{
        return(
          <Grid>
            <Paper>
              <MTable size="small" >
                <TableHead style={{backgroundColor: "#D7E3DD"}}>
                  <TableRow>
                    <TableCell style={{fontWeight:"bold"}}>Дата</TableCell>
                    <TableCell style={{fontWeight:"bold"}}>Результат</TableCell>
                    <TableCell style={{fontWeight:"bold"}}>Причина отказа</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{parseDate(meetUpResults.RegDate)}</TableCell>
                    <TableCell>{getCandidateREsultById(meetUpResults.CandidateSolutionId)}</TableCell>
                    <TableCell>{meetUpResults.RefuseReason}</TableCell>
                  </TableRow>
                </TableBody>
              </MTable>
              {buttons.map((button, index)=>
                <Button
                  key={index}
                  name={button.name}
                  variant="outlined"
                  value={button.name}                               
                  onClick = {() => buttonClick(button.name, meetUpResults)}
                  style={{
                      margin: 1,
                      fontSize: 11,
                      backgroundColor: button.backgroundColor
                  }}
                >{button.label}
                </Button>
              )}
            </Paper>
            <br></br>
          </Grid> 
        )
      })
    )
  }
  else{
    return(
      <div align="center" style={{paddingTop: 250}}>
        <CircularProgress />
      </div>
    )
  }
};
