import React, {useState, useEffect} from 'react';
import Card from '@material-ui/core/Card';
import MTable from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
var request = require("request-promise")

export default (props) => {
  const [portalAPI] = useState(props.portalAPI)
  const [keycloakAPI] = useState(props.keycloakAPI)
  // This.state
  const [candidateData, setCandidateData] = useState(null)

  useEffect(()=>{
    fetchCandidateData(props.candidateId)
  },[])
  async function fetchCandidateData(candidateId){
    // console.log("PIN")
    await request(
      {
        "headers": { "content-type": "application/json" },
        "url": portalAPI + "/ChildrenAdoptionApi/api/PortalUser/GetCandidateById?userId=" + candidateId,
        "method": "GET"
      }
    )
    .then (function (response){
      let parsedData = JSON.parse(response)
      console.log("CANDIDATE", parsedData)
      setCandidateData(parsedData)
    })
    .catch(function (error){
      return console.log("Request error: ", error)
    })
  }
  // console.log("CAND PROPS", props)
  if(candidateData !== null){
    return(
      <Grid>
        <Card>
          <MTable>
            <TableHead style={{backgroundColor: "#282828"}}>
              <TableRow>
                <TableCell style={{fontWeight:"bold", color:"white", fontSize: 16}}>Данные кандидата</TableCell>
              </TableRow>
            </TableHead>
          </MTable>
          <MTable size="small">
            <TableHead>
            <TableRow>
                <TableCell style={{fontWeight:"bold", fontSize: 16}}>УСР кандидата</TableCell>
                <TableCell style={{fontWeight:"bold", fontSize: 16}}>{candidateData.OrgName}</TableCell>
              </TableRow>
            </TableHead>
          </MTable>
          <MTable size="small">
            <TableHead style={{backgroundColor: "#D7E3DD"}}>
              <TableRow>
                <TableCell style={{fontWeight:"bold"}}>Физ. лицо</TableCell>
                <TableCell style={{fontWeight:"bold"}}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>ПИН</TableCell>
                <TableCell>{candidateData.ApplicantPerson.PIN}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell> Дата постановки на учет</TableCell>
                <TableCell>{candidateData.RegDate}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Фамилия</TableCell>
                <TableCell>{candidateData.ApplicantPerson.LastName}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Имя</TableCell>
                <TableCell>{candidateData.ApplicantPerson.FirstName}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Отчество</TableCell>
                <TableCell>{candidateData.ApplicantPerson.MiddleName}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Номер, серия паспорта</TableCell>
                <TableCell>{candidateData.ApplicantPerson.PassportNo}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Дата выдачи</TableCell>
                <TableCell>{candidateData.ApplicantPerson.PassportDate}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Выдавший орган</TableCell>
                <TableCell>{candidateData.ApplicantPerson.PassportOrg}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Телефон</TableCell>
                <TableCell>{candidateData.Telephone}</TableCell>
              </TableRow>
            </TableBody>
          </MTable>
          <MTable size="small">
            <TableHead style={{backgroundColor: "#D7E3DD"}}>
            <TableRow>
                <TableCell style={{fontWeight:"bold"}}>Адрес фактического проживания</TableCell>
                <TableCell style={{fontWeight:"bold"}}></TableCell>
              </TableRow>
              <TableRow>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Область</TableCell>
                <TableCell>{candidateData.AddressDetails.Area}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Район</TableCell>
                <TableCell>{candidateData.AddressDetails.District}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Айылный аймак</TableCell>
                <TableCell>{candidateData.AddressDetails.Settlement}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Населенный пункт</TableCell>
                <TableCell>{candidateData.AddressDetails.Village}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Улица (мкр, ж-м)</TableCell>
                <TableCell>{candidateData.AddressDetails.Street}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Дом (корпус, строение)</TableCell>
                <TableCell>{candidateData.AddressDetails.House}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Квартира</TableCell>
                <TableCell>{candidateData.AddressDetails.Apartment}</TableCell>
              </TableRow>
            </TableBody>
          </MTable>
          <MTable size="small">
            <TableHead style={{backgroundColor: "#D7E3DD"}}>
            <TableRow>
                <TableCell style={{fontWeight:"bold"}}>Супруг (а)</TableCell>
                <TableCell style={{fontWeight:"bold"}}></TableCell>
              </TableRow>
              <TableRow>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>ПИН</TableCell>
                <TableCell>{candidateData.SpousePerson.PIN}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Фамилия</TableCell>
                <TableCell>{candidateData.SpousePerson.LastName}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Имя</TableCell>
                <TableCell>{candidateData.SpousePerson.FirstName}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Отчество</TableCell>
                <TableCell>{candidateData.SpousePerson.MiddleName}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Номер, серия паспорта</TableCell>
                <TableCell>{candidateData.SpousePerson.PassportNo}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Дата выдачи</TableCell>
                <TableCell>{candidateData.SpousePerson.PassportDate}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Выдавший орган</TableCell>
                <TableCell>{candidateData.SpousePerson.PassportOrg}</TableCell>
              </TableRow> 
            </TableBody>
          </MTable>
          <MTable size="small">
            <TableHead style={{backgroundColor: "#D7E3DD"}}>
            <TableRow>
                <TableCell style={{fontWeight:"bold"}}>Сопутствующие данные</TableCell>
                <TableCell style={{fontWeight:"bold"}}></TableCell>
              </TableRow>
              <TableRow>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Место работы, вид деятельности заявителя</TableCell>
                <TableCell>{candidateData.ApplicantJobDetails}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Заявитель работает</TableCell>
                <TableCell>{candidateData.IsApplicantEmployed}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Характер работы заявителя</TableCell>
                <TableCell>{candidateData.ApplicantWorkNature1}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Тип работы заявителя</TableCell>
                <TableCell>{candidateData.ApplicantWorkNature2}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Причины не занятости заявителя</TableCell>
                <TableCell>{candidateData.ApplicantUnemployedReason}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Место работы, вид деятельности супруга (и)</TableCell>
                <TableCell>{candidateData.SpouseJobDetails}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Супруг(а) работает</TableCell>
                <TableCell>{candidateData.IsSpouseEmployed}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Характер работы супруга(и)</TableCell>
                <TableCell>{candidateData.SpouseWorkNature1}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Тип работы супруга(и)</TableCell>
                <TableCell>{candidateData.SpouseWorkNature2}</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell>Причины не занятости супруга (и)</TableCell>
                <TableCell>{candidateData.SpouseUnemployedReason}</TableCell>
              </TableRow>
            </TableBody>
          </MTable>
          <br></br>
        </Card>
      </Grid> 
    )
  }
  else{
    return(
      <div align="center" style={{paddingTop: 250}}>
        <CircularProgress />
      </div>
    )
  }
};
