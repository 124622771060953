import React, { useState, Component, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Grid from "@material-ui/core/Grid";
// import Paper from '@material-ui/core/Paper'; 
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AddCircleIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToAppOutlined';
// import Button from '@material-ui/core/Button';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Notification from "@material-ui/icons/Notifications";
import { useKeycloak } from '../lib';
import swal from 'sweetalert' // https://sweetalert.js.org/guides/
// import some data
import EnumData from '../EnumData.json';
import Menu from '../Menu.json';
// import custom components
import Users from './Users';
import Children from './Children';
// import CandidateRequests from './CandidateRequests';
import CandidateMeetUpRequests from './CandidateMeetUpRequests';
import Notifications from './Notifications';
import MeetUpResults from './MeetUpResults';
import Orders from './Orders';
import ScriptTester from './ScriptTester';


var request = require("request-promise")

const drawerWidth = 260;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: "#272724"
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  }
}))
const useStylesnav = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  listItemText: {
    fontSize: 14
  },
  level1: {
    maxHeight: 25,
    color: "inherit"
  },
  level2: {
    paddingLeft: theme.spacing(2),
    maxHeight: 25,
    color: "inherit"
  },
  level3: {
    paddingLeft: theme.spacing(3),
    maxHeight: 25,
    color: "inherit"
  },
  tabs: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  }
}))

export default (props) => {
  const { keycloak } = useKeycloak()
  const [portalAPI] = useState(props.portalAPI)
  const [keycloakAPI] = useState(props.keycloakAPI)
  // This.state
  const [routes, setRoutes] = useState([])
  const [opennav, setOpennav] = useState(false)
  const [open, setOpen] = useState(true)
  const [menuItemStates, setMenuItemStates] = useState([])
  const [userOrgId, setUserOrgId] = useState(null)
  const [userId, setUserId] = useState(null)
  const [userRole, setUserRole] = useState(null)
  const [userName, setUserName] = useState(null)
  const [userPIN, setUserPIN] = useState(null)
  const [task, setTask] = useState(null)
  const [userStatus, setUserStatus] = useState("-")
  const [userStatusDate, setUserStatusDate] = useState("-")
  const [unreadNotifications, setUnreadNotifications] = useState(0)
  const [userExpired, setUserExpired] = useState(null)

  const classes = useStyles()
  const classesnav = useStylesnav()
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }
  // Getting authenticated user profile
  useEffect(() => {
    // didMount in hooks
    let curTask = localStorage.getItem("task") || []
    // console.log('KEYCLOAK', keycloak)

    setTask(curTask)
    keycloak.loadUserProfile().success(function (profile) {
      // let curUserId = profile.id
      let curUserRole = profile.attributes.userRole[0]
      let curUserOrgId = profile.attributes.OrgId[0]
      let expiredDate = profile.attributes.expired[0]
      let curUserPIN = profile.username
      let curUserFirstName = profile.firstName
      let curUserLastName = profile.lastName
      let curUserName = curUserFirstName + " " + curUserLastName.substring(0, 1) + "."
      let routes = Menu[curUserRole]
      if (curUserRole === "operator" || curUserRole === "boardingHouse") {
        getUnreadCount(curUserOrgId, curUserRole)
      }
      setUserPIN(curUserPIN)
      setUserRole(curUserRole)
      setUserOrgId(curUserOrgId)
      setUserName(curUserName)
      setRoutes(routes)
      checkForDisabledUser(expiredDate)
      if (curUserRole === "candidate") {
        getUserStatus()
      }
      console.log('PROFILE: ', profile)
      let updatedMenuItemStates = menuItemStates.slice()
      for (let i = 0; i < routes.length; i++) {
        if (routes[i].level2 !== undefined) {
          // console.log("menuItemStates", routes[i].level2)
          let name = routes[i].name
          let state = routes[i].state
          let newMenuItem = {
            name: name,
            state: state
          }
          updatedMenuItemStates.push(newMenuItem)
          for (let l = 0; l < routes[i].level2.length; l++) {
            if (routes[i].level2[l].level3 !== undefined) {
              let name = routes[i].level2[l].name
              let newMenuItem2 = {
                name: name,
                state: state
              }
              updatedMenuItemStates.push(newMenuItem2)
            }
          }
        }
      }
      setMenuItemStates(updatedMenuItemStates)
      if (curUserRole === "admin" || curUserRole === "operator") {
        checkForCandidatesToBeBlocked(curUserOrgId)
      }

    }).error(function () {
      console.log('Failed to load user profile')
    })
    keycloak.loadUserInfo().success(function (info) {
      let curUserId = info.sub
      setUserId(curUserId)

      console.log('INFO', info)
    }).error(function () {
      console.log('Failed to load user Info')
    })

  }, [])
  async function checkForCandidatesToBeBlocked(orgId) {
    await request(
      {
        headers: {
          "Authorization": "Bearer " + keycloak.token,
          "Content-Type": "application/x-www-form-urlencoded"
        },
        url: keycloakAPI + "/auth/admin/realms/PortalSA/users/?max=20000",
        method: "GET"
      }
    )
      .then(async function (response) {
        var users = JSON.parse(response)
        // console.log("USERS", users)
        await request(
          {
            "headers": { "content-type": "application/json" },
            "url": portalAPI + "/ChildrenAdoptionApi/api/AdoptionOrders/GetByOrgId?orgId=" + orgId,
            "method": "GET"
          }
        )
          .then(async function (response) {
            let orders = JSON.parse(response)
            // console.log("ORDERS", orders)
            for (let i = 0; i < users.length; i++) {
              if (users[i].attributes.userRole[0] === "candidate") {
                for (let l = 0; l < orders.length; l++) {
                  // console.log("ID", orders[l].MeetupRequestItem.PortalUserId, users[i].id)
                  if (users[i].id === orders[l].MeetupRequestItem.PortalUserId) {
                    // console.log("BLOCK THIS", users[i].username, users[i].firstName, users[i].lastName)
                    if (orders[l].IsRejected === false) {
                      let orderDeadline = new Date(orders[l].Date)
                      let orderRemainingTime = orderDeadline.getTime() - Date.now()

                      let userExpiredDate = new Date(users[i].attributes.expired[0])
                      let userRemainingTime = userExpiredDate.getTime() - Date.now()
                      // console.log("userRemainingTime", userExpiredDate.getTime(), Date.now())
                      if (userRemainingTime > 432000000) {
                        if (orderRemainingTime < 0) {
                          // console.log("BLOCK THIS", users[i].username, users[i].firstName, users[i].lastName)

                          var newDate = new Date() // Y M D
                          var dd = String(newDate.getDate()).padStart(2, '0')
                          var mm = String(newDate.getMonth() + 1).padStart(2, '0') //January is 0!
                          var yyyy = newDate.getFullYear()
                          if (parseInt(mm) === 2) {
                            if (parseInt(dd) >= 28) {
                              dd = 3
                              mm = 3
                            }
                            else {
                              dd = parseInt(dd) + 3
                            }
                          }
                          else if (parseInt(mm) === 12) {
                            if (parseInt(dd) >= 29) {
                              dd = 2
                              mm = 1
                              yyyy = parseInt(yyyy) + 1
                            }
                            else {
                              dd = parseInt(dd) + 3
                            }
                          }
                          else if (parseInt(mm) !== 2 && parseInt(mm) !== 12) {
                            if (parseInt(dd) >= 28) {
                              dd = 2
                              mm = parseInt(mm) + 1
                            }
                            else {
                              dd = parseInt(dd) + 3
                            }
                          }
                          var newUserExpiredDate = yyyy + '-' + mm + '-' + dd
                          console.log("BLDATE", newUserExpiredDate)

                          var userData = {
                            firstName: users[i].FirstName,
                            lastName: users[i].LastName,
                            email: users[i].email,
                            emailVerified: true,
                            enabled: true,
                            attributes:
                            {
                              locale: "ru",
                              userRole: "candidate",
                              OrgId: users[i].attributes.OrgId[0],
                              address: users[i].attributes.address[0],
                              phone: users[i].attributes.phone[0],
                              expired: newUserExpiredDate
                            }
                          }
                          await request(
                            {
                              headers: {
                                "Authorization": "Bearer " + keycloak.token,
                                "Content-Type": "application/json"
                              },
                              url: keycloakAPI + "/auth/admin/realms/PortalSA/users/" + users[i].id,
                              method: "PUT",
                              body: JSON.stringify(userData)
                            }
                          )
                            .then(function (response) {
                              console.log("BLOCKED")
                            })
                            .catch(function (error) {
                              return console.log("Request error: ", error)
                            })
                        }
                      }
                    }
                  }
                }
              }

            }

          })
          .catch(function (error) {
            return console.log("Request error: ", error)
          })
      })
      .catch(function (error) {
        return console.log("Request error: ", error)
      })

  }
  async function checkForDisabledUser(expiredDate, userId) {
    var expired = new Date(expiredDate)
    var timeDifMs = expired.getTime() - Date.now()
    setUserExpired(timeDifMs)
    if (timeDifMs < 0) {
      console.log("EXPIRED USER", timeDifMs)
      swal({
        text: "Данный пользователь заблокирован в связи с истекшим сроком заключения о возможности быть усыновителем. Обратитесь в районный отдел для продления срока.",
        icon: "warning",
        buttons: { ok: "Ок" }
      })
        .then((click) => {
          keycloak.clearToken()
          keycloak.logout()
          localStorage.clear()
        })
    }
    else {
      console.log("ENABLED USER", timeDifMs)
    }
  }
  function handleOpenMenuClick(name) {
    // console.log("Event", name)
    for (var i = 0; i < menuItemStates.length; i++) {
      if (menuItemStates[i].name === name) {
        if (menuItemStates[i].state === true) {
          menuItemStates[i].state = false
        }
        else {
          menuItemStates[i].state = true
        }
      }
    }
    setOpennav(!opennav)
  }
  async function setNewTask(task) {
    await setTask(null)
    localStorage.clear()
    setTask(task)
    localStorage.setItem("task", task)
  }
  // Creating Menu Levels 1-3
  function getLevel1Items(level1, index) {
    // console.log("LVL 1", level1)
    if (level1.level2 === undefined) {
      return (
        <ListItem button
          key={index}
          className={classesnav.level1}
          onClick={() => setNewTask(level1.task)}
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <div className={classesnav.listItemText}>{level1.label}</div>
        </ListItem>
      )
    }
    else {
      // console.log("LV2", level1.level2)
      for (var i = 0; i < menuItemStates.length; i++) {
        if (menuItemStates[i].name === level1.name) {
          return (
            <List key={index}>
              <ListItem button
                key={index}
                className={classesnav.level1}
                onClick={() => handleOpenMenuClick(level1.name)}
              >
                <ListItemIcon>
                  <AddCircleIcon style={{ height: 20 }} />
                </ListItemIcon>
                <div className={classesnav.listItemText} style={{ "fontWeight": "bold" }}>{level1.label}</div>
                {(menuItemStates[i].state === true) ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={menuItemStates[i].state} timeout="auto" unmountOnExit>
                {level1.level2.map((level2Item, index) =>
                  getLevel2Items(level2Item, index)
                )}
              </Collapse>
            </List>
          )
        }
      }
    }
  }
  function getLevel2Items(level2, index) {
    // console.log("Level2", level2)
    if (level2.level3 === undefined) {
      return (
        <ListItem button
          className={classesnav.level2}
          key={index}
          onClick={() => setNewTask(level2.task)}
        >
          <ListItemIcon>
            <ExitToAppIcon style={{ height: 20 }} />
          </ListItemIcon>
          <div className={classesnav.listItemText}>{level2.label}</div>
        </ListItem>
      )
    }
    else {
      for (var i = 0; i < menuItemStates.length; i++) {
        if (menuItemStates[i].name === level2.name) {
          return (
            <List component="div" disablePadding key={index}>
              <ListItem button
                className={classesnav.level2}
                key={index}
                onClick={() => handleOpenMenuClick(level2.name)}
              >
                <ListItemIcon>
                  <AddCircleIcon style={{ height: 20 }} />
                </ListItemIcon>
                <div className={classesnav.listItemText} style={{ "fontWeight": "bold" }}>{level2.label}</div>
                {(menuItemStates[i].state === true) ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={menuItemStates[i].state} timeout="auto" unmountOnExit>
                {level2.level3.map((level3Item, index) =>
                  getLevel3Items(level3Item, index)
                )}
              </Collapse>
            </List>
          )
        }
      }
    }
  }
  function getLevel3Items(level3, index) {
    return (
      <ListItem button
        className={classesnav.level3}
        key={index}
        onClick={() => setNewTask(level3.task)}
      >
        <ListItemIcon>
          <ExitToAppIcon style={{ height: 20 }} />
        </ListItemIcon>
        <div className={classesnav.listItemText}>{level3.label}</div>
      </ListItem>
    )
  }
  function swalert(text, swalButtons) {
    return (
      swal({
        text: text,
        icon: "warning",
        // buttons: swalButtons
      })
        .then((click) => {
          if (click === "yes") {
            console.log("CLICK", click)
          } else {
            console.log("CLICK", click)
          }
        })
    )
  }
  async function getUserStatus() {
    // console.log("GETUSERSTATUS")
    keycloak.loadUserInfo().success(async function (info) {
      var curUserId = info.sub
      // console.log("USERID", curUserId)
      await request(
        {
          "headers": { "content-type": "application/json" },
          "url": portalAPI + "/ChildrenAdoptionApi/api/UserStatus/Get?userId=" + curUserId,
          "method": "GET"
        }
      )
        .then(function (response) {
          let parsedStatus = JSON.parse(response)
          console.log("STATUS", parsedStatus)
          setUserStatus(parsedStatus.StatusName)
          var newDate = new Date(parsedStatus.StatusDate)
          var dd = String(newDate.getDate()).padStart(2, '0')
          var mm = String(newDate.getMonth() + 1).padStart(2, '0') //January is 0!
          var yyyy = newDate.getFullYear()
          var convertedDate = dd + '-' + mm + '-' + yyyy
          setUserStatusDate(convertedDate)
          // var parsedData = JSON.parse(response)
        })
        .catch(function (error) {
          return console.log("Request error: ", error)
        })
    }).error(function () {
      console.log('Failed to load user Info')
    })
  }
  async function getUnreadCount(OrgId, role) {
    console.log("COUNT", OrgId, role)
    if (role === "operator" || role === "boardingHouse") {
      await request(
        {
          "headers": { "content-type": "application/json" },
          "url": portalAPI + "/ChildrenAdoptionApi/api/MeetupRequests/getUnreadCount?orgId=" + OrgId,
          "method": "GET"
        }
      )
        .then(function (response) {
          setUnreadNotifications(response)
          if (response > 0) {
            swal({
              text: "Появились новые уведомления на встречу, проверьте список",
              icon: "warning"
            })
          }

        })
        .catch(function (error) {
          return console.log("Request error: ", error)
        })
    }
    else {
      await request(
        {
          "headers": { "content-type": "application/json" },
          "url": portalAPI + "/ChildrenAdoptionApi/api/MeetupRequests/getUnreadCount?orgId=" + OrgId,
          "method": "GET"
        }
      )
        .then(function (response) {
          setUnreadNotifications(response)
          if (response > 0) {
            swal({
              text: "Появились новые уведомления на встречу, проверьте список",
              icon: "warning"
            })
          }

        })
        .catch(function (error) {
          return console.log("Request error: ", error)
        })
    }

  }
  function linkLogout() {
    keycloak.clearToken()
    keycloak.logout()
    localStorage.clear()
  }
  function getDistrictLabel() {
    for (let i = 0; i < EnumData.length; i++) {
      for (let l = 0; l < EnumData[i].data.length; l++) {
        if (EnumData[i].data[l].Id === userOrgId) {
          return EnumData[i].data[l].Text
        }
      }
    }
  }
  // console.log("ROUTE", routes)
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" fontSize="12" variant="h6" color="inherit" noWrap className={classes.title}>

          </Typography>
          {userId !== null && userRole === "candidate" &&
            <table>
              <tbody>
                <tr>
                  <td style={{ fontSize: 11 }}>Статус: </td>
                  <td style={{ fontSize: 9 }}>{userStatus}</td>
                </tr>
                <tr>
                  <td style={{ fontSize: 11 }}>Дата: </td>
                  <td style={{ fontSize: 9 }}>{userStatusDate}</td>
                </tr>
              </tbody>
            </table>
          }
          {userId !== null && (userRole === "operator" || userRole === "boardingHouse") &&
            <table>
              <tbody>
                <tr>
                  <td><Notification style={unreadNotifications > 0 ? { color: "red", marginTop: 4 } : { color: "white", marginTop: 4 }} /></td>
                  <td style={unreadNotifications > 0 ? { fontSize: 22, color: "red", paddingRight: 7 } : { fontSize: 22, paddingRight: 7 }}>{unreadNotifications}</td>
                </tr>
              </tbody>
            </table>
          }
          <AccountCircleIcon fontSize="large" />
          {/* {!!keycloak.authenticated &&( */}
          <table>
            <tbody>
              <tr>
                <td style={{ fontSize: 9 }}>ПИН:</td>
                <td style={{ fontSize: 9 }}>{userPIN}</td>
              </tr>
              <tr>
                <td style={{ fontSize: 9 }}>ФИО:</td>
                <td style={{ fontSize: 9 }}>{userName}</td>
              </tr>
              <tr>
                <td style={{ fontSize: 9 }}>Район:</td>
                <td style={{ fontSize: 9 }}>{getDistrictLabel()}</td>
              </tr>
            </tbody>
          </table>
          <IconButton color="inherit" style={{ fontSize: 15 }} onClick={() => linkLogout()} href="/">
            Выход
            </IconButton>
          {/* )} */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        {routes.map((level1, index) => (
          getLevel1Items(level1, index)
        ))}
      </Drawer>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Grid position="fixed" style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 10 }}>
          {task === "viewUsers" && userRole !== null && userOrgId !== null && userId !== null &&
            <Users
              portalAPI={portalAPI}
              keycloakAPI={keycloakAPI}
              getUserStatus={getUserStatus}
              userId={userId}
              taskType={task}
              userRole={userRole}
              userOrgId={userOrgId}
            />
          }
          {task === "getActiveList" && userOrgId !== null && userId !== null && userRole !== null &&
            <Children
              portalAPI={portalAPI}
              keycloakAPI={keycloakAPI}
              getUserStatus={getUserStatus}
              userId={userId}
              userRole={userRole}
              taskType={task}
              userOrgId={userOrgId}
            />
          }
          {/* {(task === "getRequestsByOrgId" || task === "getRequestsByUserId") && 
          userOrgId !==null && userId !== null && userRole !== null && 
            <CandidateRequests
              getUserStatus={getUserStatus}
              userId={userId}
              userPIN={userPIN}
              userRole={userRole}
              taskType={task}
              userOrgId={userOrgId}
            />
          } */}
          {(task === "getMeetUpRequestsByOrgId" || task === "getMeetUpRequestsByUserId") &&
            userOrgId !== null && userId !== null && userRole !== null &&
            <CandidateMeetUpRequests
              portalAPI={portalAPI}
              keycloakAPI={keycloakAPI}
              getUserStatus={getUserStatus}
              userId={userId}
              // userPIN={userPIN}
              userRole={userRole}
              taskType={task}
              userOrgId={userOrgId}
            />
          }

          {task === "getNotificationsByOrgId" && userOrgId !== null && userId !== null && userRole !== null &&
            <Notifications
              portalAPI={portalAPI}
              keycloakAPI={keycloakAPI}
              getUnreadCount={getUnreadCount}
              getUserStatus={getUserStatus}
              userId={userId}
              userPIN={userPIN}
              userRole={userRole}
              taskType={task}
              userOrgId={userOrgId}
            />
          }
          {(task === "candidateMeetUpResult" || task === "viewCandidateMeetUpResultsByOrgId") && userOrgId !== null && userId !== null && userRole !== null &&
            <MeetUpResults
              portalAPI={portalAPI}
              keycloakAPI={keycloakAPI}
              getUserStatus={getUserStatus}
              userId={userId}
              // userPIN={userPIN}
              userRole={userRole}
              taskType={task}
              userOrgId={userOrgId}
            />
          }
          {(task === "getOrdersByUserId" || task === "getOrdersByOrgId") && userOrgId !== null && userId !== null && userRole !== null &&
            <Orders
            portalAPI={portalAPI}
              keycloakAPI={keycloakAPI}
              getUserStatus={getUserStatus}
              userId={userId}
              userExpired={userExpired}
              // userPIN={userPIN}
              userRole={userRole}
              taskType={task}
              userOrgId={userOrgId}
            />
          }
          {/* {task === "scriptTester" && userOrgId !== null && userId !== null && userRole !== null &&
            <ScriptTester
            // getUserStatus={getUserStatus}
            // userId={userId}
            // userRole={userRole}
            // taskType={task}
            // userOrgId={userOrgId}
            />
          } */}
          <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © " + new Date().getFullYear() + " Intersoft Ltd. All rights reserved."}
          </Typography>
        </Grid>
      </main>
    </div>
  )
}
