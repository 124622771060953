import React, {useState, useEffect} from 'react';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from 'sweetalert' // https://sweetalert.js.org/guides/
import {useKeycloak} from '../lib';

var request = require("request-promise")

export default (props) => {
  // This.state
  const [operators, setOperators] = useState(null)
  const [candidates, setCandidates] = useState(null)
  const {keycloak} = useKeycloak()
  useEffect(()=>{
    setOperators(
      [
        {
          "Id":"20ff5245-db58-443a-a31b-061200e939aa",
          "Text":"уср октябрьского района"
        },
        {
          "Id":"668d6957-1f76-49de-b9ad-0f2c635df2dd",
          "Text":"уср кеминского района"
        },
        {
          "Id":"52315837-1b8f-4b80-9d8c-160214f4681a",
          "Text":"уср свердловского района"
        },
        {
          "Id":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
          "Text":"уср жети-огузского района"
        },
        {
          "Id":"d4c5884f-1820-4df5-9bf8-c48202acb801",
          "Text":"уср первомайского района"
        },
        {
          "Id":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
          "Text":"уср ленинского района"
        },
        {
          "Id":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
          "Text":"уср аламединского района"
        },
        {
          "Id":"7866d332-c471-4873-8f8b-6da9edc3683a",
          "Text":"уср ыссык-атинского района"
        },
        {
          "Id":"c8641d03-b553-4f95-adfb-ec002fd64e06",
          "Text":"уср московского района"
        },
        {
          "Id":"30658f5a-dc22-4a7a-aaf6-63dfb7070c50",
          "Text":"уср чуйского района"
        },
        {
          "Id":"8b6510f7-3b6b-4c9d-b8a1-2349b78b2cf3",
          "Text":"уср города токмок"
        },
        {
          "Id":"7f311898-d254-4668-8184-d1794e695a19",
          "Text":"уср бакай-атинского района"
        },
        {
          "Id":"19f7870d-4b5e-457a-b159-528f71374e1e",
          "Text":"уср жайылского района"
        },
        {
          "Id":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
          "Text":"уср сокулукского района"
        },
        {
          "Id":"5df10bbc-d256-40f8-b641-5ef31ce11540",
          "Text":"уср панфиловского района"
        },
        {
          "Id":"4313a514-83b6-4fed-9731-4d838a6e91cd",
          "Text":"уср нарынского района"
        },
        {
          "Id":"93df61dd-a2fe-4f52-b961-f1b27bd27a8c",
          "Text":"уср города нарын"
        },
        {
          "Id":"69eaac3d-3525-4ce3-8c5e-42123b4ed9cb",
          "Text":"уср атбашинского района"
        },
        {
          "Id":"51c381f2-6ad6-4382-81f9-3d36e9abea08",
          "Text":"уср кочкорского района"
        },
        {
          "Id":"b3ea3de1-5d76-461c-90ac-68d312b7b665",
          "Text":"уср жумгальского района"
        },
        {
          "Id":"b3c93041-bbea-445d-b7e1-d42d9f3692c3",
          "Text":"уср акталинского района"
        },
        {
          "Id":"c6d92271-6ff2-4641-a83f-d02610060c43",
          "Text":"уср кара-бууринского района"
        },
        {
          "Id":"3bb3cb8f-a747-4026-9031-c6b67ea9735b",
          "Text":"уср города талас"
        },
        {
          "Id":"16fd5bff-7ab4-4b40-aafb-0282ba5be32e",
          "Text":"уср таласского района"
        },
        {
          "Id":"b8dacad8-0118-4147-8bd2-30dce891e5eb",
          "Text":"уср манасского района"
        },
        {
          "Id":"3277f73d-b02d-4f35-ad1a-80b349eecf6b",
          "Text":"уср тонского района"
        },
        {
          "Id":"c748cdb5-502a-4243-8494-48e224968019",
          "Text":"уср тюпского района"
        },
        {
          "Id":"1f892b98-97b4-4b58-b61f-7dfecbac3b5e",
          "Text":"уср ак-сууйского района"
        },
        {
          "Id":"ab27c82d-8348-4dba-ba9c-ceae3a67704e",
          "Text":"уср города каракол"
        },
        {
          "Id":"23db562e-c0b7-4b7e-ac16-967c96500444",
          "Text":"уср города балыкчи"
        },
        {
          "Id":"14eb7aef-c331-4085-a83e-0f5eebf2eaa3",
          "Text":"уср иссык-кульского района"
        },
        {
          "Id":"5ee1ac59-52a1-45e3-84ec-54ad09fc9f1e",
          "Text":"уср баткенского района"
        },
        {
          "Id":"d328865b-ede4-4674-92c6-c134f55c3c6f",
          "Text":"уср города баткен"
        },
        {
          "Id":"879f3420-e45a-4e11-9394-d0b1a0091805",
          "Text":"уср кадамжайского района"
        },
        {
          "Id":"c7b6aa81-a4b7-4ad6-be03-e4b54e8084da",
          "Text":"уср лейлекского района"
        },
        {
          "Id":"32af3ea3-4100-406f-8825-20cb8a1877aa",
          "Text":"уср города кизыл-кия"
        },
        {
          "Id":"ff3b071c-fc51-4f64-8bd4-11a5377e93d2",
          "Text":"уср города сулюкта"
        },
        {
          "Id":"8b739bfd-cf8f-4380-a246-8a34bce9a231",
          "Text":"уср города ош"
        },
        {
          "Id":"b382a964-bbd7-4540-b6e4-bc9250ab7005",
          "Text":"уср араванского района"
        },
        {
          "Id":"a2317371-cc1d-4eb3-8adf-d7ba33b75f87",
          "Text":"уср кара-кульджинского района"
        },
        {
          "Id":"59ff6f9c-f047-4e25-b837-669aa0596b07",
          "Text":"уср карасуйского района"
        },
        {
          "Id":"a3282ffc-bafe-4b4e-99d0-c68509be6133",
          "Text":"уср ноокатского района"
        },
        {
          "Id":"e425497a-3c82-424e-9691-71eaad4759d8",
          "Text":"уср узгенского района"
        },
        {
          "Id":"d62e0b83-e589-491d-b0a4-8bea42178231",
          "Text":"уср города узген"
        },
        {
          "Id":"e5411cdd-3caf-4798-a7bf-fd57c856c82b",
          "Text":"уср алайского района"
        },
        {
          "Id":"c1a54a26-ca15-4c29-8deb-957f5b0fd002",
          "Text":"уср чон-алайского района"
        },
        {
          "Id":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
          "Text":"уср города джалал-абад"
        },
        {
          "Id":"b42b4f13-2aa5-43d2-b738-cb02ca647d47",
          "Text":"уср города кок-жангак"
        },
        {
          "Id":"9afa2870-4609-4d8a-8417-11c2dbdec94b",
          "Text":"уср города майлуу-суу"
        },
        {
          "Id":"9d8b0ca0-d618-4157-af15-5e7cca7d9f23",
          "Text":"уср города таш-кумыр"
        },
        {
          "Id":"aa65c2f7-3091-40e5-939d-6a18250e125a",
          "Text":"уср города кара-куль "
        },
        {
          "Id":"dc448ce3-49f4-43f1-aeaa-f782341c62c0",
          "Text":"уср сузакского района"
        },
        {
          "Id":"17f5601a-36b7-4fac-a980-07f349239d5b",
          "Text":"уср базар-коргонского района"
        },
        {
          "Id":"e4964796-6658-46a9-bd49-a34b3275cb1f",
          "Text":"уср ноокенского района"
        },
        {
          "Id":"2765827a-88ef-4144-8050-9a2fd66fb136",
          "Text":"уср аксыйский района"
        },
        {
          "Id":"60f22545-cb84-4795-81a3-2eeeeb0fb88a",
          "Text":"уср ала-букинского района"
        },
        {
          "Id":"a598bea0-37c9-4dc2-9bc7-fb3aa1097e14",
          "Text":"чаткальское утср"
        },
        {
          "Id":"64ecd259-a848-474b-8e8d-571612a7e43b",
          "Text":"уср токтогульского района"
        },
        {
          "Id":"b0a96501-f5f4-48a4-8103-6c7a7ba290c4",
          "Text":"уср тогуз-тороузского района"
        }
      ] 
    )
    setCandidates([
      {
         "OrgId":"9d8b0ca0-d618-4157-af15-5e7cca7d9f23",
         "PIN":"10808198701057",
         "LastName":"Асаналиева",
         "FirstName":"Гульназ",
         "MiddleName":"Жолдошаалыевна",
         "Address":"Джалал-Абадская область г. Таш-Комур",
         "Telephone":"0779344343"
      },
      {
         "OrgId":"23db562e-c0b7-4b7e-ac16-967c96500444",
         "PIN":"10306197300370",
         "LastName":"Абдрахманова",
         "FirstName":"Нуриля",
         "MiddleName":"Кочконбаевна",
         "Address":"Ысык-Кульская область г. Балыкчы",
         "Telephone":""
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"12309196100442",
         "LastName":"Сабыркулова",
         "FirstName":"Рыскуль",
         "MiddleName":"Султановна",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0556 147-721"
      },
      {
         "OrgId":"64ecd259-a848-474b-8e8d-571612a7e43b",
         "PIN":"12704196500589",
         "LastName":"Мамбеталиева",
         "FirstName":"Элмира",
         "MiddleName":"Чоюновна",
         "Address":"Джалал-Абадская область Токтогульский район",
         "Telephone":"0709 590110"
      },
      {
         "OrgId":"d328865b-ede4-4674-92c6-c134f55c3c6f",
         "PIN":"22605198300870",
         "LastName":"Акматов",
         "FirstName":"Абдыбек",
         "MiddleName":"Мамадиевна",
         "Address":"Баткенская область г. Баткен",
         "Telephone":""
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10603196901430",
         "LastName":"Абдыбекова",
         "FirstName":"Гулира",
         "MiddleName":"Жолчубековна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0773 108 169"
      },
      {
         "OrgId":"1f892b98-97b4-4b58-b61f-7dfecbac3b5e",
         "PIN":"22904198800054",
         "LastName":"Мусабаев",
         "FirstName":"Айбек",
         "MiddleName":"Тынычбекович",
         "Address":"Ысык-Кульская область Ак-Суйский район",
         "Telephone":"0507 442770"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"12212197400541",
         "LastName":"Бердикулова",
         "FirstName":"Жайнагуль",
         "MiddleName":"Джолдошбековна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0707393280"
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"10108198200883",
         "LastName":"Мамасалиева",
         "FirstName":"Канзада",
         "MiddleName":"Тайировна ",
         "Address":"Джалал-Абадская область Ноокенский район",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"10906197200711",
         "LastName":"Токтосунова ",
         "FirstName":"Арзыкан",
         "MiddleName":"Жоробаевна",
         "Address":"Джалал-Абадская область Аксыйский район",
         "Telephone":"0772563475"
      },
      {
         "OrgId":"64ecd259-a848-474b-8e8d-571612a7e43b",
         "PIN":"12111197600641",
         "LastName":"Сарматова",
         "FirstName":"Айгүл",
         "MiddleName":"Зайырболотовна",
         "Address":"Джалал-Абадская область Токтогульский район",
         "Telephone":"0707 337632"
      },
      {
         "OrgId":"60f22545-cb84-4795-81a3-2eeeeb0fb88a",
         "PIN":"20508196600045",
         "LastName":"Дыйканбаев",
         "FirstName":"Мамыт",
         "MiddleName":"Осмоналиевич",
         "Address":"Джалал-Абадская область Ала-Букинский район",
         "Telephone":"0703730552"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"20904197700500",
         "LastName":"Базаркулов",
         "FirstName":"Джунуш",
         "MiddleName":"Кемельбекович",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0555682322"
      },
      {
         "OrgId":"668d6957-1f76-49de-b9ad-0f2c635df2dd",
         "PIN":"22708197200227",
         "LastName":"Байчура",
         "FirstName":"Олег",
         "MiddleName":"Федорович",
         "Address":"Чуйская область Кеминский район",
         "Telephone":"0555626333"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"10612197600962",
         "LastName":"Эрмырзаева",
         "FirstName":"Роза",
         "MiddleName":"Абдыбековна",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0703417803"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"12102196400799",
         "LastName":"Чодонбаева",
         "FirstName":"Бурма",
         "MiddleName":"Жээнбековна ",
         "Telephone":""
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"12711197600612",
         "LastName":"Темирболот кызы",
         "FirstName":"Аида",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0701 511012, раб (03132) 5-23-87"
      },
      {
         "OrgId":"93df61dd-a2fe-4f52-b961-f1b27bd27a8c",
         "PIN":"20110196900135",
         "LastName":"Асланбеков",
         "FirstName":"Рыскелди",
         "MiddleName":"Асанович",
         "Address":"Нарынская область Нарынский район",
         "Telephone":"0705220348"
      },
      {
         "OrgId":"d328865b-ede4-4674-92c6-c134f55c3c6f",
         "PIN":"22605198300870",
         "LastName":"Акматов",
         "FirstName":"Абдыбек",
         "MiddleName":"Мамадиевич",
         "Address":"Баткенская область г. Баткен",
         "Telephone":"0774 29-21-43"
      },
      {
         "OrgId":"8b739bfd-cf8f-4380-a246-8a34bce9a231",
         "PIN":"12512197300274",
         "LastName":"Айдарова",
         "FirstName":"Чолпон",
         "MiddleName":"Джеенбековна",
         "Telephone":"0777424422"
      },
      {
         "OrgId":"b8dacad8-0118-4147-8bd2-30dce891e5eb",
         "PIN":"21906196601037",
         "LastName":"Тилепов",
         "FirstName":"Алыбек",
         "MiddleName":"Джумабекович",
         "Address":"Таласская область Манасский район",
         "Telephone":"0771369677"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"20302197400953",
         "LastName":"Дурсунов",
         "FirstName":"Сурач",
         "MiddleName":"Пашаевич",
         "Address":"Чуйская область Московский район",
         "Telephone":"0555152235"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"20206197400693",
         "LastName":"Бекиев ",
         "FirstName":"Азамат ",
         "MiddleName":"Курбанбаевич",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0701877789"
      },
      {
         "OrgId":"23db562e-c0b7-4b7e-ac16-967c96500444",
         "PIN":"22701197700530",
         "LastName":"Кыдыбаев",
         "FirstName":"Нурлан",
         "MiddleName":"Бейшеналиевич",
         "Address":"Ысык-Кульская область г. Балыкчы",
         "Telephone":"0707344083"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"11406196700078",
         "LastName":"Омуралиева",
         "FirstName":"Райкан",
         "MiddleName":"Айтыбаевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":""
      },
      {
         "OrgId":"ab27c82d-8348-4dba-ba9c-ceae3a67704e",
         "PIN":"11510198501038",
         "LastName":"Маткулова",
         "FirstName":"Мээрим",
         "MiddleName":"Жапарбековна",
         "Telephone":""
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"11802198700896",
         "LastName":"Захидова ",
         "FirstName":"Зарина ",
         "MiddleName":"Захидовна ",
         "Telephone":"0559038566"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"12210197600727",
         "LastName":"Кадыраджиева",
         "FirstName":"Шадия",
         "MiddleName":"Турсунмахаметовна",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0700362623"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"21406197400350",
         "LastName":"Тапаев",
         "FirstName":"Талайбек",
         "MiddleName":"Турсунбекович",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0700768671"
      },
      {
         "OrgId":"d328865b-ede4-4674-92c6-c134f55c3c6f",
         "PIN":"22605198300870",
         "LastName":"Акматов ",
         "FirstName":"Абдыбек",
         "MiddleName":"Мамадиевич",
         "Address":"Баткенская область г. Баткен",
         "Telephone":""
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"23001197801102",
         "LastName":"Ургазиев",
         "FirstName":"Алмазбек",
         "MiddleName":"Санташович",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0708080739"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"12006197000346",
         "LastName":"Исакова ",
         "FirstName":"Айнура",
         "MiddleName":"Аскаровна",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0555019700"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"11403198100865",
         "LastName":"Корчубекова",
         "FirstName":"Жылдыз",
         "MiddleName":"Курманбековна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0552 26 77 57"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840125*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"c7b6aa81-a4b7-4ad6-be03-e4b54e8084da",
         "PIN":"12008196510080",
         "LastName":"Баннанова",
         "FirstName":"Адалатхон",
         "MiddleName":"Султанмуратовна",
         "Address":"Баткенская область г. Сулюкта",
         "Telephone":"0777876632"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10501195800028",
         "LastName":"Барикова",
         "FirstName":"Света",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0553 85 21 52"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"22201197400847",
         "LastName":"Мадалиев",
         "FirstName":"Алибек",
         "MiddleName":"Абдиллаевич",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0507 507-076"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"12303197600035",
         "LastName":"Эркин кызы",
         "FirstName":"Аида",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0550107306"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"10706197601162",
         "LastName":"Багавеева",
         "FirstName":"Люция",
         "MiddleName":"Гумаровна",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0556-177-056"
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"10306198601209",
         "LastName":"Шарапиева ",
         "FirstName":"Айнагул ",
         "MiddleName":"Акылбековна ",
         "Telephone":""
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"21102198001119",
         "LastName":"Момуналиев",
         "FirstName":"Мурат",
         "MiddleName":"Женишбекович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0702908904 М.Ж., 0703908904 Э.Ж."
      },
      {
         "OrgId":"aa65c2f7-3091-40e5-939d-6a18250e125a",
         "PIN":"12002198400891",
         "LastName":"Шаменова",
         "FirstName":"Назира",
         "MiddleName":"Рысбековна",
         "Address":"Джалал-Абадская область г. Кара-Куль",
         "Telephone":"0773752953"
      },
      {
         "OrgId":"7f311898-d254-4668-8184-d1794e695a19",
         "PIN":"12102197900376",
         "LastName":"Эсеналиева ",
         "FirstName":"Чынара ",
         "MiddleName":"Анаркановна ",
         "Address":"Таласская область Бакай-Атинский район",
         "Telephone":"0701650518"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"11204195800337",
         "LastName":"Бузина",
         "FirstName":"Татьяна",
         "MiddleName":"Викторовна",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0551 935777"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"12605197600966",
         "LastName":"Штоль ",
         "FirstName":"Наталья",
         "MiddleName":"Павловна",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0550898804\r\n0551404743"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"10212197201007",
         "LastName":"Козуева",
         "FirstName":"Жанылай",
         "MiddleName":"Жанышовна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":""
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"10610198900178",
         "LastName":"Эшенова",
         "FirstName":"Жылдыз",
         "MiddleName":"Доолоткелдиевна",
         "Telephone":""
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840154*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"20603197100562",
         "LastName":"Окошев",
         "FirstName":"Нурбек",
         "MiddleName":"Зарлыкович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0708182568, 0556182568"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"22305197900482",
         "LastName":"Исаев",
         "FirstName":"Бактыяр",
         "MiddleName":"Абдыманапович",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0703950363"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"11307198501268",
         "LastName":"Акунова ",
         "FirstName":"Элизат ",
         "MiddleName":"Жумабековна",
         "Address":"Чуйская область Свердловский",
         "Telephone":"0708533112"
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"22302198300630",
         "LastName":"Билалов ",
         "FirstName":"Тяншан",
         "MiddleName":"Кабакбаевич",
         "Telephone":"773152185"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"11702197601356",
         "LastName":"Абийбула кызы ",
         "FirstName":"Бактыгул",
         "Address":"Чуйская область Свердловский",
         "Telephone":"0708767031"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"10201197700305",
         "LastName":"Эркегулова",
         "FirstName":"Гульзат ",
         "MiddleName":"Джекшенбековна",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0701855520"
      },
      {
         "OrgId":"8b6510f7-3b6b-4c9d-b8a1-2349b78b2cf3",
         "PIN":"21105198900280",
         "LastName":"Монолдор уулу",
         "FirstName":"Темирбек",
         "Address":"Чуйская область Чуйский район",
         "Telephone":"0707100838"
      },
      {
         "OrgId":"93df61dd-a2fe-4f52-b961-f1b27bd27a8c",
         "PIN":"12411196700183",
         "LastName":"Калматова",
         "FirstName":"Миргуль",
         "MiddleName":"Мукеновна",
         "Address":"Нарынская область г. Нарын",
         "Telephone":"0707437270"
      },
      {
         "OrgId":"32af3ea3-4100-406f-8825-20cb8a1877aa",
         "PIN":"11701195900957",
         "LastName":"Аманова",
         "FirstName":"Ирабия",
         "MiddleName":"Абдубаитовна",
         "Telephone":"0778959059"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"20405197101208",
         "LastName":"Нааматов",
         "FirstName":"Болотбек",
         "MiddleName":"Самарбекович",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0552151072"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"10503319720042",
         "LastName":"Ахмедакунова",
         "FirstName":"Алтынай",
         "MiddleName":"Турсунбековна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0555960411"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"11610197400779",
         "LastName":"Курманалиева",
         "FirstName":"Кенжегул",
         "MiddleName":"Казакбаевна",
         "Telephone":"0505849494"
      },
      {
         "OrgId":"93df61dd-a2fe-4f52-b961-f1b27bd27a8c",
         "PIN":"20110196900135",
         "LastName":"Асланбеков",
         "FirstName":"Рыскелди",
         "MiddleName":"Асанович",
         "Address":"Нарынская область Нарынский район",
         "Telephone":"0705220348"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"10212197201007",
         "LastName":"Козуева",
         "FirstName":"Жанылай",
         "MiddleName":"Жанышовна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"+996772687367"
      },
      {
         "OrgId":"8b6510f7-3b6b-4c9d-b8a1-2349b78b2cf3",
         "PIN":"20502198100379",
         "LastName":"Ашырбеков",
         "FirstName":"Жаныбек",
         "MiddleName":"Мукашович",
         "Address":"Чуйская область Чуйский район",
         "Telephone":"0700443277"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"10205198501417",
         "LastName":"Кошалиева",
         "FirstName":"Майрамкан",
         "MiddleName":"Ишенбаевна",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0701770085"
      },
      {
         "OrgId":"e4964796-6658-46a9-bd49-a34b3275cb1f",
         "PIN":"20403196400852",
         "LastName":"Абдуллаев",
         "FirstName":"Рустамбек",
         "MiddleName":"Бахапович",
         "Address":"Джалал-Абадская область Ноокенский район",
         "Telephone":"0557282811"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"11202196500078",
         "LastName":"Рысалиева",
         "FirstName":"Чолпон",
         "MiddleName":"Кулановна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0701 91 15 98"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"21403197600632",
         "LastName":"Джангазиев",
         "FirstName":"Тимур",
         "MiddleName":"Салибекович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0702522214, 0507189127"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"12011198900817",
         "LastName":"Алтынбекова ",
         "FirstName":"Каныкей ",
         "MiddleName":"Асейиновна ",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0772136779"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"10203198300674",
         "LastName":"Темирбек кызы",
         "FirstName":"Нургул",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0703881219"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2010919910187*",
         "LastName":"Бейшеев",
         "FirstName":"Арслан",
         "MiddleName":"Асылбекович",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":""
      },
      {
         "OrgId":"30658f5a-dc22-4a7a-aaf6-63dfb7070c50",
         "PIN":"20612197100130",
         "LastName":"Бообеков ",
         "FirstName":"Уланбек ",
         "MiddleName":"Койчубекович",
         "Address":"Чуйская область Кеминский район",
         "Telephone":"0550 08-98-47"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"10208195900496",
         "LastName":"Акимова",
         "FirstName":"Гульнура",
         "MiddleName":"Шариповна",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0709020859"
      },
      {
         "OrgId":"3bb3cb8f-a747-4026-9031-c6b67ea9735b",
         "PIN":"12802198501110",
         "LastName":"Дуйшеева",
         "FirstName":"Албина",
         "MiddleName":"Аманжоловна",
         "Address":"Таласская область Таласский район",
         "Telephone":"0779672404"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"12404197600586",
         "LastName":"Боромбаева",
         "FirstName":"Роза",
         "MiddleName":"Искаковна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0708 11 45 11"
      },
      {
         "OrgId":"8b6510f7-3b6b-4c9d-b8a1-2349b78b2cf3",
         "PIN":"11809196800551",
         "LastName":"Бекбоева",
         "FirstName":"Айнагуль",
         "MiddleName":"Джусуповна",
         "Address":"Чуйская область г. Токмок",
         "Telephone":"0555-76-72-01, 0709-76-72-01"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10203197600432",
         "LastName":"Валиева",
         "FirstName":"Эльвира",
         "MiddleName":"Рашитовна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0 550 30 77 22, 0700 30 77 22"
      },
      {
         "OrgId":"668d6957-1f76-49de-b9ad-0f2c635df2dd",
         "PIN":"22701196800632",
         "LastName":"Семетов ",
         "FirstName":"Табылды ",
         "MiddleName":"Кымбатканович ",
         "Address":"Чуйская область Кеминский район",
         "Telephone":""
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2050719840040*",
         "LastName":"Borubaev",
         "FirstName":"Zhakypbek",
         "MiddleName":"Zharkynbaevich",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"22809196400069",
         "LastName":"Мамаюсупов",
         "FirstName":"Абдыхалил",
         "MiddleName":"Шадманович",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0709387164"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"21512198000037",
         "LastName":"Алыкулов",
         "FirstName":"Исламбек",
         "MiddleName":"Жээнбекович",
         "Address":"Нарынская область Кочкорский район",
         "Telephone":"0772755783\r\n0703755788"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"22506199202215",
         "LastName":"Никитенко",
         "FirstName":"Юрий",
         "MiddleName":"Анатольевич",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0555809037"
      },
      {
         "OrgId":"93df61dd-a2fe-4f52-b961-f1b27bd27a8c",
         "PIN":"21401197700430",
         "LastName":"Жолдошбеков",
         "FirstName":"Максат",
         "MiddleName":"Жолдубаевич",
         "Address":"Нарынская область г. Нарын",
         "Telephone":"0205585858, 0553262748"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"10706197601162",
         "LastName":"Багавеева",
         "FirstName":"Люция",
         "MiddleName":"Гумаровна",
         "Telephone":"0556177056"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"20907196400094",
         "LastName":"Шакиров",
         "FirstName":"Задемир",
         "MiddleName":"Нурджанович ",
         "Address":"Чуйская область Чуйский район",
         "Telephone":"0700856123"
      },
      {
         "OrgId":"c8641d03-b553-4f95-adfb-ec002fd64e06",
         "PIN":"12409197400861",
         "LastName":"Джанузакова",
         "FirstName":"Гульмира",
         "MiddleName":"Джанузаковна",
         "Address":"Чуйская область Московский район",
         "Telephone":"0557 60 50 52"
      },
      {
         "OrgId":"ab27c82d-8348-4dba-ba9c-ceae3a67704e",
         "PIN":"11108198501220",
         "LastName":"Прохоренко ",
         "FirstName":"Ольга",
         "MiddleName":"Викторовна",
         "Address":"Ысык-Кульская область г. Каракол",
         "Telephone":"0559896108"
      },
      {
         "OrgId":"23db562e-c0b7-4b7e-ac16-967c96500444",
         "PIN":"11003197000465",
         "LastName":"Кетиренова",
         "FirstName":"Айнагуль",
         "MiddleName":"Кыдырмышевна",
         "Address":"Ысык-Кульская область г. Балыкчы",
         "Telephone":""
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"11011198800223",
         "LastName":"Исакова ",
         "FirstName":"Айсулуу",
         "MiddleName":"Жакыповна",
         "Address":"Чуйская область Свердловский",
         "Telephone":"0700386419"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"10507197001113",
         "LastName":"Кулболдиева",
         "FirstName":"Назира",
         "MiddleName":"Кашынбаевна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0702 080880"
      },
      {
         "OrgId":"3277f73d-b02d-4f35-ad1a-80b349eecf6b",
         "PIN":"22505197500258",
         "LastName":"Качыбек уулу",
         "FirstName":"Нурбек",
         "Address":"Ысык-Кульская область Тонский район",
         "Telephone":"0502832002"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"21002198801476",
         "LastName":"Сабиров",
         "FirstName":"Надыржан",
         "MiddleName":"Ярмухамедович",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0551101792"
      },
      {
         "OrgId":"93df61dd-a2fe-4f52-b961-f1b27bd27a8c",
         "PIN":"2200819770035",
         "LastName":"Токтоназаров",
         "FirstName":"Арзымат",
         "MiddleName":"Турдубаевич",
         "Telephone":""
      },
      {
         "OrgId":"3bb3cb8f-a747-4026-9031-c6b67ea9735b",
         "PIN":"10101196801707",
         "LastName":"Мажиева ",
         "FirstName":"Мира",
         "MiddleName":"Болотбековна",
         "Address":"Таласская область г. Талас",
         "Telephone":"0772696158"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"11803197300412",
         "LastName":"Жолдошбекова",
         "FirstName":"Эрмекан",
         "MiddleName":"Нусупбаевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0555 10 91 10"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"11006196500918",
         "LastName":"Ковалицкая",
         "FirstName":"Светлана",
         "MiddleName":"Валентиновна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"+996 556650610"
      },
      {
         "OrgId":"b8dacad8-0118-4147-8bd2-30dce891e5eb",
         "PIN":"22408198300932",
         "LastName":"Нурматов",
         "FirstName":"Нурлан",
         "MiddleName":"Юсупович",
         "Address":"Таласская область Манасский район",
         "Telephone":"0772716740"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"11412196800058",
         "LastName":"Алыкулова",
         "FirstName":"Жылдыз",
         "MiddleName":"Джумабековна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0777576313\r\n0772576313"
      },
      {
         "OrgId":"e425497a-3c82-424e-9691-71eaad4759d8",
         "PIN":"11210198300094",
         "LastName":"Авазбекова",
         "FirstName":"Чолпон",
         "MiddleName":"Абдувалиевна",
         "Address":"Ошская область Узгенский район",
         "Telephone":""
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"10401198700029",
         "LastName":"Тургунбаева ",
         "FirstName":"Айсулуу",
         "MiddleName":"Закиировна",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":"0776555826"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"21204197001114",
         "LastName":"Сейитканов",
         "FirstName":"Салайдин",
         "MiddleName":"Кадырович",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"+996778781752"
      },
      {
         "OrgId":"e5411cdd-3caf-4798-a7bf-fd57c856c82b",
         "PIN":"21806198301285",
         "LastName":"Туйбаев",
         "FirstName":"Нурбек",
         "MiddleName":"Масабирович",
         "Address":"Ошская область Алайский район",
         "Telephone":""
      },
      {
         "OrgId":"32af3ea3-4100-406f-8825-20cb8a1877aa",
         "PIN":"12909197601139",
         "LastName":"Исманова",
         "FirstName":"Калия",
         "MiddleName":"Абдыганиевна",
         "Telephone":"0777535135"
      },
      {
         "OrgId":"30658f5a-dc22-4a7a-aaf6-63dfb7070c50",
         "PIN":"22705196500636",
         "LastName":"Увалиев ",
         "FirstName":"Кубатбек ",
         "MiddleName":"Желденович",
         "Address":"Чуйская область Чуйский район",
         "Telephone":"0551 44-77-06"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"22705198200256",
         "LastName":"Алишеров",
         "FirstName":"Аскат",
         "MiddleName":"Абдымаметович",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0702332275"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"13006196900142",
         "LastName":"Шмитько",
         "FirstName":"Светлана",
         "MiddleName":"Викторовна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0555328240"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"12607196000011",
         "LastName":"Атамкулова",
         "FirstName":"Байыскан",
         "MiddleName":"Иманкожоевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":""
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"12002197300038",
         "LastName":"Балабрикова",
         "FirstName":"Наталья ",
         "MiddleName":"Владимировна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0 900 80 44 95"
      },
      {
         "OrgId":"3277f73d-b02d-4f35-ad1a-80b349eecf6b",
         "PIN":"21208197500251",
         "LastName":"Жолочиев ",
         "FirstName":"Бактыбек",
         "MiddleName":"Жумадилович",
         "Address":"Ысык-Кульская область Тонский район",
         "Telephone":"0702120875"
      },
      {
         "OrgId":"30658f5a-dc22-4a7a-aaf6-63dfb7070c50",
         "PIN":"11702197510018",
         "LastName":"Сыдыкова ",
         "FirstName":"Миргул",
         "MiddleName":"Туратбековна",
         "Telephone":"0700 53-66-13;   0559 66-96-79"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"21910199001554",
         "LastName":"Ахматов",
         "FirstName":"Акбар",
         "MiddleName":"Анарбекович",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0500405407"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"21805198800584",
         "LastName":"Петриков ",
         "FirstName":"Дмитрий",
         "MiddleName":"Александрович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0555194034"
      },
      {
         "OrgId":"30658f5a-dc22-4a7a-aaf6-63dfb7070c50",
         "PIN":"10101194801662",
         "LastName":"Дуйшеналиева ",
         "FirstName":"Асыл",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0556010016"
      },
      {
         "OrgId":"64ecd259-a848-474b-8e8d-571612a7e43b",
         "PIN":"00112197900761",
         "LastName":"Бөжөкова",
         "FirstName":"Нурсида",
         "MiddleName":"Смаиловна",
         "Address":"Джалал-Абадская область Токтогульский район",
         "Telephone":"0703 106462"
      },
      {
         "OrgId":"c7b6aa81-a4b7-4ad6-be03-e4b54e8084da",
         "PIN":"20408197500748",
         "LastName":"Останкулов",
         "FirstName":"Талантбек",
         "MiddleName":"Олимкулович",
         "Address":"Баткенская область Лейлекский район",
         "Telephone":"0770463802"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"12003197200374",
         "LastName":"Сарыева",
         "FirstName":"Жаннат",
         "MiddleName":"Аманбаевна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0702704402\r\n0772169113"
      },
      {
         "OrgId":"2765827a-88ef-4144-8050-9a2fd66fb136",
         "PIN":"10308198601171",
         "LastName":"Маматеминова ",
         "FirstName":"Гулнура",
         "MiddleName":"Мамасалиевна",
         "Address":"Джалал-Абадская область Аксыйский район",
         "Telephone":"0770293610"
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"12911198300217",
         "LastName":"Жантороева",
         "FirstName":"Гулжамал",
         "MiddleName":"Абдирашитовна",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":"0550411374"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"11404198550168",
         "LastName":"Токторалиева",
         "FirstName":"Нуржамал",
         "MiddleName":"Доолотбековна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0708780999"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"12611197400018",
         "LastName":"Пеннер",
         "FirstName":"Ольга",
         "MiddleName":"Владимировна",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0776 898232, 0550 898232 "
      },
      {
         "OrgId":"668d6957-1f76-49de-b9ad-0f2c635df2dd",
         "PIN":"11812198200257",
         "LastName":"Абдрахманова ",
         "FirstName":"Назира ",
         "MiddleName":"Сабыровна ",
         "Address":"Чуйская область Кеминский район",
         "Telephone":""
      },
      {
         "OrgId":"ab27c82d-8348-4dba-ba9c-ceae3a67704e",
         "PIN":"11802198700863",
         "LastName":"Захидова  ",
         "FirstName":"Зарина",
         "MiddleName":"Захидовна",
         "Address":"Ысык-Кульская область г. Каракол",
         "Telephone":""
      },
      {
         "OrgId":"a598bea0-37c9-4dc2-9bc7-fb3aa1097e14",
         "PIN":"20209198100587",
         "LastName":"Накетаев",
         "FirstName":"узон",
         "MiddleName":"Алтымышович",
         "Address":"Джалал-Абадская область Чаткальский район",
         "Telephone":"0777"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"20110195801230",
         "LastName":"Станбеков",
         "FirstName":"Масалбек",
         "MiddleName":"Рыспекович",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0550557022"
      },
      {
         "OrgId":"a3282ffc-bafe-4b4e-99d0-c68509be6133",
         "PIN":"21402198400842",
         "LastName":"Адилов ",
         "FirstName":"Элчибек",
         "MiddleName":"Раимбердиевич",
         "Address":"г. Ош Ысык-Кульский район",
         "Telephone":"0772738287"
      },
      {
         "OrgId":"30658f5a-dc22-4a7a-aaf6-63dfb7070c50",
         "PIN":"21411196300433",
         "LastName":"Оморбаев",
         "FirstName":"Темирбек",
         "MiddleName":"Омурбекович ",
         "Address":"Чуйская область Чуйский район",
         "Telephone":"0556026478"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10612197700524",
         "LastName":"Токталиева",
         "FirstName":"Кайыргул",
         "MiddleName":"Усубалиевна",
         "Telephone":"0706 06 12 77"
      },
      {
         "OrgId":"3bb3cb8f-a747-4026-9031-c6b67ea9735b",
         "PIN":"20604197201019",
         "LastName":"Бердикеев",
         "FirstName":"Эркин",
         "MiddleName":"Токтоназарович",
         "Address":"Таласская область г. Талас",
         "Telephone":"0770452952"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"11804197400136",
         "LastName":"Бекмурзаева ",
         "FirstName":"Назгуль ",
         "MiddleName":"Кадыровна",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0778 587665"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"12409197401190",
         "LastName":"Аскарова",
         "FirstName":"Рахат",
         "MiddleName":"Алимжановна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0 702 25 30 95"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10210198801157",
         "LastName":"Еремеенко",
         "FirstName":"Кристина",
         "MiddleName":"Викторовна",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0559 444430,0559009325"
      },
      {
         "OrgId":"668d6957-1f76-49de-b9ad-0f2c635df2dd",
         "PIN":"10102197700801",
         "LastName":"Кадыркулова ",
         "FirstName":"Малика ",
         "MiddleName":"Кудайбергеновна ",
         "Address":"Чуйская область г. Токмок",
         "Telephone":"0707999232"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"12703196701062",
         "LastName":"Ярославцева",
         "FirstName":"Наталия ",
         "MiddleName":"Александровна ",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0550136675"
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"21804197800861",
         "LastName":"Жанганаев ",
         "FirstName":"Бактыбек",
         "MiddleName":"Урмамбетович",
         "Address":"Ысык-Кульская область Жети-Огузский район",
         "Telephone":"0702097292"
      },
      {
         "OrgId":"3277f73d-b02d-4f35-ad1a-80b349eecf6b",
         "PIN":"21304197800375",
         "LastName":"Сарбагыш уулу",
         "FirstName":"Талантбек",
         "Address":"Ысык-Кульская область Тонский район",
         "Telephone":"0705172796"
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"12102197900837",
         "LastName":"Кадирова",
         "FirstName":" Мукадам",
         "MiddleName":"Хакимжановна",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"22809196400069",
         "LastName":"Мамаюсупов",
         "FirstName":"Абдыхалил",
         "MiddleName":"Шадманович",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0709387164"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"13108198000574",
         "LastName":"Чингараева",
         "FirstName":"Зыйнат",
         "MiddleName":"Маматжановна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0703748001"
      },
      {
         "OrgId":"5ee1ac59-52a1-45e3-84ec-54ad09fc9f1e",
         "PIN":"10702198000857",
         "LastName":"Минбаева ",
         "FirstName":"Монузкан",
         "MiddleName":"Аркабаевна",
         "Address":"Баткенская область Баткенский район",
         "Telephone":"0771715125\r\n0554715125"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"22702197500301",
         "LastName":"Тураров",
         "FirstName":"Нурдин",
         "MiddleName":"Сыдыгалиевич",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"+996551449050"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840121*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"12209197700507",
         "LastName":"Тургунбаева ",
         "FirstName":"Атыркул",
         "MiddleName":"Эсенбаевна ",
         "Address":"Джалал-Абадская область Токтогульский район",
         "Telephone":""
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10101197707553",
         "LastName":"Умаралиева",
         "FirstName":"Эльвира",
         "MiddleName":"Бочоновна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":""
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"20810198200293",
         "LastName":"Нишанов",
         "FirstName":"Саид",
         "MiddleName":"Кадыржанович",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"+996 556 75-93-78"
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"13108198700090",
         "LastName":"Касымбаева ",
         "FirstName":"Махбурат",
         "MiddleName":"Имаарбековна",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":"0773058885"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"13006196900142",
         "LastName":"Шмитько",
         "FirstName":"Светлана",
         "MiddleName":"Викторовна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0555 32 82 40"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"11502196810022",
         "LastName":"Абдыкадырова",
         "FirstName":"Гульмира",
         "MiddleName":"Айдиновна",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0705723430"
      },
      {
         "OrgId":"7f311898-d254-4668-8184-d1794e695a19",
         "PIN":"10809198600785",
         "LastName":"Рысмендеева ",
         "FirstName":"Жийдегүл",
         "MiddleName":"Суйундуковна ",
         "Address":"Таласская область Бакай-Атинский район",
         "Telephone":""
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"20203197900766",
         "LastName":"Капашев",
         "FirstName":"Мирлан",
         "MiddleName":"Шейшенбаевич",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0707020379"
      },
      {
         "OrgId":"7f311898-d254-4668-8184-d1794e695a19",
         "PIN":"13001196600598",
         "LastName":"Сапаева",
         "FirstName":"Ансира ",
         "MiddleName":"Мухамаджановна ",
         "Address":"Таласская область Бакай-Атинский район",
         "Telephone":"0770502621"
      },
      {
         "OrgId":"c7b6aa81-a4b7-4ad6-be03-e4b54e8084da",
         "PIN":"12905197301000",
         "LastName":"Усарова",
         "FirstName":"Кыймат",
         "MiddleName":"Муратовна",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0706812732"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"21002198700025",
         "LastName":"Рысалиев",
         "FirstName":"Токтоболот",
         "MiddleName":"Анарбекович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0706 889958 Т.А., 0706407440 Г.Б."
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"10110197200790",
         "LastName":"Сопуева",
         "FirstName":"Рыскүл",
         "MiddleName":"Төлөмүшовна",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0708277077"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"565525584",
         "LastName":"Мостоллер",
         "FirstName":"Александра",
         "MiddleName":"Юрьевна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0779 208 016 \r\n0773 295 195"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10411197500458",
         "LastName":"Эшмурзаева",
         "FirstName":"Нургул",
         "MiddleName":"Пайзилдаевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0 707 323 990"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"1230319760035",
         "LastName":"Эркин кызы",
         "FirstName":"Аида",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"(0550) 107306"
      },
      {
         "OrgId":"14eb7aef-c331-4085-a83e-0f5eebf2eaa3",
         "PIN":"10705198301301",
         "LastName":"Монолдор кызы",
         "FirstName":"Фарида",
         "Address":"Ысык-Кульская область Ысык-Кульский район",
         "Telephone":""
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"11210198401637",
         "LastName":"Кошман",
         "FirstName":"Светлана",
         "MiddleName":"Витальевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0553344529"
      },
      {
         "OrgId":"3bb3cb8f-a747-4026-9031-c6b67ea9735b",
         "PIN":"10712198100139",
         "LastName":"султаналиева",
         "FirstName":"дамира",
         "MiddleName":"самудиновна",
         "Address":"Таласская область Бакай-Атинский район",
         "Telephone":"0709365482"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"10706198100830",
         "LastName":"Белимова ",
         "FirstName":"Любовь ",
         "MiddleName":"Николаевна ",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0552939490"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"20906197100551",
         "LastName":"Молдалиев",
         "FirstName":"Марс",
         "MiddleName":"Аманбекович",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0558005251"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10409197900530",
         "LastName":"Сутормина",
         "FirstName":"Татьяна",
         "MiddleName":"Александровна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0772 61 83 78"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"12905197301000",
         "LastName":"Усарова",
         "FirstName":"Кыймат",
         "MiddleName":"Муратовна",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0706812732"
      },
      {
         "OrgId":"c6d92271-6ff2-4641-a83f-d02610060c43",
         "PIN":"12109199301138",
         "LastName":"Акберди кызы ",
         "FirstName":"Багдат",
         "Address":"Таласская область Кара-Бууринский район",
         "Telephone":"0501717193"
      },
      {
         "OrgId":"668d6957-1f76-49de-b9ad-0f2c635df2dd",
         "PIN":"12711197300315",
         "LastName":"Жолдошева",
         "FirstName":"Венера",
         "MiddleName":"Алмухамбетовна",
         "Address":"Чуйская область Кеминский район",
         "Telephone":"0700588820"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"11811196500061",
         "LastName":"Урумкулова",
         "FirstName":"Джылдыз",
         "MiddleName":"Токтобаевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0773141637"
      },
      {
         "OrgId":"668d6957-1f76-49de-b9ad-0f2c635df2dd",
         "PIN":"20310196700450",
         "LastName":"Абдраимов ",
         "FirstName":"Нурбек ",
         "MiddleName":"Увалиевич ",
         "Address":"Чуйская область Кеминский район",
         "Telephone":"0703114279"
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"12812196600207",
         "LastName":"Урсеитова ",
         "FirstName":"Динаркуль",
         "MiddleName":"Джапаровна",
         "Address":"Ысык-Кульская область Жети-Огузский район",
         "Telephone":"0556824796"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"--------------",
         "LastName":"Турсунов",
         "FirstName":"Мирзо",
         "MiddleName":"Ахматжонович",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0557 77-92-98\r\n0778 22-59-79"
      },
      {
         "OrgId":"c7b6aa81-a4b7-4ad6-be03-e4b54e8084da",
         "PIN":"10811197800821",
         "LastName":"Кулматова",
         "FirstName":"Шайыргүл",
         "MiddleName":"Авдалимбаевна",
         "Address":"Баткенская область г. Сулюкта",
         "Telephone":"0777303161"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"11505197600443",
         "LastName":"Батыркулова",
         "FirstName":"Жылдыз",
         "MiddleName":"Торокулова",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0700387275"
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"22209198200173",
         "LastName":"Турумбаев",
         "FirstName":"Азат ",
         "MiddleName":"Омурбекович",
         "Address":"Ысык-Кульская область Жети-Огузский район",
         "Telephone":"0700782818"
      },
      {
         "OrgId":"64ecd259-a848-474b-8e8d-571612a7e43b",
         "PIN":"6160531770117",
         "LastName":"Култаева",
         "FirstName":"Элиза",
         "MiddleName":"Бузурманкуловна",
         "Address":"Джалал-Абадская область Токтогульский район",
         "Telephone":"0709 173136, 0706 030675"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"12903197800431",
         "LastName":"Эсеналиева",
         "FirstName":"Чолпон",
         "MiddleName":"Табылдыевна",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"+996550408449"
      },
      {
         "OrgId":"51c381f2-6ad6-4382-81f9-3d36e9abea08",
         "PIN":"22806197400733",
         "LastName":"Асыков",
         "FirstName":"Билим",
         "MiddleName":"Жумабекович",
         "Address":"Нарынская область Кочкорский район",
         "Telephone":"0709742606,  "
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"21404197500627",
         "LastName":"Татаев",
         "FirstName":"Бакыт",
         "MiddleName":"Байысбекович",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0709562465"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"12501197600904",
         "LastName":"Акматова",
         "FirstName":"Назира",
         "MiddleName":"Бейшеновна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0702 703918"
      },
      {
         "OrgId":"ab27c82d-8348-4dba-ba9c-ceae3a67704e",
         "PIN":"11001197300933",
         "LastName":"Рыскулова ",
         "FirstName":"Лира",
         "MiddleName":"Акбаровна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":""
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"10706197601162",
         "LastName":"Багавеева ",
         "FirstName":"Люция",
         "MiddleName":"Гумаровна",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0556-177-056"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"11811196500061",
         "LastName":"Урумкулова",
         "FirstName":"Джылдыз",
         "MiddleName":"Токтобаевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0700141637\r\n0773141637"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840152*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"11908199001520",
         "LastName":"Абдымомун кызы ",
         "FirstName":"Акмарал",
         "Address":"Джалал-Абадская область Сузакский район",
         "Telephone":""
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"12112197700037",
         "LastName":"Казакбаева",
         "FirstName":"Канайым",
         "MiddleName":"Мусаевна",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":"0776313021"
      },
      {
         "OrgId":"64ecd259-a848-474b-8e8d-571612a7e43b",
         "PIN":"12508196800108",
         "LastName":"Оморова",
         "FirstName":"Талайкан",
         "MiddleName":"Токоновна",
         "Address":"Джалал-Абадская область Токтогульский район",
         "Telephone":"0709 127190"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"22205197800848",
         "LastName":"Тоялиев",
         "FirstName":"Миргазы",
         "MiddleName":"Анарбаевич",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0703618388\r\n0507788878"
      },
      {
         "OrgId":"e425497a-3c82-424e-9691-71eaad4759d8",
         "PIN":"11409198700190",
         "LastName":"Мамашова ",
         "FirstName":"Таалайгул",
         "MiddleName":"Маданбековна",
         "Address":"Ошская область Узгенский район",
         "Telephone":""
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"20508198400447",
         "LastName":"Ибраимов ",
         "FirstName":"Тилек",
         "MiddleName":"Касымалиевич",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":"0776924263"
      },
      {
         "OrgId":"51c381f2-6ad6-4382-81f9-3d36e9abea08",
         "PIN":"11004197101091",
         "LastName":"Теңирбергенова",
         "FirstName":"Айнура",
         "MiddleName":"Кадыровна",
         "Address":"Нарынская область Кочкорский район",
         "Telephone":"0702877836"
      },
      {
         "OrgId":"d328865b-ede4-4674-92c6-c134f55c3c6f",
         "PIN":"12703197800295",
         "LastName":"Кадырова",
         "FirstName":"Бактыгул",
         "MiddleName":"Тажибаевна",
         "Address":"Баткенская область г. Баткен",
         "Telephone":"0773 70-47-30"
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"11502198501575",
         "LastName":"Шекеева ",
         "FirstName":"Фарида",
         "MiddleName":"Асангуловна",
         "Address":"Ысык-Кульская область Жети-Огузский район",
         "Telephone":"0708875285"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840151*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"14eb7aef-c331-4085-a83e-0f5eebf2eaa3",
         "PIN":"22805197300083",
         "LastName":"Алымкулов",
         "FirstName":"Талгарт",
         "MiddleName":"Жапакович",
         "Address":"Ысык-Кульская область Ысык-Кульский район",
         "Telephone":""
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"13003196400492",
         "LastName":"Нурбаева ",
         "FirstName":"Райкан",
         "MiddleName":"Каримовна ",
         "Address":"Джалал-Абадская область Сузакский район",
         "Telephone":"0771 38-13-51,\r\n0553 30-64-03"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2130719850003*",
         "LastName":"РУДЕНКО",
         "FirstName":"АРТЕМ",
         "MiddleName":"ВАЛЕРЬЕВИЧ",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":""
      },
      {
         "OrgId":"668d6957-1f76-49de-b9ad-0f2c635df2dd",
         "PIN":"20110197800906",
         "LastName":"Джунушов ",
         "FirstName":"Бакытбек ",
         "MiddleName":"Сагыналиевич ",
         "Address":"Чуйская область Кеминский район",
         "Telephone":""
      },
      {
         "OrgId":"51c381f2-6ad6-4382-81f9-3d36e9abea08",
         "PIN":"21512198000037",
         "LastName":"Алыкулов",
         "FirstName":"Исламбек",
         "MiddleName":"Жээнбекович",
         "Address":"Нарынская область Кочкорский район",
         "Telephone":""
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"12709197500565",
         "LastName":"Матназарова ",
         "FirstName":"Шаиргул",
         "MiddleName":"Тураталиевна",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":"0554627766"
      },
      {
         "OrgId":"5df10bbc-d256-40f8-b641-5ef31ce11540",
         "PIN":"12909198200413",
         "LastName":"Каттабекова",
         "FirstName":"Шаиргул",
         "MiddleName":"Султаналиевна",
         "Address":"Чуйская область Панфиловский район",
         "Telephone":"0777477905"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"12102196400799",
         "LastName":"Чодонбаева ",
         "FirstName":"Бурма",
         "MiddleName":"Жээнбековна",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0777374821\r\n0500374821"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"11309197100041",
         "LastName":"Бектенова",
         "FirstName":"Рахат",
         "MiddleName":"Маратовна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0501758552"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10203198300674",
         "LastName":"Темирбек кызы ",
         "FirstName":"Назгул",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0703881219"
      },
      {
         "OrgId":"c7b6aa81-a4b7-4ad6-be03-e4b54e8084da",
         "PIN":"12406197801164",
         "LastName":"Жороева",
         "FirstName":"Айнура",
         "MiddleName":"Калбаевна",
         "Address":"Баткенская область Лейлекский район",
         "Telephone":"0776055501"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"20405197100979",
         "LastName":"Рахманов",
         "FirstName":"Абдухамит",
         "MiddleName":"Авазбекович",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0706262648"
      },
      {
         "OrgId":"60f22545-cb84-4795-81a3-2eeeeb0fb88a",
         "PIN":"20407198200823",
         "LastName":"Мамбетов ",
         "FirstName":"Кылычбек",
         "MiddleName":"Курбаналиевич",
         "Address":"Джалал-Абадская область Ала-Букинский район",
         "Telephone":""
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"21002197100612",
         "LastName":"Алымкулов",
         "FirstName":"Нурбек",
         "MiddleName":"Саткынович",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0553602360,0556233559"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"11109197500911",
         "LastName":"Соромбаева ",
         "FirstName":"Канымкуль",
         "MiddleName":"Абдышевна",
         "Address":"Чуйская область Свердловский",
         "Telephone":"0559818611"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"20809198300680",
         "LastName":"Момбеков",
         "FirstName":"Улан",
         "MiddleName":"Нурбекович",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0700887739"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"21202199401476",
         "LastName":"Майсызов",
         "FirstName":"Ренат",
         "MiddleName":"Джамарович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":""
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"11803199200683",
         "LastName":"Каримова ",
         "FirstName":"Феруза ",
         "MiddleName":"Сайдилажанова ",
         "Address":"Джалал-Абадская область Сузакский район",
         "Telephone":""
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10208198700693",
         "LastName":"Кыдыралиева",
         "FirstName":"Айчурок",
         "MiddleName":"Эдилбековна",
         "Telephone":"0702 972889"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"20508196600045",
         "LastName":"Дыйканбаев ",
         "FirstName":"Мамыт",
         "MiddleName":"Осмоналиевич",
         "Telephone":""
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"20107198201558",
         "LastName":"Ашимов",
         "FirstName":"Данияр",
         "MiddleName":"Дурусбекович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0700 523031, 0700 112122"
      },
      {
         "OrgId":"3277f73d-b02d-4f35-ad1a-80b349eecf6b",
         "PIN":"11512196700380",
         "LastName":"Букаева",
         "FirstName":"Элза",
         "MiddleName":"Шакеновна",
         "Address":"Ысык-Кульская область Тонский район",
         "Telephone":""
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"11509198000503",
         "LastName":"Мураталиева",
         "FirstName":"Бегайым",
         "MiddleName":"Куванычбековна",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0709150980"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"11204196400181",
         "LastName":"Шералиева ",
         "FirstName":"Жайнагул",
         "MiddleName":"Казакбаевна",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0700379383, 0700288204"
      },
      {
         "OrgId":"64ecd259-a848-474b-8e8d-571612a7e43b",
         "PIN":"22807196900617",
         "LastName":"Осмонтаев",
         "FirstName":"Алилмуртаза",
         "MiddleName":"Керезбекович",
         "Address":"Джалал-Абадская область Токтогульский район",
         "Telephone":"0509 977075"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"22112196300621",
         "LastName":"Абдуллаев",
         "FirstName":"Азамат",
         "MiddleName":"Маннапович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0702139888 А.М., 0705817387 А.О."
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"10403195600837",
         "LastName":"Мусина ",
         "FirstName":"Валентина ",
         "MiddleName":"Джумагазьевна",
         "Address":"Чуйская область Свердловский",
         "Telephone":"0555335154"
      },
      {
         "OrgId":"3277f73d-b02d-4f35-ad1a-80b349eecf6b",
         "PIN":"12310197601006",
         "LastName":"Каратаева ",
         "FirstName":"Гульмира",
         "MiddleName":"Качкыновна",
         "Address":"Ысык-Кульская область Тонский район",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"11005197800749",
         "LastName":"Тайшибаева",
         "FirstName":"Алия",
         "MiddleName":"Бейкутовна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0703615744"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"21301196700097",
         "LastName":"Мисиралиев",
         "FirstName":"Айтбай",
         "MiddleName":"Омаралиевич",
         "Telephone":""
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10403198300215",
         "LastName":"Реверюк",
         "FirstName":"Любовь",
         "MiddleName":"Евгеньевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0558 91 56 15"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"11212197601016",
         "LastName":"Мумбаева ",
         "FirstName":"Айнахан",
         "MiddleName":"Жолболдуевна",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0704371861"
      },
      {
         "OrgId":"64ecd259-a848-474b-8e8d-571612a7e43b",
         "PIN":"12202198800132",
         "LastName":"Омурбекова",
         "FirstName":"Гулай",
         "MiddleName":"Жаныбековна",
         "Address":"Джалал-Абадская область Токтогульский район",
         "Telephone":"0702 593310"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"10112197900659",
         "LastName":"Скурятина",
         "FirstName":"Анна",
         "MiddleName":"Александровна",
         "Address":"Чуйская область Аламединский район",
         "Telephone":""
      },
      {
         "OrgId":"5df10bbc-d256-40f8-b641-5ef31ce11540",
         "PIN":"13005198600654",
         "LastName":"Аралбаева ",
         "FirstName":"Жаркынай ",
         "MiddleName":"Асылбековна",
         "Address":"Чуйская область Панфиловский район",
         "Telephone":"0550005911"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"21402197200356",
         "LastName":"Патанов ",
         "FirstName":"Арстанбек",
         "MiddleName":"Кочкорбаевич ",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0550140224,0552452052"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"12511197200461",
         "LastName":"Асылбекова",
         "FirstName":"Айжан",
         "MiddleName":"Ибраимбековна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0708910553\r\n0556516130"
      },
      {
         "OrgId":"ab27c82d-8348-4dba-ba9c-ceae3a67704e",
         "PIN":"11910198000479",
         "LastName":"Муканбетова",
         "FirstName":"Гулмира",
         "MiddleName":"Карыевна",
         "Address":"Ысык-Кульская область г. Каракол",
         "Telephone":""
      },
      {
         "OrgId":"3bb3cb8f-a747-4026-9031-c6b67ea9735b",
         "PIN":"10202198000323",
         "LastName":"Муратова",
         "FirstName":"Аятбубу",
         "MiddleName":"Каменовна",
         "Address":"Таласская область г. Талас",
         "Telephone":"0700/402902"
      },
      {
         "OrgId":"93df61dd-a2fe-4f52-b961-f1b27bd27a8c",
         "PIN":"12411196700183",
         "LastName":"Калматова",
         "FirstName":"Миргуль",
         "MiddleName":"Мукеновна",
         "Address":"Нарынская область г. Нарын",
         "Telephone":"0707437270"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"22708198901120",
         "LastName":"Абдрахманов ",
         "FirstName":"Алтынбек",
         "MiddleName":"Аблабекович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0559 206209, 0556 180589"
      },
      {
         "OrgId":"3bb3cb8f-a747-4026-9031-c6b67ea9735b",
         "PIN":"12105196700267",
         "LastName":"Ташибекова ",
         "FirstName":"Керимбубу",
         "MiddleName":"Анарбековна",
         "Address":"Таласская область г. Талас",
         "Telephone":"0772604142"
      },
      {
         "OrgId":"3277f73d-b02d-4f35-ad1a-80b349eecf6b",
         "PIN":"20502198501663",
         "LastName":"Аматахунов",
         "FirstName":"Фархат",
         "MiddleName":"Керимакунович",
         "Address":"Ысык-Кульская область Тонский район",
         "Telephone":"0702473873"
      },
      {
         "OrgId":"668d6957-1f76-49de-b9ad-0f2c635df2dd",
         "PIN":"20506197400691",
         "LastName":"Айтпаев ",
         "FirstName":"Мирлан",
         "MiddleName":"Кубатович ",
         "Address":"Чуйская область Кеминский район",
         "Telephone":"0706666939"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"12404197600586",
         "LastName":"Боромбаева",
         "FirstName":"Роза",
         "MiddleName":"Искаковна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0708114511"
      },
      {
         "OrgId":"3277f73d-b02d-4f35-ad1a-80b349eecf6b",
         "PIN":"21208197000124",
         "LastName":"Качаганов",
         "FirstName":"Нурдин",
         "MiddleName":"Сагындыкович",
         "Address":"Ысык-Кульская область Тонский район",
         "Telephone":""
      },
      {
         "OrgId":"51c381f2-6ad6-4382-81f9-3d36e9abea08",
         "PIN":"11906197200333",
         "LastName":"Жолдошова",
         "FirstName":"Анипа",
         "MiddleName":"Эрнисовна",
         "Telephone":""
      },
      {
         "OrgId":"93df61dd-a2fe-4f52-b961-f1b27bd27a8c",
         "PIN":"20308196700958",
         "LastName":"Балтаев",
         "FirstName":"Шайлообек",
         "MiddleName":"Кудайбергенович",
         "Address":"Нарынская область г. Нарын",
         "Telephone":"0700141429"
      },
      {
         "OrgId":"d328865b-ede4-4674-92c6-c134f55c3c6f",
         "PIN":"11710198201133",
         "LastName":"Козубаева",
         "FirstName":"Гулзат",
         "MiddleName":"Борубаевна",
         "Address":"Баткенская область г. Баткен",
         "Telephone":"0770 47-87-40, 0770 68-31-05"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"11502197300522",
         "LastName":"Аманова",
         "FirstName":"Гульнара",
         "MiddleName":"Сыражидиновна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0 555 23 55 88"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"11806197600500",
         "LastName":"Касымалиева",
         "FirstName":"Айгул",
         "MiddleName":"Базарбаевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0708 51 12 88"
      },
      {
         "OrgId":"a3282ffc-bafe-4b4e-99d0-c68509be6133",
         "PIN":"11006197900450",
         "LastName":"Абдуллаева",
         "FirstName":"Зулайка",
         "MiddleName":"Ариповна",
         "Telephone":"0776092020"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"11505197600443",
         "LastName":"Батыркулова",
         "FirstName":"Жылдыз",
         "MiddleName":"Торокулова",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0 700 387275"
      },
      {
         "OrgId":"ab27c82d-8348-4dba-ba9c-ceae3a67704e",
         "PIN":"13011197900581",
         "LastName":"Анищенкова",
         "FirstName":"Олеся",
         "MiddleName":"Ильинична",
         "Address":"Ысык-Кульская область г. Каракол",
         "Telephone":"0778013735 0551013735"
      },
      {
         "OrgId":"668d6957-1f76-49de-b9ad-0f2c635df2dd",
         "PIN":"21209198301144",
         "LastName":"Каширин ",
         "FirstName":"Евгений ",
         "MiddleName":"Александрович",
         "Address":"Чуйская область Кеминский район",
         "Telephone":"0553305578"
      },
      {
         "OrgId":"8b6510f7-3b6b-4c9d-b8a1-2349b78b2cf3",
         "PIN":"10909198200862",
         "LastName":"Орозобекова",
         "FirstName":"Назгул",
         "MiddleName":"Абдылдабековна",
         "Address":"Чуйская область Чуйский район",
         "Telephone":"0550927472"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"10607196700088",
         "LastName":"Абжанбекова",
         "FirstName":"Аймира",
         "MiddleName":"Эркиновна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0708729083\r\n0555928216"
      },
      {
         "OrgId":"e5411cdd-3caf-4798-a7bf-fd57c856c82b",
         "PIN":"21806198301285",
         "LastName":"Туйбаев",
         "FirstName":"Нурбек",
         "MiddleName":"масабирович",
         "Address":"Ошская область Алайский район",
         "Telephone":"0771717560"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"23007198100641",
         "LastName":"Бердиматов",
         "FirstName":"Нурмухаммед",
         "MiddleName":"Абдыкадырович",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0706061277"
      },
      {
         "OrgId":"d328865b-ede4-4674-92c6-c134f55c3c6f",
         "PIN":"12311197700544",
         "LastName":"Маткайымова",
         "FirstName":"урматкан",
         "MiddleName":"Абдижапаровна",
         "Address":"Баткенская область г. Баткен",
         "Telephone":"0771 35-46-06"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10401198500754",
         "LastName":"Нурдин кызы",
         "FirstName":"Жумакан",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0707 320 793"
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"11401197800544",
         "LastName":"Турдакунова ",
         "FirstName":"Назира",
         "MiddleName":"Замирбековна",
         "Address":"Ысык-Кульская область Жети-Огузский район",
         "Telephone":"0702123235"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"12102196400799",
         "LastName":"Чодонбаева ",
         "FirstName":"Бурма",
         "MiddleName":"Жээнбековна",
         "Telephone":""
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"20503197810023",
         "LastName":"Ниязов ",
         "FirstName":"Азамат ",
         "MiddleName":"Каримович ",
         "Telephone":"0705101506"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"12907197600849",
         "LastName":"Минакова",
         "FirstName":"Татьяна",
         "MiddleName":"Дмитриевна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0559 663226, дом.(03131) 5-05-85"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"20205197701288",
         "LastName":"Абиев",
         "FirstName":"Эсен",
         "MiddleName":"Акматбекович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0553335853"
      },
      {
         "OrgId":"3bb3cb8f-a747-4026-9031-c6b67ea9735b",
         "PIN":"10712198100139",
         "LastName":"султаналиева",
         "FirstName":"дамира",
         "MiddleName":"самудиновна",
         "Address":"Таласская область г. Талас",
         "Telephone":"0709365482"
      },
      {
         "OrgId":"c8641d03-b553-4f95-adfb-ec002fd64e06",
         "PIN":"22012196600785",
         "LastName":"Ташбаев",
         "FirstName":"Чилдебай",
         "MiddleName":"Эшимович",
         "Address":"Чуйская область Московский район",
         "Telephone":"0772 921 145"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"20206196610053",
         "LastName":"Осмоналиев",
         "FirstName":"Жаныбек",
         "MiddleName":"Соотбекович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0555 33 66 80"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"20403198100257",
         "LastName":"Доолотбаев",
         "FirstName":"Кайрат",
         "MiddleName":"Молдокматович",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0 555936006, 0555733719"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"12508197601057",
         "LastName":"Токтоматова ",
         "FirstName":"Махабат ",
         "MiddleName":"Ражабаевна",
         "Telephone":"0770371537"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"11312197600056",
         "LastName":"Копцова",
         "FirstName":"Елена",
         "MiddleName":"Михайловна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0555 366040, 0707 366040"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"22509198200526",
         "LastName":"Байгашканов",
         "FirstName":"Мирлан",
         "MiddleName":"Сарлыкович",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0559773383"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"22107198700576",
         "LastName":"Акунов ",
         "FirstName":"Азамат ",
         "MiddleName":"Кадырович",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0702501454\r\n0708533112"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"11501197800489",
         "LastName":"Байгашкаева",
         "FirstName":"Асылкан",
         "MiddleName":"Мурсабековна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0702071121"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"21907199100549",
         "LastName":"Тахтахунов",
         "FirstName":"Хасан",
         "MiddleName":"Муратович",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0553 201-202"
      },
      {
         "OrgId":"a2317371-cc1d-4eb3-8adf-d7ba33b75f87",
         "PIN":"10504198700264",
         "LastName":"Омошова ",
         "FirstName":"Бегаим",
         "MiddleName":"Бектурсуновна",
         "Telephone":""
      },
      {
         "OrgId":"8b6510f7-3b6b-4c9d-b8a1-2349b78b2cf3",
         "PIN":"20902197010100",
         "LastName":"Усонов",
         "FirstName":"Таалайбек",
         "MiddleName":"Ысакович",
         "Address":"Чуйская область Чуйский район",
         "Telephone":"0555366500"
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"11304199200469",
         "LastName":"Мадимарова ",
         "FirstName":"Рахат",
         "MiddleName":"Сайдуллаевна",
         "Address":"Джалал-Абадская область Сузакский район",
         "Telephone":"0773 92-93-97"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"20904197700500",
         "LastName":"Базаркулов",
         "FirstName":"Джунуш",
         "MiddleName":"Кемельбекович",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0555682322"
      },
      {
         "OrgId":"8b6510f7-3b6b-4c9d-b8a1-2349b78b2cf3",
         "PIN":"12009199400056",
         "LastName":"Аюпова",
         "FirstName":"Зухра",
         "MiddleName":"Харсановна",
         "Address":"Чуйская область г. Токмок",
         "Telephone":"0550466266"
      },
      {
         "OrgId":"e4964796-6658-46a9-bd49-a34b3275cb1f",
         "PIN":"12412198100891",
         "LastName":"Адиева",
         "FirstName":"Канымгүл",
         "MiddleName":"Акматбековна",
         "Telephone":""
      },
      {
         "OrgId":"93df61dd-a2fe-4f52-b961-f1b27bd27a8c",
         "PIN":"13110196800268",
         "LastName":"Какишова",
         "FirstName":"Чынаркул",
         "MiddleName":"Нукеевна",
         "Address":"Нарынская область г. Нарын",
         "Telephone":"0708824875"
      },
      {
         "OrgId":"23db562e-c0b7-4b7e-ac16-967c96500444",
         "PIN":"11001197600934",
         "LastName":"Шабданбекова",
         "FirstName":"Миража",
         "MiddleName":"темирбековна",
         "Address":"Ысык-Кульская область г. Балыкчы",
         "Telephone":"0776836366"
      },
      {
         "OrgId":"8b739bfd-cf8f-4380-a246-8a34bce9a231",
         "PIN":"22712197800133",
         "LastName":"Макыев",
         "FirstName":"Канатбек",
         "MiddleName":"Турганбаевич",
         "Telephone":"0770853295"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"20708198900480",
         "LastName":"Балыбаев",
         "FirstName":"Улан",
         "MiddleName":"Кенжебекович",
         "Address":"Таласская область Таласский район",
         "Telephone":"0703 46 96 58\r\n0556 77 06 27 \r\n0708 74 37 70 "
      },
      {
         "OrgId":"64ecd259-a848-474b-8e8d-571612a7e43b",
         "PIN":"10509197600212",
         "LastName":"Тулекеева",
         "FirstName":"Асылбү",
         "MiddleName":"Таштемировна",
         "Address":"Джалал-Абадская область Токтогульский район",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"21007196900799",
         "LastName":"Казымбеков",
         "FirstName":"Мирбек",
         "MiddleName":"Рыскульбекович",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0551929769"
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"12504198902102",
         "LastName":"Умарова ",
         "FirstName":"Мукаддам ",
         "MiddleName":"Абдужабборовна ",
         "Address":"Джалал-Абадская область г. Джалал-Абад",
         "Telephone":"0779 52-75-54,\r\n0771 11-79-34"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"11809197800256",
         "LastName":"Уметалиева ",
         "FirstName":"Гулзат ",
         "MiddleName":"Осмонкуловна",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0708002769\r\n0707644453"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"21404198501078",
         "LastName":"Ашуров",
         "FirstName":"Рысбек",
         "MiddleName":"Муроталиевич",
         "Address":"Баткенская область Баткенский район",
         "Telephone":"0771450085"
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"10411197600658",
         "LastName":"Жалилова ",
         "FirstName":"Феруза",
         "MiddleName":"Садыковна",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":"0778070058"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"13004196800400",
         "LastName":"Осекова",
         "FirstName":"Динара",
         "MiddleName":"Усеновна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"+996703002154"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840153*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"c7b6aa81-a4b7-4ad6-be03-e4b54e8084da",
         "PIN":"11402198101253",
         "LastName":"Баратова",
         "FirstName":"Арзыгүл",
         "MiddleName":"Файзуллаевна",
         "Address":"Баткенская область Лейлекский район",
         "Telephone":"0779343633"
      },
      {
         "OrgId":"51c381f2-6ad6-4382-81f9-3d36e9abea08",
         "PIN":"21512198000037",
         "LastName":"Алыкулов",
         "FirstName":"Исламбек",
         "MiddleName":"Жээнбекович",
         "Address":"Нарынская область Кочкорский район",
         "Telephone":""
      },
      {
         "OrgId":"ab27c82d-8348-4dba-ba9c-ceae3a67704e",
         "PIN":"12904196100406",
         "LastName":"Турдушева",
         "FirstName":"Анара",
         "MiddleName":"Коношевна",
         "Address":"Ысык-Кульская область г. Каракол",
         "Telephone":""
      },
      {
         "OrgId":"30658f5a-dc22-4a7a-aaf6-63dfb7070c50",
         "PIN":"11704197000837",
         "LastName":"Сулайманова ",
         "FirstName":"Айнур",
         "MiddleName":"Суеркуловна ",
         "Address":"Нарынская область Жумгальский район",
         "Telephone":"0703 15-36-44"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"10904196100022",
         "LastName":"Чомоева",
         "FirstName":"Уруйла ",
         "MiddleName":"Бейшеевна",
         "Address":"Чуйская область Свердловский",
         "Telephone":"637739\r\n0777325147"
      },
      {
         "OrgId":"5df10bbc-d256-40f8-b641-5ef31ce11540",
         "PIN":"10901198001203",
         "LastName":"Касмалиева",
         "FirstName":"Кундуз",
         "MiddleName":"Бектургановна",
         "Address":"Чуйская область Панфиловский район",
         "Telephone":""
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"22706195700297",
         "LastName":"Кочкорбаев",
         "FirstName":"Мукамбедин",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0553 773125, 0556 153416"
      },
      {
         "OrgId":"a2317371-cc1d-4eb3-8adf-d7ba33b75f87",
         "PIN":"13005196100393",
         "LastName":"Осмонова",
         "FirstName":"Ыргалбү",
         "MiddleName":"Акматовна",
         "Address":"Ошская область Кара-Кулжинский район",
         "Telephone":"0771812827"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"13103198000191",
         "LastName":"Данкаева",
         "FirstName":"Нурзада",
         "MiddleName":"Тыныбековна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0502 090376"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840121*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"668d6957-1f76-49de-b9ad-0f2c635df2dd",
         "PIN":"12007197500138",
         "LastName":"Жумалиева ",
         "FirstName":"Нарынкул",
         "MiddleName":"Комбаевна",
         "Address":"Чуйская область Кеминский район",
         "Telephone":"0705513608"
      },
      {
         "OrgId":"3bb3cb8f-a747-4026-9031-c6b67ea9735b",
         "PIN":"10906198801755",
         "LastName":"Өмүрзакова",
         "FirstName":"Жанна",
         "MiddleName":"Асанбековна",
         "Address":"Таласская область Таласский район",
         "Telephone":"0700533619"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"22812198300977",
         "LastName":"Бообеков",
         "FirstName":"Урмат ",
         "MiddleName":"Шамшидинович",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0555 303369"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"22705198000843",
         "LastName":"Темербаев",
         "FirstName":"Эрмек",
         "MiddleName":"Уруспекович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0700 014 131, 0701 102 871"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"10409197600423",
         "LastName":"Насырымбекова ",
         "FirstName":"Паризат",
         "MiddleName":"Курманбековна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0708239226"
      },
      {
         "OrgId":"30658f5a-dc22-4a7a-aaf6-63dfb7070c50",
         "PIN":"21912196300099",
         "LastName":"Султанов ",
         "FirstName":"Савырбек ",
         "MiddleName":"Нургазиевич",
         "Address":"Чуйская область Чуйский район",
         "Telephone":"0556 00-55-52"
      },
      {
         "OrgId":"2765827a-88ef-4144-8050-9a2fd66fb136",
         "PIN":"11512197800111",
         "LastName":"Юсупбаева",
         "FirstName":"Гульчехра",
         "MiddleName":"Джороевна",
         "Address":"Джалал-Абадская область Аксыйский район",
         "Telephone":"0770658088"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"21010197201207",
         "LastName":"Омурбеков",
         "FirstName":"Анарбек",
         "MiddleName":"Асанович",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0771660343\r\n0708160246"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"12112197100519",
         "LastName":"Гриднева",
         "FirstName":"Ольга",
         "MiddleName":"Юрьевна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0555324076"
      },
      {
         "OrgId":"9afa2870-4609-4d8a-8417-11c2dbdec94b",
         "PIN":"12305199200453",
         "LastName":"Мажайко",
         "FirstName":"Алиса",
         "Address":"Джалал-Абадская область г. Майлуу-Суу",
         "Telephone":"(03744) 5-03-40"
      },
      {
         "OrgId":"3bb3cb8f-a747-4026-9031-c6b67ea9735b",
         "PIN":"10105197501341",
         "LastName":"Турсункулова",
         "FirstName":"Чолпон",
         "MiddleName":"Анаркуловна",
         "Address":"Таласская область г. Талас",
         "Telephone":"0771343034"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"20412197101012",
         "LastName":"Толубаев",
         "FirstName":"Руслан",
         "MiddleName":"Кагановичевич",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0707027064, 0705027064"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"11604197901029",
         "LastName":"Абдувалиева",
         "FirstName":"Наталья",
         "MiddleName":"Александровна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0557-22-02-09"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"20606198500045",
         "LastName":"Эрматов",
         "FirstName":"Чингиз",
         "MiddleName":"Космосбекович",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0551789818\r\n0700769205"
      },
      {
         "OrgId":"93df61dd-a2fe-4f52-b961-f1b27bd27a8c",
         "PIN":"20807198300025",
         "LastName":"Мусаев",
         "FirstName":"Бактыбек",
         "MiddleName":"Тынымсеитович",
         "Address":"Нарынская область г. Нарын",
         "Telephone":"0707889906"
      },
      {
         "OrgId":"7f311898-d254-4668-8184-d1794e695a19",
         "PIN":"12411198400200",
         "LastName":"Сманова ",
         "FirstName":"Мээрим ",
         "MiddleName":"Базарбековна ",
         "Address":"Таласская область Бакай-Атинский район",
         "Telephone":"0779950540"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"22412197100215",
         "LastName":"Михайленко",
         "FirstName":"Юрий",
         "MiddleName":"Александрович",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0555575350"
      },
      {
         "OrgId":"60f22545-cb84-4795-81a3-2eeeeb0fb88a",
         "PIN":"11503198501507",
         "LastName":"ТАЖИБАЕВА",
         "FirstName":"ТОЛКУНАЙ",
         "MiddleName":"НУРБАЕВНА",
         "Address":"Джалал-Абадская область Ала-Букинский район",
         "Telephone":"0777 53 31 85"
      },
      {
         "OrgId":"c748cdb5-502a-4243-8494-48e224968019",
         "PIN":"10304198901406",
         "LastName":"Мааданбекова",
         "FirstName":"Айзат",
         "MiddleName":"Асановна",
         "Address":"Ысык-Кульская область Тюпский район",
         "Telephone":"0701058561"
      },
      {
         "OrgId":"3bb3cb8f-a747-4026-9031-c6b67ea9735b",
         "PIN":"12103197700087",
         "LastName":"Мамыратова",
         "FirstName":"Бакбубу",
         "MiddleName":"Облусбековна",
         "Telephone":"0706 363243"
      },
      {
         "OrgId":"51c381f2-6ad6-4382-81f9-3d36e9abea08",
         "PIN":"10204197400466",
         "LastName":"Уметалиева ",
         "FirstName":"Чолпон",
         "MiddleName":"Чодуровна",
         "Address":"Нарынская область Кочкорский район",
         "Telephone":"0700642777"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"11503197100051",
         "LastName":"Манапбаева ",
         "FirstName":"Жаныл",
         "MiddleName":"Толошовна",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0705334356"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"11406198600923",
         "LastName":"Исаева ",
         "FirstName":"Клара",
         "MiddleName":"Сагындыковна",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0700252504\r\n0700898080"
      },
      {
         "OrgId":"64ecd259-a848-474b-8e8d-571612a7e43b",
         "PIN":"10208198700693",
         "LastName":"Кыдыралиева",
         "FirstName":"Айчүрөк",
         "MiddleName":"Эдилбековна",
         "Address":"Джалал-Абадская область Токтогульский район",
         "Telephone":"0702 972889"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"12308197400107",
         "LastName":"Мухаметсалиева",
         "FirstName":"Жылдыз",
         "MiddleName":"Булатовна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0700252223"
      },
      {
         "OrgId":"5df10bbc-d256-40f8-b641-5ef31ce11540",
         "PIN":"12502197700058",
         "LastName":"Кадацкая",
         "FirstName":"Ольга",
         "MiddleName":"Викторовна",
         "Address":"Чуйская область Панфиловский район",
         "Telephone":"0556433782"
      },
      {
         "OrgId":"7f311898-d254-4668-8184-d1794e695a19",
         "PIN":"12411197800088",
         "LastName":"Батырбекова ",
         "FirstName":"Назира ",
         "MiddleName":"Талантовна ",
         "Address":"Таласская область Бакай-Атинский район",
         "Telephone":"0773061308"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"10107197900052",
         "LastName":"Алпышова ",
         "FirstName":"Жылдыз ",
         "MiddleName":"Беккуловна",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0708396355"
      },
      {
         "OrgId":"ab27c82d-8348-4dba-ba9c-ceae3a67704e",
         "PIN":"12504198300391",
         "LastName":"Одноварченко ",
         "FirstName":"Юлия ",
         "MiddleName":"Юрьевна",
         "Address":"Ысык-Кульская область г. Каракол",
         "Telephone":""
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"23108198200292",
         "LastName":"Исаев",
         "FirstName":"Эмиль",
         "MiddleName":"Эрнстович",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0772 23 0003"
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"22307197800523",
         "LastName":"Маметкулов",
         "FirstName":"Омор",
         "MiddleName":"Жанысапович ",
         "Address":"Джалал-Абадская область Ноокенский район",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"11208196901106",
         "LastName":"Батырканова",
         "FirstName":"Чынаркул",
         "MiddleName":"Шаршеевна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0550 399 441"
      },
      {
         "OrgId":"64ecd259-a848-474b-8e8d-571612a7e43b",
         "PIN":"10709199100918",
         "LastName":"Майрамбек кызы",
         "FirstName":"Айжамал",
         "Address":"Джалал-Абадская область Токтогульский район",
         "Telephone":"0706 005229"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10307198300816",
         "LastName":"Мусуралиева",
         "FirstName":"Айжаркын",
         "MiddleName":"Жаныбековна",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0701633990"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"20410198801420",
         "LastName":"Бакиров",
         "FirstName":"Сайфидин",
         "MiddleName":"Гаффарович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0702554507,0779376204"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"1040419960089*",
         "LastName":"КОЖОГУЛОВА",
         "FirstName":"АЛТЫНАЙ",
         "MiddleName":"КАМЧИБЕКОВНА",
         "Address":"Таласская область Аксыйский район",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"22505197900516",
         "LastName":"Осмоналиев",
         "FirstName":"Каныбек",
         "MiddleName":"Раимбекович",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0705108394"
      },
      {
         "OrgId":"5df10bbc-d256-40f8-b641-5ef31ce11540",
         "PIN":"21204197400813",
         "LastName":"Рыскулов",
         "FirstName":"Мерлис",
         "MiddleName":"Узбекович",
         "Address":"Чуйская область Панфиловский район",
         "Telephone":"0777057041"
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"10503196701164",
         "LastName":"Абдрасулова",
         "FirstName":"Буфатима",
         "MiddleName":"Апсатаровна",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":"0779523135"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10101197001075",
         "LastName":"Турсуналиева",
         "FirstName":"Толкун",
         "MiddleName":"Сабыралиевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0555 670 223"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"22304198701012",
         "LastName":"Токтогулов ",
         "FirstName":"Габит",
         "MiddleName":"Токтогулович",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0551425042,0559198598"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"20505198700396",
         "LastName":"Исмаилов",
         "FirstName":"Нургазы",
         "MiddleName":"Махамматаипович",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0706050527"
      },
      {
         "OrgId":"668d6957-1f76-49de-b9ad-0f2c635df2dd",
         "PIN":"21509198101223",
         "LastName":"Самаркулов ",
         "FirstName":"Нурбек",
         "MiddleName":"Дуйшенбекович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0705 327-356\r\n0550 327-356"
      },
      {
         "OrgId":"c8641d03-b553-4f95-adfb-ec002fd64e06",
         "PIN":"22303197900940",
         "LastName":"Айбашов",
         "FirstName":"Нурлан",
         "MiddleName":"Бердибекович",
         "Address":"Чуйская область Московский район",
         "Telephone":"0550 720 444"
      },
      {
         "OrgId":"14eb7aef-c331-4085-a83e-0f5eebf2eaa3",
         "PIN":"11212198500735",
         "LastName":"Капарова ",
         "FirstName":"Жанетта",
         "MiddleName":"Капаровна",
         "Address":"Ысык-Кульская область Ысык-Кульский район",
         "Telephone":""
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"20806198800789",
         "LastName":"Моминов",
         "FirstName":"Ихтиержон",
         "MiddleName":"Бахромович",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":"0557474714"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"10205197000015",
         "LastName":"Шаршенбаева ",
         "FirstName":"Замира ",
         "MiddleName":"Ахметовна",
         "Address":"Чуйская область Свердловский",
         "Telephone":"0703744366\r\n0550744366"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"20705198600117",
         "LastName":"Сатылбалдиев",
         "FirstName":"Даниэл",
         "MiddleName":"Анарбекович",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0555595557\r\n0556503535"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10105197201384",
         "LastName":"Джакыпова",
         "FirstName":"Асель",
         "MiddleName":"Абдыкасымовна",
         "Address":"Ысык-Кульская область Ак-Суйский район",
         "Telephone":"0702 845188, 0772784817"
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"11504198000168",
         "LastName":"Балбаева ",
         "FirstName":"Жадыра",
         "MiddleName":"Зарипбековна",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":"0556019981"
      },
      {
         "OrgId":"14eb7aef-c331-4085-a83e-0f5eebf2eaa3",
         "PIN":"11511196600388",
         "LastName":"Шаршеева",
         "FirstName":"Жыпаркул",
         "MiddleName":"Зарлыковна",
         "Address":"Ысык-Кульская область Ысык-Кульский район",
         "Telephone":"0707-58-48-28"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"36985555555512",
         "LastName":"Мартиросян",
         "FirstName":"Кирилл",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0777-77-77-77"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"21410198000893",
         "LastName":"Токтосунов ",
         "FirstName":"Эрнест",
         "MiddleName":"Белекович",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0700487372\r\n0507670624"
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"11812196700632",
         "LastName":"Ирсалиева",
         "FirstName":"Бурул",
         "MiddleName":"Садановна",
         "Address":"Ысык-Кульская область Жети-Огузский район",
         "Telephone":"0702730186"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"21706198100718",
         "LastName":"Кулжабаев",
         "FirstName":"Улан",
         "MiddleName":"Султанаалиевич",
         "Address":"Чуйская область Аламединский район",
         "Telephone":""
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"10701195300100",
         "LastName":"Орозова",
         "FirstName":"Галия",
         "MiddleName":"Карыбаевна",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0708694565"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"21007196900799",
         "LastName":"Казымбеков",
         "FirstName":"Мирбек",
         "MiddleName":"Рыскульбекович",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"+996552933272"
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"20803198300824",
         "LastName":"Орозбаев ",
         "FirstName":"Кубанычбек",
         "MiddleName":"Узакович",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":"0552460026"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"22211019750058",
         "LastName":"Айталиев",
         "FirstName":"Ильяс",
         "MiddleName":"Маатказыевич",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0 509 609 679"
      },
      {
         "OrgId":"30658f5a-dc22-4a7a-aaf6-63dfb7070c50",
         "PIN":"22004196701336",
         "LastName":"Изаев ",
         "FirstName":"Куватбек ",
         "MiddleName":"Толенович ",
         "Address":"Таласская область Кара-Бууринский район",
         "Telephone":"0551687425"
      },
      {
         "OrgId":"aa65c2f7-3091-40e5-939d-6a18250e125a",
         "PIN":"13003197700049",
         "LastName":"Кадырова",
         "FirstName":"Нургүл",
         "MiddleName":"Темирбековна",
         "Address":"Джалал-Абадская область г. Кара-Куль",
         "Telephone":"0777505845"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10812196500873",
         "LastName":"Джумабаева",
         "FirstName":"Назира",
         "MiddleName":"Барпиевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0700020640"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"12210197300354",
         "LastName":"Каракеева",
         "FirstName":"Айнура",
         "MiddleName":"Казыбековна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0704 700 096"
      },
      {
         "OrgId":"30658f5a-dc22-4a7a-aaf6-63dfb7070c50",
         "PIN":"11702197510018",
         "LastName":"Сыдыкова ",
         "FirstName":"Миргул",
         "MiddleName":"Туратбековна",
         "Telephone":"0700 53-66-13;   0559 66-96-79"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"21604199100802",
         "LastName":"Путилин",
         "FirstName":"Антон",
         "MiddleName":"Алексеевич",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0550517280"
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"12706197800870",
         "LastName":"Тойчуева ",
         "FirstName":"Сонунгул",
         "MiddleName":"Болушбековна ",
         "Address":"Джалал-Абадская область г. Джалал-Абад",
         "Telephone":""
      },
      {
         "OrgId":"8b739bfd-cf8f-4380-a246-8a34bce9a231",
         "PIN":"21208198100017",
         "LastName":"Тургунов ",
         "FirstName":"Умид",
         "MiddleName":"Адхамжанович",
         "Telephone":"0557008683"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"10203198300674",
         "LastName":"Темирбек кызы",
         "FirstName":"Назгул",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0703881219"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"11012198101078",
         "LastName":"Каратаева",
         "FirstName":"Самара",
         "MiddleName":"Уметбековна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0702525683"
      },
      {
         "OrgId":"2765827a-88ef-4144-8050-9a2fd66fb136",
         "PIN":"11411197700367",
         "LastName":"Абдиева",
         "FirstName":"Гүлгаакы",
         "MiddleName":"Азанбековна",
         "Address":"Джалал-Абадская область Аксыйский район",
         "Telephone":"0777415827"
      },
      {
         "OrgId":"5ee1ac59-52a1-45e3-84ec-54ad09fc9f1e",
         "PIN":"11605198400674",
         "LastName":"Минбаева",
         "FirstName":"Гулзат",
         "MiddleName":"Аскаровна",
         "Address":"Баткенская область Баткенский район",
         "Telephone":"0776663835"
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"12707198000740",
         "LastName":"Асыранова ",
         "FirstName":"Таннур ",
         "MiddleName":"Чынбердиевна ",
         "Telephone":""
      },
      {
         "OrgId":"e5411cdd-3caf-4798-a7bf-fd57c856c82b",
         "PIN":"21806198301285",
         "LastName":"Туйбаев",
         "FirstName":"Нурбек",
         "MiddleName":"Масабирович",
         "Address":"Ошская область Алайский район",
         "Telephone":"0771717560"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10402196600246",
         "LastName":"Тохтыева",
         "FirstName":"Хуршидям",
         "MiddleName":"Таировна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0 772 48 09 62"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"20103197801094",
         "LastName":"Сакиев",
         "FirstName":"Мирлан",
         "MiddleName":"Жумадылович",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0500440909"
      },
      {
         "OrgId":"668d6957-1f76-49de-b9ad-0f2c635df2dd",
         "PIN":"11102197101295",
         "LastName":"Абдиева ",
         "FirstName":"Лира ",
         "MiddleName":"Кылычбековна ",
         "Address":"Чуйская область Кеминский район",
         "Telephone":"0704471541"
      },
      {
         "OrgId":"23db562e-c0b7-4b7e-ac16-967c96500444",
         "PIN":"21704198500557",
         "LastName":"Өскөмбаев",
         "FirstName":"Таалайбек",
         "MiddleName":"Амангулович",
         "Address":"Ысык-Кульская область г. Балыкчы",
         "Telephone":"0709850304"
      },
      {
         "OrgId":"23db562e-c0b7-4b7e-ac16-967c96500444",
         "PIN":"21401196800339",
         "LastName":"СУЛТАНОВ",
         "FirstName":"АЛМАЗБЕК",
         "MiddleName":"СУЮМБАЕВИЧ",
         "Address":"Ысык-Кульская область Тонский район",
         "Telephone":"0705393282, 0702221319"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"13006196900142",
         "LastName":"Шмитько",
         "FirstName":"Светлана",
         "MiddleName":"Викторовна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"+996705328240"
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"12403197600253",
         "LastName":"Айдарова ",
         "FirstName":"Мактым",
         "MiddleName":"Женишбековна ",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"12110196400339",
         "LastName":"Онищенко",
         "FirstName":"Галина",
         "MiddleName":"Александровна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0553472797"
      },
      {
         "OrgId":"8b739bfd-cf8f-4380-a246-8a34bce9a231",
         "PIN":"12612198301213",
         "LastName":"Исмаилов ",
         "FirstName":"Икрам",
         "MiddleName":"Ибрагимжанович",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"c7b6aa81-a4b7-4ad6-be03-e4b54e8084da",
         "PIN":"11805198801320",
         "LastName":"Якубжанова",
         "FirstName":"Мухайё",
         "MiddleName":"Рахматуллоевна",
         "Address":"Баткенская область Лейлекский район",
         "Telephone":" 0777783434"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840121*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"51c381f2-6ad6-4382-81f9-3d36e9abea08",
         "PIN":"10204197400466",
         "LastName":"Уметалиева ",
         "FirstName":"Чолпон",
         "MiddleName":"Чодуровна",
         "Address":"Нарынская область Кочкорский район",
         "Telephone":"0700642777"
      },
      {
         "OrgId":"93df61dd-a2fe-4f52-b961-f1b27bd27a8c",
         "PIN":"22512195210050",
         "LastName":"Байзаков",
         "FirstName":"Барктабас",
         "MiddleName":"-",
         "Address":"Нарынская область г. Нарын",
         "Telephone":"0704120452"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"21206196400904",
         "LastName":"Рысдолотов",
         "FirstName":"Самыйбек",
         "MiddleName":"Жумадылович",
         "Address":"Ысык-Кульская область Тюпский район",
         "Telephone":"0706808553"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"21205197400818",
         "LastName":"Сулайманкулов",
         "FirstName":"Авазбек",
         "MiddleName":"Тологонович",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0703748001\r\n0707488338"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"--------------",
         "LastName":"Турсунов",
         "FirstName":"Мирзо",
         "MiddleName":"Ахматжонович",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0557 77-92-98\r\n0778 22-59-79"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"21510198800015",
         "LastName":"Асанкулов",
         "FirstName":"Нурбек",
         "MiddleName":"Толонович",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0706055050"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"10812197900229",
         "LastName":"Аликеева",
         "FirstName":"Эрмек",
         "MiddleName":"Адыловна",
         "Telephone":"0554 18 27 08\r\n0705 18 27 08"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"11305197000878",
         "LastName":"Сейталиева",
         "FirstName":"Индира",
         "MiddleName":"Ниязбековна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0703 636412"
      },
      {
         "OrgId":"8b739bfd-cf8f-4380-a246-8a34bce9a231",
         "PIN":"21001196000964",
         "LastName":"Кадыров",
         "FirstName":"Абдумутал",
         "MiddleName":"Абдулакимович",
         "Telephone":"0777111130"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"12309197200105",
         "LastName":"Мырзаахматова",
         "FirstName":"Айнура",
         "MiddleName":"Куватбековна",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0552 078301"
      },
      {
         "OrgId":"ab27c82d-8348-4dba-ba9c-ceae3a67704e",
         "PIN":"13011197900581",
         "LastName":"Ташикова",
         "FirstName":"Айнура ",
         "MiddleName":"Кусеиновна",
         "Telephone":""
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"12112197100544",
         "LastName":"Какишова",
         "FirstName":"Назгул",
         "MiddleName":"Нуукеевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0555881018"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"21110198201045",
         "LastName":"Солтоев",
         "FirstName":"Бактияр",
         "MiddleName":"Кабылович",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0709011674\r\n0779668881"
      },
      {
         "OrgId":"8b6510f7-3b6b-4c9d-b8a1-2349b78b2cf3",
         "PIN":"20210197500270",
         "LastName":"Кадыркулов",
         "FirstName":"Узакбек",
         "MiddleName":"Сагыналиевич",
         "Address":"Чуйская область г. Токмок",
         "Telephone":"0707082975"
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"10302198401038",
         "LastName":"Мамакеева",
         "FirstName":"Жаныгул",
         "MiddleName":"Усонкалиевна",
         "Address":"Ысык-Кульская область Жети-Огузский район",
         "Telephone":"0702030284"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"12509197500016",
         "LastName":"Хван",
         "FirstName":"Анастасия",
         "MiddleName":"Павловна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0700738891"
      },
      {
         "OrgId":"32af3ea3-4100-406f-8825-20cb8a1877aa",
         "PIN":"22312197700615",
         "LastName":"Цой",
         "FirstName":"Лев",
         "MiddleName":"Михайлович",
         "Address":"Баткенская область г. Кызыл-Кия",
         "Telephone":""
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"21407198900693",
         "LastName":"Маатказиев",
         "FirstName":"Аман",
         "Address":"г.Бишкек Аламединский район",
         "Telephone":"0505100515"
      },
      {
         "OrgId":"ab27c82d-8348-4dba-ba9c-ceae3a67704e",
         "PIN":"10606197200308",
         "LastName":"Попова",
         "FirstName":"Оксана",
         "MiddleName":"Сергеевна",
         "Address":"Ысык-Кульская область Ыссык-Атинский район",
         "Telephone":""
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840120*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"21210198301487",
         "LastName":"Уразабаев",
         "FirstName":"Адылбек",
         "MiddleName":"Джумалиевич",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0705552487"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"11205196400012",
         "LastName":"Батракеева",
         "FirstName":"Гульнара",
         "MiddleName":"Эбишевна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"+996553228815"
      },
      {
         "OrgId":"c8641d03-b553-4f95-adfb-ec002fd64e06",
         "PIN":"21208198301478",
         "LastName":"Савенко",
         "FirstName":"Николай",
         "MiddleName":"Сергеевич",
         "Address":"Чуйская область Московский район",
         "Telephone":"0554 555 223"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"22504197300649",
         "LastName":"Сыдыкакунов",
         "FirstName":"Тариел",
         "MiddleName":"Аскералиевич",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0708450788"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"21301196700097",
         "LastName":"Мисиралиев",
         "FirstName":"Айтбай",
         "MiddleName":"Омаралиевич",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"12704196500589",
         "LastName":"Мамбеталиева",
         "FirstName":"Эльмира",
         "MiddleName":"Чоюновна",
         "Address":"Джалал-Абадская область Токтогульский район",
         "Telephone":"0702404362\r\n"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"22412198801395",
         "LastName":"Искитов",
         "FirstName":"Абдулазис",
         "MiddleName":"Дохдурбекович",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0550161522"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"21512097200073",
         "LastName":"Филатов",
         "FirstName":"Аман",
         "MiddleName":"Турдубекович",
         "Address":"Ысык-Кульская область Тюпский район",
         "Telephone":"0705534477,0505151272"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"11011197500888",
         "LastName":"Усенова",
         "FirstName":"Тахмина",
         "MiddleName":"Абдраимовна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0708 946688, 0709 946688"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"11806197600500",
         "LastName":"Касымалиева",
         "FirstName":"Айгүль",
         "MiddleName":"Базарбаевна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0701 51 12 88\r\n0556 38 46 38 "
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"11610197400201",
         "LastName":"Зайцева ",
         "FirstName":"Светлана",
         "MiddleName":"Сергеевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0 312 54-00 54"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"11603198010016",
         "LastName":"Акимова",
         "FirstName":"Бермет",
         "MiddleName":"Куванычбековна",
         "Address":"Чуйская область Аламединский район",
         "Telephone":""
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"10610198900178",
         "LastName":"Эшенова ",
         "FirstName":"Жылдыз",
         "MiddleName":"Доолоткелдиевна",
         "Address":"Ысык-Кульская область Жети-Огузский район",
         "Telephone":"0704480089"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"11105197300001",
         "LastName":"Кадырова",
         "FirstName":"Аида",
         "MiddleName":"Букарбековна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0554 550 345"
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"10908196610048",
         "LastName":"Насирова",
         "FirstName":"Тажихан",
         "MiddleName":"Худойбердиевна",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":"0755065801"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"10804198200071",
         "LastName":"Рахимбаева",
         "FirstName":"Ботокөз",
         "MiddleName":"Орымбаевна",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0773830472"
      },
      {
         "OrgId":"3bb3cb8f-a747-4026-9031-c6b67ea9735b",
         "PIN":"11910199001351",
         "LastName":"Сыргакбек кызы",
         "FirstName":"Нуркан",
         "Address":"Таласская область Таласский район",
         "Telephone":"0706350356"
      },
      {
         "OrgId":"3bb3cb8f-a747-4026-9031-c6b67ea9735b",
         "PIN":"20607197200517",
         "LastName":"Бейшебаев",
         "FirstName":"Жакып",
         "MiddleName":"Жээнбекович",
         "Address":"Таласская область Таласский район",
         "Telephone":"0703060772"
      },
      {
         "OrgId":"51c381f2-6ad6-4382-81f9-3d36e9abea08",
         "PIN":"21808197900687",
         "LastName":"Токтомушов",
         "FirstName":"Туйгунбек",
         "MiddleName":"Исабекович",
         "Address":"Нарынская область Кочкорский район",
         "Telephone":"0705208780"
      },
      {
         "OrgId":"8b6510f7-3b6b-4c9d-b8a1-2349b78b2cf3",
         "PIN":"22009198000437",
         "LastName":"Бердибеков",
         "FirstName":"Кайратбек",
         "MiddleName":"Жумакадырович",
         "Address":"Чуйская область г. Токмок",
         "Telephone":"0708200980"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"10701195300100",
         "LastName":"Орозова",
         "FirstName":"Галия",
         "MiddleName":"Карыбаевна",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0708694565"
      },
      {
         "OrgId":"8b739bfd-cf8f-4380-a246-8a34bce9a231",
         "PIN":"21710198200635",
         "LastName":"Ниматуллаев",
         "FirstName":"Худайберди",
         "MiddleName":"Халилджанович",
         "Telephone":""
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"12310197200776",
         "LastName":"Мамырбаева",
         "FirstName":"Айгуль",
         "MiddleName":"Жусуповна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"+9960709248954"
      },
      {
         "OrgId":"9afa2870-4609-4d8a-8417-11c2dbdec94b",
         "PIN":"12401196400535",
         "LastName":"Чатаева ",
         "FirstName":"Гульнара",
         "MiddleName":"Калдаровна",
         "Address":"Джалал-Абадская область г. Майлуу-Суу",
         "Telephone":"0556404839"
      },
      {
         "OrgId":"668d6957-1f76-49de-b9ad-0f2c635df2dd",
         "PIN":"11102197101295",
         "LastName":"Абдиева ",
         "FirstName":"Лира ",
         "MiddleName":"Кылычбековна ",
         "Address":"Чуйская область Кеминский район",
         "Telephone":"0704471541"
      },
      {
         "OrgId":"d62e0b83-e589-491d-b0a4-8bea42178231",
         "PIN":"10208196600503",
         "LastName":"Борубаева ",
         "FirstName":"Эльмира",
         "MiddleName":"Абдылдаевна",
         "Telephone":"0772140592"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"20606198500045",
         "LastName":"Эрматов",
         "FirstName":"Чингиз",
         "MiddleName":"Космосбекович",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0551789818\r\n0700769205"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"21112197900448",
         "LastName":"Алыбаев",
         "FirstName":"Жаныш",
         "MiddleName":"Каныбекович",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"+996550028916"
      },
      {
         "OrgId":"668d6957-1f76-49de-b9ad-0f2c635df2dd",
         "PIN":"10109197700371",
         "LastName":"Ченгелова ",
         "FirstName":"Аида",
         "MiddleName":"Бектуровна ",
         "Address":"Чуйская область Кеминский район",
         "Telephone":"0707258784"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"12004197200306",
         "LastName":"Кутуева",
         "FirstName":"Рахат",
         "MiddleName":"Мукамедиевна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0770340781"
      },
      {
         "OrgId":"23db562e-c0b7-4b7e-ac16-967c96500444",
         "PIN":"11307198550023",
         "LastName":"Төлөнгутова",
         "FirstName":"Айзат",
         "MiddleName":"Өмүракуновна",
         "Address":"Ысык-Кульская область г. Балыкчы",
         "Telephone":"0507 010610"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"21805197300602",
         "LastName":"Сенькин",
         "FirstName":"Дмитрий",
         "MiddleName":"Геннадьевич",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"+996555095255"
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"1260199200364",
         "LastName":"Усен кызы",
         "FirstName":"Айчурок",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":"0776 11 37 27 "
      },
      {
         "OrgId":"64ecd259-a848-474b-8e8d-571612a7e43b",
         "PIN":"10703197200696",
         "LastName":"Мамыралиева",
         "FirstName":"Динара",
         "MiddleName":"Шаршеновна",
         "Address":"Джалал-Абадская область Токтогульский район",
         "Telephone":"0709 368381"
      },
      {
         "OrgId":"e4964796-6658-46a9-bd49-a34b3275cb1f",
         "PIN":"12502195400399",
         "LastName":"Бармалтаева",
         "FirstName":"Октом",
         "MiddleName":"Додоновна",
         "Address":"Джалал-Абадская область Ноокенский район",
         "Telephone":"0554454171"
      },
      {
         "OrgId":"d328865b-ede4-4674-92c6-c134f55c3c6f",
         "PIN":"22605198300870",
         "LastName":"Акматов",
         "FirstName":"Абдыбек",
         "MiddleName":"Мамадиевич",
         "Address":"Баткенская область г. Баткен",
         "Telephone":"0774 29-21-43"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"21401197200011",
         "LastName":"Бельфор",
         "FirstName":"Андрей",
         "MiddleName":"Семенович",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0773003773"
      },
      {
         "OrgId":"3277f73d-b02d-4f35-ad1a-80b349eecf6b",
         "PIN":"21401196800339",
         "LastName":"Султанов ",
         "FirstName":"Алмазбек",
         "MiddleName":"Суюмбаевич",
         "Address":"Ысык-Кульская область Тонский район",
         "Telephone":"0702221319"
      },
      {
         "OrgId":"14eb7aef-c331-4085-a83e-0f5eebf2eaa3",
         "PIN":"20711198801079",
         "LastName":"Бутаков",
         "FirstName":"Александр",
         "MiddleName":"Владимирович",
         "Address":"Ысык-Кульская область Ысык-Кульский район",
         "Telephone":"0999763163"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"20505195900543",
         "LastName":"Маймендиев",
         "FirstName":"Калыбек",
         "MiddleName":"Осмонкулович",
         "Address":"Таласская область г. Талас",
         "Telephone":"0773691487"
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"11605198401402",
         "LastName":"Раимбекова ",
         "FirstName":"Чолпонай",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":"0779441378"
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"10508197500079",
         "LastName":"Назарова ",
         "FirstName":"Гульнара ",
         "MiddleName":"Абдихамидовна ",
         "Address":"Джалал-Абадская область г. Джалал-Абад",
         "Telephone":"0778 176869"
      },
      {
         "OrgId":"93df61dd-a2fe-4f52-b961-f1b27bd27a8c",
         "PIN":"13110196800268",
         "LastName":"Какишова",
         "FirstName":"Чынаркул",
         "MiddleName":"Нукеевна",
         "Address":"Нарынская область г. Нарын",
         "Telephone":"0708824875"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"11705196500467",
         "LastName":"Осокина",
         "FirstName":"Галина",
         "MiddleName":"Васильевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0 555 281509"
      },
      {
         "OrgId":"30658f5a-dc22-4a7a-aaf6-63dfb7070c50",
         "PIN":"21007196900080",
         "LastName":"Тууганбаев",
         "FirstName":"Жайлоо",
         "MiddleName":"Тобокелович",
         "Address":"Чуйская область Чуйский район",
         "Telephone":"0701 009940"
      },
      {
         "OrgId":"e4964796-6658-46a9-bd49-a34b3275cb1f",
         "PIN":"22209197800846",
         "LastName":"Самиев ",
         "FirstName":"Турдумамат",
         "MiddleName":"Мыктыевич",
         "Address":"Джалал-Абадская область Ноокенский район",
         "Telephone":"0778045340"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"10311196600189",
         "LastName":"Сарымсакова",
         "FirstName":"Майрамкан",
         "MiddleName":"Карыпбаевна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0555 915573"
      },
      {
         "OrgId":"d62e0b83-e589-491d-b0a4-8bea42178231",
         "PIN":"10508198606681",
         "LastName":"Кулбатаева",
         "FirstName":"Таалайгул",
         "MiddleName":"Амиржановна",
         "Address":"Ошская область Узгенский район",
         "Telephone":"0555363631"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"10903197400079",
         "LastName":"Джунусова",
         "FirstName":"Гульмира",
         "MiddleName":"Абдубаитовна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0550780118"
      },
      {
         "OrgId":"23db562e-c0b7-4b7e-ac16-967c96500444",
         "PIN":"22701197700530",
         "LastName":"Кыдыбаев",
         "FirstName":"Нурлан",
         "MiddleName":"Бейшеналиевич",
         "Address":"Ысык-Кульская область г. Балыкчы",
         "Telephone":""
      },
      {
         "OrgId":"d328865b-ede4-4674-92c6-c134f55c3c6f",
         "PIN":"12311197700544",
         "LastName":"Маткайымова",
         "FirstName":"урматкан",
         "MiddleName":"Абдижапаровна",
         "Address":"Баткенская область г. Баткен",
         "Telephone":"0771 35 46 06"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"11308198101182",
         "LastName":"Газиева",
         "FirstName":"Зухра",
         "MiddleName":"Ярмагометовна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0554220208, 0557220209"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"21603196500681",
         "LastName":"Джумабеков",
         "FirstName":"Канатбек",
         "MiddleName":"Окенович",
         "Address":"Чуйская область Аламединский район",
         "Telephone":""
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"12904198401175",
         "LastName":"Макеева ",
         "FirstName":"Валерия ",
         "MiddleName":"Юрьевна",
         "Address":"г.Бишкек Свердловский",
         "Telephone":""
      },
      {
         "OrgId":"ab27c82d-8348-4dba-ba9c-ceae3a67704e",
         "PIN":"10708196600771",
         "LastName":"Мусуралиева ",
         "FirstName":"Гульнара",
         "MiddleName":"Шаршенбаевна",
         "Address":"Ысык-Кульская область г. Каракол",
         "Telephone":""
      },
      {
         "OrgId":"3277f73d-b02d-4f35-ad1a-80b349eecf6b",
         "PIN":"22304197801252",
         "LastName":"Молдалиев",
         "FirstName":"Серенбек",
         "MiddleName":"Толонович",
         "Address":"Ысык-Кульская область Тонский район",
         "Telephone":"0704617095"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"21603196500681",
         "LastName":"Джумабеков",
         "FirstName":"Канатбек",
         "MiddleName":"Окенович",
         "Address":"Чуйская область Аламединский район",
         "Telephone":""
      },
      {
         "OrgId":"5df10bbc-d256-40f8-b641-5ef31ce11540",
         "PIN":"12207199100196",
         "LastName":"Кравченко",
         "FirstName":"Юлия",
         "MiddleName":"Николаевна",
         "Address":"Чуйская область Панфиловский район",
         "Telephone":"0555913094"
      },
      {
         "OrgId":"30658f5a-dc22-4a7a-aaf6-63dfb7070c50",
         "PIN":"11204196700012",
         "LastName":"Эрназарова ",
         "FirstName":"Сайрагул",
         "MiddleName":"Алмазбековна",
         "Address":"Чуйская область Октябрьский",
         "Telephone":"0700 64-92-29;  0777 64-92-29"
      },
      {
         "OrgId":"b3ea3de1-5d76-461c-90ac-68d312b7b665",
         "PIN":"21610196900267",
         "LastName":"Орозобеков",
         "FirstName":"Алмазбек",
         "MiddleName":"Аалиевич",
         "Address":"Нарынская область Жумгальский район",
         "Telephone":"0771420652"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840151*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"11404198200541",
         "LastName":"Капарова ",
         "FirstName":"Динара ",
         "MiddleName":"Оморалиевна ",
         "Address":"Джалал-Абадская область Базар-Коргонский район",
         "Telephone":"0779 81-81-66"
      },
      {
         "OrgId":"c7b6aa81-a4b7-4ad6-be03-e4b54e8084da",
         "PIN":"21705198701393",
         "LastName":"Адхамов",
         "FirstName":"Шерзоджон",
         "MiddleName":"Рустамалиевич",
         "Address":"Баткенская область Лейлекский район",
         "Telephone":"0550733137"
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"23107196300318",
         "LastName":"Дербишев",
         "FirstName":"Омурбек",
         "MiddleName":"Дюшеевич",
         "Address":"Ысык-Кульская область Жети-Огузский район",
         "Telephone":""
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"21402196800876",
         "LastName":"Исмаилбеков ",
         "FirstName":"Искендер ",
         "MiddleName":"Акимович ",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0707623838"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"21303197700056",
         "LastName":"Бекмурзаев",
         "FirstName":"Омурбек",
         "MiddleName":"Мурадилович",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0705 777033"
      },
      {
         "OrgId":"3bb3cb8f-a747-4026-9031-c6b67ea9735b",
         "PIN":"20202198400465",
         "LastName":"Абалаев",
         "FirstName":"Жоомарт",
         "MiddleName":"Дөөлөталиевич",
         "Address":"Таласская область г. Талас",
         "Telephone":"0706 901655"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840122*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"11409198400588",
         "LastName":"Де",
         "FirstName":"Наталья",
         "MiddleName":"Феликсовна",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0553455699"
      },
      {
         "OrgId":"30658f5a-dc22-4a7a-aaf6-63dfb7070c50",
         "PIN":"21611198300684",
         "LastName":"Сулпиев ",
         "FirstName":"Керезбек ",
         "MiddleName":"Карыбекович",
         "Address":"Чуйская область г. Токмок",
         "Telephone":"0705 98-68-15"
      },
      {
         "OrgId":"3bb3cb8f-a747-4026-9031-c6b67ea9735b",
         "PIN":"13105197100130",
         "LastName":"Токтогулова ",
         "FirstName":"Гулжакан",
         "MiddleName":"Кенешбековна",
         "Address":"Таласская область г. Талас",
         "Telephone":"0770/489496"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"20801197200665",
         "LastName":"Омурзаков ",
         "FirstName":"Токтосун ",
         "MiddleName":"Казакбаевич ",
         "Address":"Джалал-Абадская область Базар-Коргонский район",
         "Telephone":"0706074030"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"23010199101805",
         "LastName":"Сатаркулов",
         "FirstName":"Алтынбек",
         "MiddleName":"Джаныбекович",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0707222823"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"23009197600520",
         "LastName":"Молдогурманов",
         "FirstName":"Бактыбек",
         "MiddleName":"Турдубекович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0550820728, 0556041076, 0709264646"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"21204197001114",
         "LastName":"Сейитканов",
         "FirstName":"Салайдин",
         "MiddleName":"Кадырович",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"-"
      },
      {
         "OrgId":"14eb7aef-c331-4085-a83e-0f5eebf2eaa3",
         "PIN":"12205197800633",
         "LastName":"Абыканова",
         "FirstName":"Махабат",
         "MiddleName":"Шаршенгалиевна",
         "Address":"Ысык-Кульская область Ысык-Кульский район",
         "Telephone":"0708380111"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"12911128500642",
         "LastName":"Леджебу",
         "FirstName":"Айша",
         "MiddleName":"Шамузовна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0554 886866"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"21402197200356",
         "LastName":"Патанов ",
         "FirstName":"Арстанбек",
         "MiddleName":"Кочкорбаевич ",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0550140224,0552452052"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10210198800194",
         "LastName":"Цурова",
         "FirstName":"Виктория ",
         "MiddleName":"Викторовна",
         "Telephone":"0 755 884402"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840121*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"11706198300511",
         "LastName":"Омуралиева",
         "FirstName":"Мадина",
         "MiddleName":"Акынбековна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0700 909 939"
      },
      {
         "OrgId":"a2317371-cc1d-4eb3-8adf-d7ba33b75f87",
         "PIN":"12410197400269",
         "LastName":"Ташбаева",
         "FirstName":"Татыбүбү",
         "MiddleName":"Каримжановна",
         "Address":"Ошская область Кара-Кулжинский район",
         "Telephone":"996776102474"
      },
      {
         "OrgId":"5ee1ac59-52a1-45e3-84ec-54ad09fc9f1e",
         "PIN":"11007197100485",
         "LastName":"Халилова",
         "FirstName":"Анар",
         "MiddleName":"Тологоновна",
         "Address":"Баткенская область Баткенский район",
         "Telephone":"0772156246"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"11011198800223",
         "LastName":"Исакова ",
         "FirstName":"Айсулуу",
         "MiddleName":"Жакыповна ",
         "Address":"г.Бишкек Свердловский",
         "Telephone":""
      },
      {
         "OrgId":"3277f73d-b02d-4f35-ad1a-80b349eecf6b",
         "PIN":"21212198701097",
         "LastName":"Чыймыев",
         "FirstName":"Саматбек",
         "MiddleName":"Койчуманович",
         "Address":"Ысык-Кульская область Тонский район",
         "Telephone":"0703842103"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"21509198101223",
         "LastName":"Самаркулов ",
         "FirstName":"Нурбек",
         "MiddleName":"Дуйшенбекович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0555010681"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"10704198701687",
         "LastName":"Джунусова",
         "FirstName":"Айгуль",
         "MiddleName":"Серкуловна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"+996707582533"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"10504197901061",
         "LastName":"Аширова",
         "FirstName":"Жыпаргүл",
         "MiddleName":"Эрмаматовна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0709191245"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"21611196900616",
         "LastName":"Кадыров",
         "FirstName":"Данияр",
         "MiddleName":"Шарабидинович",
         "Address":"Джалал-Абадская область Токтогульский район",
         "Telephone":"0777337632"
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"10907199201204",
         "LastName":"Мухтар кызы",
         "FirstName":"Гулноза",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":""
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"13103198200922",
         "LastName":"Мырзалиева",
         "FirstName":"Каныкей",
         "MiddleName":"Аманбековна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"076888466"
      },
      {
         "OrgId":"3277f73d-b02d-4f35-ad1a-80b349eecf6b",
         "PIN":"21705197100688",
         "LastName":"Кызматов",
         "FirstName":"Нурланбек ",
         "MiddleName":"Садыкович",
         "Address":"Ысык-Кульская область Тонский район",
         "Telephone":"0500096446"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"22104198200801",
         "LastName":"Джамалбеков",
         "FirstName":"Улан",
         "MiddleName":"Рысбекович",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0776401083"
      },
      {
         "OrgId":"64ecd259-a848-474b-8e8d-571612a7e43b",
         "PIN":"10902197000892",
         "LastName":"Оролова",
         "FirstName":"Чынара",
         "MiddleName":"Кайдулатовна",
         "Address":"Джалал-Абадская область Токтогульский район",
         "Telephone":"0707 196769"
      },
      {
         "OrgId":"8b6510f7-3b6b-4c9d-b8a1-2349b78b2cf3",
         "PIN":"11811199201134",
         "LastName":"Максутбаева",
         "FirstName":"Дильбархан",
         "MiddleName":"Анваровна",
         "Address":"Чуйская область г. Токмок",
         "Telephone":"0707179024"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"11210196600353",
         "LastName":"Шералиева ",
         "FirstName":"Зайнагүл",
         "MiddleName":"Казакпаевна",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0703155885"
      },
      {
         "OrgId":"d328865b-ede4-4674-92c6-c134f55c3c6f",
         "PIN":"12311197700544",
         "LastName":"Маткайымова",
         "FirstName":"урматкан",
         "MiddleName":"Абдижапаровна",
         "Address":"Баткенская область г. Баткен",
         "Telephone":"0771 35-46-05"
      },
      {
         "OrgId":"e4964796-6658-46a9-bd49-a34b3275cb1f",
         "PIN":"21107197400106",
         "LastName":"Мейманкулов",
         "FirstName":"Султанбек",
         "MiddleName":"Абаралиевич",
         "Address":"Джалал-Абадская область Ноокенский район",
         "Telephone":"0557030476"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"22511199200038",
         "LastName":"Потехин",
         "FirstName":"Денис",
         "MiddleName":"Олегович",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0555 559910\r\n0553 399939"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"1070419850114*",
         "LastName":"ЮНУСАЛИЕВА",
         "FirstName":"АНАСТАСИЯ",
         "MiddleName":"ОЛЕГОВНА",
         "Address":"г.Бишкек Чуйский район",
         "Telephone":""
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"10101197501444",
         "LastName":"Мирзаева ",
         "FirstName":"Бурулча",
         "MiddleName":"Бекболотовна ",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":"0777 77 71 52"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"11110196700646",
         "LastName":"Жакыпбаева ",
         "FirstName":"Айнажан",
         "MiddleName":"Социаловна",
         "Address":"Чуйская область Свердловский",
         "Telephone":"0708015043"
      },
      {
         "OrgId":"3bb3cb8f-a747-4026-9031-c6b67ea9735b",
         "PIN":"11305198200074",
         "LastName":"Малабекова ",
         "FirstName":"Гульмира",
         "MiddleName":"Джеенбековна",
         "Address":"Таласская область г. Талас",
         "Telephone":"0701331154"
      },
      {
         "OrgId":"60f22545-cb84-4795-81a3-2eeeeb0fb88a",
         "PIN":"21209197200909",
         "LastName":"НАРБЕКОВ",
         "FirstName":"НУРИДИЛЛА",
         "MiddleName":"ЖЭЭНБЕКОВИЧ",
         "Address":"Джалал-Абадская область Ала-Букинский район",
         "Telephone":"+996773674944"
      },
      {
         "OrgId":"14eb7aef-c331-4085-a83e-0f5eebf2eaa3",
         "PIN":"10706196900765",
         "LastName":"Усупова",
         "FirstName":"Бурул",
         "MiddleName":"Кадыевна",
         "Address":"Ысык-Кульская область Ысык-Кульский район",
         "Telephone":""
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"1211197300148",
         "LastName":"Тельтаева",
         "FirstName":"Асель",
         "MiddleName":"Эриковна",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0700626972"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"10912196700467",
         "LastName":"Флюгель",
         "FirstName":"Эльмира ",
         "MiddleName":"Калдарбековна",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0556266938"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"21007198601714",
         "LastName":"Мамакеев",
         "FirstName":"Усен",
         "MiddleName":"Куванычбекович",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0555 87 78 06\r\n0555 07 05 04"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"10812196500873",
         "LastName":"Джумабаева",
         "FirstName":"Назира",
         "MiddleName":"Барпиевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0700020640\r\n0550073570"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"10402199101110",
         "LastName":"Заитова",
         "FirstName":"Захидэм",
         "MiddleName":"Абдушухритовна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0557221031, 0700793525"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"11603198010016",
         "LastName":"Акимова",
         "FirstName":"Бермет",
         "MiddleName":"Куванычбековна",
         "Address":"Чуйская область Аламединский район",
         "Telephone":""
      },
      {
         "OrgId":"e5411cdd-3caf-4798-a7bf-fd57c856c82b",
         "PIN":"11508198200198",
         "LastName":"Ысмайылова ",
         "FirstName":"Замира",
         "MiddleName":"Кимсанбаевна",
         "Address":"Ошская область Алайский район",
         "Telephone":"0555417911"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"22202198300055",
         "LastName":"Тукушов",
         "FirstName":"Алайбек",
         "MiddleName":"Бактыкулович",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0773 198787\r\n0556 150612"
      },
      {
         "OrgId":"30658f5a-dc22-4a7a-aaf6-63dfb7070c50",
         "PIN":"11403197700438",
         "LastName":"Найдовская",
         "FirstName":"Инна ",
         "MiddleName":"Борисовна ",
         "Address":"Чуйская область Свердловский",
         "Telephone":"0550 23-23-12"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"21608198701244",
         "LastName":"Литвиненко",
         "FirstName":"Александр",
         "MiddleName":"Владимирович",
         "Address":"Чуйская область Свердловский",
         "Telephone":"0773661438"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"11706196000132",
         "LastName":"Савочкина",
         "FirstName":"Вера",
         "MiddleName":"Владимировна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0550188735"
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"13101197800962",
         "LastName":"Салиева ",
         "FirstName":"Гульбарчын",
         "MiddleName":"Пазыловна ",
         "Address":"Джалал-Абадская область Сузакский район",
         "Telephone":"0771 50-56-26,\r\n0773 83-86-98"
      },
      {
         "OrgId":"668d6957-1f76-49de-b9ad-0f2c635df2dd",
         "PIN":"20903197600897",
         "LastName":"Абдрахманов",
         "FirstName":"Мелис ",
         "MiddleName":"Усупбекович ",
         "Address":"Чуйская область Кеминский район",
         "Telephone":"+996500997653"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"12103198001115",
         "LastName":"Красоцкая",
         "FirstName":"Юлия",
         "MiddleName":"Владимировна",
         "Address":"Чуйская область Свердловский",
         "Telephone":"0701400003"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"20202198401048",
         "LastName":"Кайназаров",
         "FirstName":"Замирбек",
         "MiddleName":"Джолдошбекович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0700637384"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"11505197600443",
         "LastName":"Батыркулова",
         "FirstName":"Жылдыз",
         "MiddleName":"Торокулова",
         "Telephone":"0700387245"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"22602197600510",
         "LastName":"Бектурсунов",
         "FirstName":"Канатбек",
         "MiddleName":"Исаевич",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0709020182"
      },
      {
         "OrgId":"2765827a-88ef-4144-8050-9a2fd66fb136",
         "PIN":"10906197200711",
         "LastName":"Токтосунова ",
         "FirstName":"Арзыкан",
         "MiddleName":"Жоробаевна",
         "Address":"Джалал-Абадская область Аксыйский район",
         "Telephone":"0772563475"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"21002198801476",
         "LastName":"Сабиров",
         "FirstName":"Надыржан",
         "MiddleName":"Ярмухамедович",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0551101792"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"21905198100869",
         "LastName":"Нигматов",
         "FirstName":"Эрмек",
         "MiddleName":"Абдулазисович",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0771406593"
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"12308196900274",
         "LastName":"Жумалиева ",
         "FirstName":"Нуржамал",
         "MiddleName":"Арстанбековна",
         "Address":"Джалал-Абадская область г. Джалал-Абад",
         "Telephone":"0773 56-36-20"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"11712198300606",
         "LastName":"Лукина",
         "FirstName":"Оксана",
         "MiddleName":"Александровна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0558 486374"
      },
      {
         "OrgId":"c8641d03-b553-4f95-adfb-ec002fd64e06",
         "PIN":"21208198301478",
         "LastName":"Савенко",
         "FirstName":"Николай",
         "MiddleName":"Сергеевич",
         "Address":"Чуйская область Московский район",
         "Telephone":"0554 5554 223"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"12003197200374",
         "LastName":"Сарыева",
         "FirstName":"Жаннат",
         "MiddleName":"Аманбаевна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0702704402\r\n0772169113"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"1211197300148",
         "LastName":"Тельтаева",
         "FirstName":"Асель",
         "MiddleName":"Эриковна",
         "Telephone":"0700626972"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"12102196600051",
         "LastName":"Омуралиева",
         "FirstName":"Гульнара ",
         "MiddleName":"Турарбековна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0 550 210266"
      },
      {
         "OrgId":"c6d92271-6ff2-4641-a83f-d02610060c43",
         "PIN":"21911197900105",
         "LastName":"Маткеримов ",
         "FirstName":"Улан ",
         "MiddleName":"Сатыбалдиевич ",
         "Address":"Таласская область Кара-Бууринский район",
         "Telephone":"0708722912"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"22105196200129",
         "LastName":"Джелденов",
         "FirstName":"Канатбек",
         "MiddleName":"Асешович",
         "Address":"Ысык-Кульская область г. Каракол",
         "Telephone":"0555270013"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"13010196200039",
         "LastName":"Насирова",
         "FirstName":"Валя",
         "MiddleName":"Маликовна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0 550 08 32 69"
      },
      {
         "OrgId":"e4964796-6658-46a9-bd49-a34b3275cb1f",
         "PIN":"21809197600630",
         "LastName":"Айтбаев",
         "FirstName":"Бектур",
         "MiddleName":"Саякбаевич",
         "Address":"Джалал-Абадская область Ноокенский район",
         "Telephone":""
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"22104197901191",
         "LastName":"Азаматов ",
         "FirstName":"Айдар",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0703820869"
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"11401198901015",
         "LastName":"Исманова",
         "FirstName":"Нурия ",
         "MiddleName":"Исмаилжановна ",
         "Address":"Джалал-Абадская область г. Джалал-Абад",
         "Telephone":""
      },
      {
         "OrgId":"9d8b0ca0-d618-4157-af15-5e7cca7d9f23",
         "PIN":"10309197600812",
         "LastName":"Таштанбекова",
         "FirstName":"Кумаркан",
         "MiddleName":"Абасбековна",
         "Address":"Джалал-Абадская область г. Таш-Комур",
         "Telephone":"0222-61-09-73"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"21611197000613",
         "LastName":"Бектурсунов",
         "FirstName":"Нурбек",
         "MiddleName":"Дуйшенович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0556224557"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"21907197500559",
         "LastName":"Осконов",
         "FirstName":"Бактияр",
         "MiddleName":"Айтмаматович",
         "Telephone":"0505549262, 0703187175"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"11406196700078",
         "LastName":"Омуралиева",
         "FirstName":"Райкан",
         "MiddleName":"Айтыбаевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0550100989"
      },
      {
         "OrgId":"b0a96501-f5f4-48a4-8103-6c7a7ba290c4",
         "PIN":"22109197301122",
         "LastName":"Жолдошов ",
         "FirstName":"Союзбек",
         "MiddleName":"Эргешович",
         "Address":"Джалал-Абадская область Тогуз-Тороуский район",
         "Telephone":"0703696913"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"12703196701062",
         "LastName":"Ярославцева",
         "FirstName":"Наталия ",
         "MiddleName":"Александровна ",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0550136675"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"20708198501387",
         "LastName":"Арсымканов",
         "FirstName":"Рустам",
         "MiddleName":"Арсымканович",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0701 77 11  88"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"10905196900604",
         "LastName":"Алжакеева",
         "FirstName":"Женишкан",
         "MiddleName":"Элибаевна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0705170470"
      },
      {
         "OrgId":"14eb7aef-c331-4085-a83e-0f5eebf2eaa3",
         "PIN":"10103197350001",
         "LastName":"Исмаилова",
         "FirstName":"Майраикул",
         "MiddleName":"Садыковна",
         "Address":"Ысык-Кульская область Ысык-Кульский район",
         "Telephone":"0703251191"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"10105197200210",
         "LastName":"Адашпасова",
         "FirstName":"Асель",
         "MiddleName":"Зулкаровна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0501146347"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"11712198000119",
         "LastName":"Анбаза",
         "FirstName":"Рукиян",
         "MiddleName":"Макамеровна",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0704174036"
      },
      {
         "OrgId":"e425497a-3c82-424e-9691-71eaad4759d8",
         "PIN":"10208196600503",
         "LastName":"Борубаева",
         "FirstName":"Эльмира ",
         "MiddleName":"Абдылдаевна",
         "Address":"Ошская область Узгенский район",
         "Telephone":"0772140592\r\n0507141097"
      },
      {
         "OrgId":"ab27c82d-8348-4dba-ba9c-ceae3a67704e",
         "PIN":"21407197600249",
         "LastName":"Ачикеев ",
         "FirstName":"Жолдошбек",
         "MiddleName":"Ишенбаевич",
         "Address":"Ысык-Кульская область г. Каракол",
         "Telephone":""
      },
      {
         "OrgId":"ab27c82d-8348-4dba-ba9c-ceae3a67704e",
         "PIN":"20101198004214",
         "LastName":"Уралиев ",
         "FirstName":"Омор",
         "MiddleName":"Айтбекович ",
         "Address":"Ысык-Кульская область г. Каракол",
         "Telephone":"0 700 649 411"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"12306198600732",
         "LastName":"Бостонова",
         "FirstName":"Бермет",
         "MiddleName":"Усоналиевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0703220580"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"20304198600564",
         "LastName":"Журсунов",
         "FirstName":"Мирбек",
         "MiddleName":"Кармышевич",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0559646520, 0771055030"
      },
      {
         "OrgId":"14eb7aef-c331-4085-a83e-0f5eebf2eaa3",
         "PIN":"10209197600530",
         "LastName":"Иммангазы кызы",
         "FirstName":"назгул",
         "Address":"Ысык-Кульская область Ысык-Кульский район",
         "Telephone":"0555177527"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"12912199000732",
         "LastName":"Ыса",
         "FirstName":"кызы",
         "MiddleName":"Мээрим",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0774 400 852"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"22107198100706",
         "LastName":"Ибраимов ",
         "FirstName":"Максатбек",
         "MiddleName":"Азимканович",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0705282723"
      },
      {
         "OrgId":"b0a96501-f5f4-48a4-8103-6c7a7ba290c4",
         "PIN":"10412197200066",
         "LastName":"Сатыбалдиева",
         "FirstName":"Жылдыз",
         "MiddleName":"Токтоболотовна",
         "Address":"Джалал-Абадская область Тогуз-Тороуский район",
         "Telephone":"0770200493"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"21509197500609",
         "LastName":"Сейитбеков ",
         "FirstName":"Таалайбек",
         "MiddleName":"Жанышбекович",
         "Address":"Нарынская область Кочкорский район",
         "Telephone":"0700642777"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"11104197300746",
         "LastName":"Сабыралиева ",
         "FirstName":"Айгуль ",
         "MiddleName":"Болотовна ",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0558789478, 0709204890, 676826"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"12705198000486",
         "LastName":"Гафурова ",
         "FirstName":"Эльвира",
         "MiddleName":"Ильдаровна",
         "Address":"Чуйская область Свердловский",
         "Telephone":"0701291305"
      },
      {
         "OrgId":"b3ea3de1-5d76-461c-90ac-68d312b7b665",
         "PIN":"20311196900785",
         "LastName":"Киркиев ",
         "FirstName":"Майрамбек ",
         "MiddleName":"Омурович",
         "Address":"Нарынская область Жумгальский район",
         "Telephone":"0704313241"
      },
      {
         "OrgId":"8b6510f7-3b6b-4c9d-b8a1-2349b78b2cf3",
         "PIN":"22302198802590",
         "LastName":"Аманбаев",
         "FirstName":"Тимур",
         "MiddleName":"Болотбекович",
         "Address":"Чуйская область Чуйский район",
         "Telephone":"0702777761"
      },
      {
         "OrgId":"30658f5a-dc22-4a7a-aaf6-63dfb7070c50",
         "PIN":"22005198701121",
         "LastName":"Агзамов",
         "FirstName":"Ренат",
         "MiddleName":"Алмазбекович",
         "Address":"Чуйская область Чуйский район",
         "Telephone":"0551 56-65-59;   0501 55-65-59"
      },
      {
         "OrgId":"32af3ea3-4100-406f-8825-20cb8a1877aa",
         "PIN":"12909197601139",
         "LastName":"Исманова",
         "FirstName":"Калия",
         "MiddleName":"Абдыганиевна",
         "Address":"Баткенская область г. Кызыл-Кия",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"10305198500041",
         "LastName":"Цикина",
         "FirstName":"Марина",
         "MiddleName":"Владимировна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0312-45-09-53,0772-93-17-03"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"22411197100044",
         "LastName":"Карабаев ",
         "FirstName":"Нурлан",
         "MiddleName":"Джанузакович",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0550880338"
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"11804197200901",
         "LastName":"Абылова",
         "FirstName":"Чолпон ",
         "MiddleName":"Эрмековна",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":"0771481124"
      },
      {
         "OrgId":"30658f5a-dc22-4a7a-aaf6-63dfb7070c50",
         "PIN":"21611198300684",
         "LastName":"Сулпиев ",
         "FirstName":"Керезбек",
         "MiddleName":"Карыбекович",
         "Telephone":"0705986815"
      },
      {
         "OrgId":"e5411cdd-3caf-4798-a7bf-fd57c856c82b",
         "PIN":"12401197400832",
         "LastName":"Шадиева",
         "FirstName":"Зульхумар",
         "MiddleName":"Акуновна",
         "Telephone":""
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"12109197500015",
         "LastName":"Хегай",
         "FirstName":"Ирина",
         "MiddleName":"Владимировна",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0775970969, 0775988887"
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"11203198700085",
         "LastName":"Базарбаева ",
         "FirstName":"Нуриза ",
         "MiddleName":"Байышовна ",
         "Telephone":"0771 68-64-77,\r\n0778 98-82-07"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840153*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"10105198000603",
         "LastName":"Култаева ",
         "FirstName":"Жазгул",
         "MiddleName":"Балбаевна",
         "Address":"Джалал-Абадская область г. Джалал-Абад",
         "Telephone":"0701 76-63-76"
      },
      {
         "OrgId":"c6d92271-6ff2-4641-a83f-d02610060c43",
         "PIN":"12111198801391",
         "LastName":"Даирова",
         "FirstName":"Элнура",
         "MiddleName":"Мадырайымовна",
         "Address":"Таласская область Кара-Бууринский район",
         "Telephone":"0709068425"
      },
      {
         "OrgId":"c7b6aa81-a4b7-4ad6-be03-e4b54e8084da",
         "PIN":"12303197701336",
         "LastName":"Жаныбекова",
         "FirstName":"Лола",
         "MiddleName":"Эгембердиевна",
         "Telephone":"0779605056"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840122*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"10403198100293",
         "LastName":"Кузина",
         "FirstName":"Ирина",
         "MiddleName":"Вилльевна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0700347757"
      },
      {
         "OrgId":"c8641d03-b553-4f95-adfb-ec002fd64e06",
         "PIN":"22009198500932",
         "LastName":"Абдырайымов",
         "FirstName":"Кайып",
         "MiddleName":"Асылбекович",
         "Address":"Чуйская область Московский район",
         "Telephone":" 5-33-98"
      },
      {
         "OrgId":"8b739bfd-cf8f-4380-a246-8a34bce9a231",
         "PIN":"22106197800030",
         "LastName":"Мажиев ",
         "FirstName":"Эмилбек",
         "MiddleName":"Абдраимович",
         "Telephone":"0776430134"
      },
      {
         "OrgId":"30658f5a-dc22-4a7a-aaf6-63dfb7070c50",
         "PIN":"22705196500636",
         "LastName":"Увалиев ",
         "FirstName":"Кубатбек ",
         "MiddleName":"Желденович",
         "Address":"Чуйская область Чуйский район",
         "Telephone":"0551 44-77-06"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"20205197701288",
         "LastName":"Абиев",
         "FirstName":"Эсен",
         "MiddleName":"Акматбекович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"11204196700012",
         "LastName":"Эрназарова",
         "FirstName":"Сайрагуль",
         "MiddleName":"Алмазбековна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0700649229"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"11401197801162",
         "LastName":"Котиняткина",
         "FirstName":"Ирина",
         "MiddleName":"Николаевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0 555 90 24 30"
      },
      {
         "OrgId":"c7b6aa81-a4b7-4ad6-be03-e4b54e8084da",
         "PIN":"22712198701120",
         "LastName":"Фахридинов",
         "FirstName":"Акылжан",
         "MiddleName":"Тажибаевич",
         "Address":"Баткенская область Лейлекский район",
         "Telephone":"0771426231"
      },
      {
         "OrgId":"30658f5a-dc22-4a7a-aaf6-63dfb7070c50",
         "PIN":"20408196400806",
         "LastName":"Пазилов ",
         "FirstName":"Анарбек ",
         "MiddleName":"Шайлообекович ",
         "Address":"Чуйская область Чуйский район",
         "Telephone":"0709 05-61-36"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"10109197600134",
         "LastName":"Айтбаева",
         "FirstName":"Айнура",
         "MiddleName":"Джумабековна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0550052623"
      },
      {
         "OrgId":"2765827a-88ef-4144-8050-9a2fd66fb136",
         "PIN":"11512197800111",
         "LastName":"Юсупбаева",
         "FirstName":"Гульчехра",
         "MiddleName":"Джороевна",
         "Address":"Джалал-Абадская область Аксыйский район",
         "Telephone":"0770658086"
      },
      {
         "OrgId":"51c381f2-6ad6-4382-81f9-3d36e9abea08",
         "PIN":"20710199000052",
         "LastName":"Акылбек уулу",
         "FirstName":"Тилек",
         "Address":"Нарынская область Кочкорский район",
         "Telephone":"0500171315"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"11210196700024",
         "LastName":"Ниязалиева ",
         "FirstName":"Гулкайыр",
         "MiddleName":"Шукурбековна",
         "Telephone":"0774995159"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"21602199001033",
         "LastName":"Тынчылык уулу",
         "FirstName":"Эрнист",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0706042018"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"10104198100390",
         "LastName":"Рысбаева",
         "FirstName":"Бермет",
         "MiddleName":"Анаркуловна",
         "Telephone":"0555450582"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"20210198400547",
         "LastName":"Токталиев",
         "FirstName":"Жаркынбек",
         "MiddleName":"Эсенгулович",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0557 841002, 0708 977991"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"11805197300080",
         "LastName":"Нургазиева",
         "FirstName":"Махабат",
         "MiddleName":"Калыбаевна",
         "Telephone":"0708438201"
      },
      {
         "OrgId":"3277f73d-b02d-4f35-ad1a-80b349eecf6b",
         "PIN":"21107198601285",
         "LastName":"Толоев",
         "FirstName":"Мирлан",
         "MiddleName":"Жолочиевич",
         "Address":"Ысык-Кульская область Тонский район",
         "Telephone":""
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"11004197700872",
         "LastName":"Нурумбетова ",
         "FirstName":"Тартипкан",
         "MiddleName":"Касмаматовна ",
         "Address":"Джалал-Абадская область Сузакский район",
         "Telephone":""
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"12901196100690",
         "LastName":"Абылгазиева",
         "FirstName":"Алтынгул",
         "MiddleName":"Джумалиевна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0707501327"
      },
      {
         "OrgId":"14eb7aef-c331-4085-a83e-0f5eebf2eaa3",
         "PIN":"10803197400661",
         "LastName":"Молдогазиева",
         "FirstName":"Назира",
         "MiddleName":"Дюшебаевна",
         "Address":"Ысык-Кульская область Ысык-Кульский район",
         "Telephone":"0707792345"
      },
      {
         "OrgId":"32af3ea3-4100-406f-8825-20cb8a1877aa",
         "PIN":"12811198200228",
         "LastName":"Карабаева",
         "FirstName":"Хилола",
         "MiddleName":"Хужаахмадовна",
         "Address":"Баткенская область г. Кызыл-Кия",
         "Telephone":"0755373631"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"12801196100233",
         "LastName":"Исраилова",
         "FirstName":"Гулмайрам",
         "MiddleName":"Бакашовна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0709889553"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"20807198401237",
         "LastName":"Сазыкбаев ",
         "FirstName":"Байыш",
         "MiddleName":"Раимжанович",
         "Address":"Джалал-Абадская область Аксыйский район",
         "Telephone":"0705165684"
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"22501196200740",
         "LastName":"Тотубаев",
         "FirstName":"Кубатбек ",
         "MiddleName":"Толомушович",
         "Address":"Ысык-Кульская область Жети-Огузский район",
         "Telephone":"0556159666"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"20801198000326",
         "LastName":"Мамиев",
         "FirstName":"Нурланбек",
         "MiddleName":"Курманбекович",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0770 68 60 89"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"11311196200722",
         "LastName":"Сатиева ",
         "FirstName":"Нурила",
         "MiddleName":"Абдыкалыковна",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"38-53-41; 0702705214"
      },
      {
         "OrgId":"51c381f2-6ad6-4382-81f9-3d36e9abea08",
         "PIN":"22412198101451",
         "LastName":"Дүйшөнакун уулу",
         "FirstName":"Нурлан",
         "Address":"Нарынская область Кочкорский район",
         "Telephone":"0709995913"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"20107198201558",
         "LastName":"Ашимов",
         "FirstName":"Данияр",
         "MiddleName":"Дурусбекович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0700 112122, 0700 523031"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"11609198001025",
         "LastName":"Рахматжанова",
         "FirstName":"Сайрагуль",
         "MiddleName":"Абдумиталиповна",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0708252052"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840126*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"e4964796-6658-46a9-bd49-a34b3275cb1f",
         "PIN":"20309198300222",
         "LastName":"Базарбаев",
         "FirstName":"Курсанали",
         "MiddleName":"Кимсанбаевич",
         "Address":"Джалал-Абадская область Ноокенский район",
         "Telephone":"0702074460,0777490040"
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"10807197200750",
         "LastName":"Белекова ",
         "FirstName":"Динара ",
         "MiddleName":"Усенова ",
         "Address":"Ысык-Кульская область Жети-Огузский район",
         "Telephone":"0556197289"
      },
      {
         "OrgId":"a3282ffc-bafe-4b4e-99d0-c68509be6133",
         "PIN":"22505198001288",
         "LastName":"Хасанов",
         "FirstName":"Хамидилло",
         "MiddleName":"Турсунбаевич",
         "Address":"г. Ош Ноокатский район",
         "Telephone":""
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"11207198200627",
         "LastName":"Турсунова",
         "FirstName":"Наргиза",
         "MiddleName":"Таабалдыевна",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0557779298"
      },
      {
         "OrgId":"c7b6aa81-a4b7-4ad6-be03-e4b54e8084da",
         "PIN":"12104198501069",
         "LastName":"Якубжанова",
         "FirstName":"Нигина",
         "MiddleName":"Исроиловна",
         "Address":"Баткенская область Лейлекский район",
         "Telephone":"0776354387, 0772717083"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840124*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"10411197500458",
         "LastName":"Эшмурзаева",
         "FirstName":"Нургул",
         "MiddleName":"Пайзилдаевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0707323990"
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"12310196800717",
         "LastName":"Мамытканова",
         "FirstName":"Назира",
         "MiddleName":"Асангазиевна",
         "Address":"Ысык-Кульская область Жети-Огузский район",
         "Telephone":"0709322085"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"22702198701734",
         "LastName":"Бейшекеев ",
         "FirstName":"Бекказы ",
         "MiddleName":"Азизович ",
         "Address":"Чуйская область Жайылский район",
         "Telephone":""
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"12311196900239",
         "LastName":"Сыдыкова ",
         "FirstName":"Гулмира ",
         "MiddleName":"Ырыскуловна",
         "Address":"Чуйская область Свердловский",
         "Telephone":""
      },
      {
         "OrgId":"3bb3cb8f-a747-4026-9031-c6b67ea9735b",
         "PIN":"20505195900543",
         "LastName":"Маймендиев",
         "FirstName":"Калыбек",
         "MiddleName":"Осмонкулович",
         "Address":"Таласская область г. Талас",
         "Telephone":""
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"21205198900651",
         "LastName":"Омаров",
         "FirstName":"Асхат",
         "MiddleName":"Анварбекович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0555110415,\r\n0559106765"
      },
      {
         "OrgId":"aa65c2f7-3091-40e5-939d-6a18250e125a",
         "PIN":"13003197700049",
         "LastName":"Кадырова ",
         "FirstName":"Нургүл",
         "MiddleName":"Темирбековна",
         "Address":"Джалал-Абадская область г. Кара-Куль",
         "Telephone":"0777 505845"
      },
      {
         "OrgId":"3277f73d-b02d-4f35-ad1a-80b349eecf6b",
         "PIN":"20412198000263",
         "LastName":"Деркенбаев ",
         "FirstName":"Мирлан ",
         "MiddleName":"Джаныбекович",
         "Address":"Ысык-Кульская область Тонский район",
         "Telephone":"0703222182"
      },
      {
         "OrgId":"9afa2870-4609-4d8a-8417-11c2dbdec94b",
         "PIN":"11904197200107",
         "LastName":"Эреджепова ",
         "FirstName":"Светлана",
         "MiddleName":"Алиевна",
         "Address":"Джалал-Абадская область г. Майлуу-Суу",
         "Telephone":"0555110969"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"20712198801388",
         "LastName":"Полянский",
         "FirstName":"Василий",
         "MiddleName":"Анатольевич",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0555766656,0556457566"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"22910196900522",
         "LastName":"Жумуков ",
         "FirstName":"Темиркул",
         "MiddleName":"Камчыбекович",
         "Address":"Нарынская область Жумгальский район",
         "Telephone":"0701 774409, 0501221173"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"22209197700784",
         "LastName":"Качиев",
         "FirstName":"Эрлан",
         "MiddleName":"Саткынович",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0778691601"
      },
      {
         "OrgId":"17f5601a-36b7-4fac-a980-07f349239d5b",
         "PIN":"11101197800464",
         "LastName":"Сакабаева ",
         "FirstName":"Мээркан ",
         "MiddleName":"Таиповна",
         "Address":"Джалал-Абадская область Базар-Коргонский район",
         "Telephone":"0777 809-926"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"21111198500776",
         "LastName":"Исаев ",
         "FirstName":"Арфидин",
         "MiddleName":"Туратбекович",
         "Address":"Чуйская область Свердловский",
         "Telephone":""
      },
      {
         "OrgId":"a2317371-cc1d-4eb3-8adf-d7ba33b75f87",
         "PIN":"22408197301074",
         "LastName":"Искендеров",
         "FirstName":"Кубанычбек",
         "MiddleName":"Асилбекович",
         "Address":"Ошская область Кара-Кулжинский район",
         "Telephone":"0559730873"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"22409196400525",
         "LastName":"Сатаров",
         "FirstName":"Айбек",
         "MiddleName":"Рыскулович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0700454531, 0700991429"
      },
      {
         "OrgId":"93df61dd-a2fe-4f52-b961-f1b27bd27a8c",
         "PIN":"22008197700735",
         "LastName":"Токтоназаров",
         "FirstName":"Арзымат",
         "MiddleName":"Турдубаевич",
         "Address":"Нарынская область г. Нарын",
         "Telephone":"0705317870"
      },
      {
         "OrgId":"60f22545-cb84-4795-81a3-2eeeeb0fb88a",
         "PIN":"21001198101526",
         "LastName":"АБДУЛАЖАНОВ",
         "FirstName":"ХАМИДУЛЛО",
         "MiddleName":"ИБАДИЛАЖАНОВИЧ",
         "Address":"Джалал-Абадская область Ала-Букинский район",
         "Telephone":"+996 556771741 "
      },
      {
         "OrgId":"14eb7aef-c331-4085-a83e-0f5eebf2eaa3",
         "PIN":"10611198100220",
         "LastName":"Доолотбакова",
         "FirstName":"Индира",
         "MiddleName":"Мухтаровна",
         "Address":"Ысык-Кульская область Ысык-Кульский район",
         "Telephone":"0702123910"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"20402196900014",
         "LastName":"Карымбаев",
         "FirstName":"Кенен",
         "MiddleName":"Сейиткулович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0705467208"
      },
      {
         "OrgId":"3bb3cb8f-a747-4026-9031-c6b67ea9735b",
         "PIN":"22001198500746",
         "LastName":"Сыдыгалиева",
         "FirstName":"Талгат",
         "MiddleName":"Мамбетович",
         "Address":"Таласская область Таласский район",
         "Telephone":"0703823383"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"10502196900320",
         "LastName":"Бердибекова",
         "FirstName":"Космира",
         "MiddleName":"Нурпашовна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0771494089"
      },
      {
         "OrgId":"1f892b98-97b4-4b58-b61f-7dfecbac3b5e",
         "PIN":"10105197201384",
         "LastName":"Джакыпова ",
         "FirstName":"Асель ",
         "MiddleName":"Абдыкасымовна ",
         "Address":"Ысык-Кульская область Ак-Суйский район",
         "Telephone":"0778 782959\r\n0702 845188"
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"12708198400279",
         "LastName":"Азимбекова ",
         "FirstName":"Айгул",
         "MiddleName":"Темирбековна",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":""
      },
      {
         "OrgId":"5ee1ac59-52a1-45e3-84ec-54ad09fc9f1e",
         "PIN":"10308198600208",
         "LastName":"Гафурова",
         "FirstName":"Гулинса",
         "MiddleName":"Зайниевна",
         "Address":"Баткенская область Баткенский район",
         "Telephone":"0771 87 40 16\r\n0778 01 76 04"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"21611196900616",
         "LastName":"Кадыров",
         "FirstName":"Данияр",
         "MiddleName":"Шарабидинович",
         "Telephone":"0771 233587\r\n0707233681"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"10312197100307",
         "LastName":"Кожоналиева",
         "FirstName":"Айнура",
         "MiddleName":"Кыдырбековна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0702241848\r\n0701400623"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"11702197510018",
         "LastName":"Сыдыкова ",
         "FirstName":"Миргүл",
         "MiddleName":"Туратбековна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0700536613"
      },
      {
         "OrgId":"c7b6aa81-a4b7-4ad6-be03-e4b54e8084da",
         "PIN":"12701198601730",
         "LastName":"Ходиева",
         "FirstName":"Тухтажон",
         "MiddleName":"Холмирзоевна",
         "Address":"Баткенская область г. Сулюкта",
         "Telephone":"0771588610"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"23012197401727",
         "LastName":"Омурзаков",
         "FirstName":"Жаныбек",
         "MiddleName":"Ашымович",
         "Address":"Таласская область г. Талас",
         "Telephone":"0700533619"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840120*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"e4964796-6658-46a9-bd49-a34b3275cb1f",
         "PIN":"20507197600909",
         "LastName":"Паязов",
         "FirstName":"Мейкинбек",
         "MiddleName":"Нурмагаметович",
         "Address":"Джалал-Абадская область Ноокенский район",
         "Telephone":""
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"12510197100279",
         "LastName":"Карабалаева",
         "FirstName":"Гульниса",
         "MiddleName":"Анарбаевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0770 25 93 39"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"21209198301144",
         "LastName":"Каширин ",
         "FirstName":"Евгений ",
         "MiddleName":"Александрович",
         "Address":"Чуйская область Кеминский район",
         "Telephone":"0550923700"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"21511198000905",
         "LastName":"Рысбаев",
         "FirstName":"Талантбек",
         "MiddleName":"Урайимович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0505 771980, 0507 771980"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"12011019790035",
         "LastName":"Сутормин",
         "FirstName":"Георгий",
         "MiddleName":"Юрьевич",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0772618378"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"12609198000230",
         "LastName":"Турсуналиева ",
         "FirstName":"Эльмира",
         "MiddleName":"Кубанычбековна",
         "Address":"г.Бишкек Свердловский",
         "Telephone":""
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"22204198500518",
         "LastName":"Давладов",
         "FirstName":"Владимир",
         "MiddleName":"Викторович",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0551 240999\r\n0551 240991"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"2051219750037",
         "LastName":"Мамбетов",
         "FirstName":"Нурлан",
         "MiddleName":"Орозобекович",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0707969977"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"12801197800530",
         "LastName":"Джунушева",
         "FirstName":"Айнура",
         "MiddleName":"Омурбековна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0708483798"
      },
      {
         "OrgId":"3bb3cb8f-a747-4026-9031-c6b67ea9735b",
         "PIN":"10107197801461",
         "LastName":"Жузупбеков",
         "FirstName":"Махабат",
         "MiddleName":"Каналбековна",
         "Address":"Таласская область г. Талас",
         "Telephone":"0702290205"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"12311196900239",
         "LastName":"Сыдыкова",
         "FirstName":"Гулмира",
         "MiddleName":"Ырыскуловна",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0777018697"
      },
      {
         "OrgId":"aa65c2f7-3091-40e5-939d-6a18250e125a",
         "PIN":"22209198600572",
         "LastName":"Калбаев",
         "FirstName":"Тынычтык",
         "MiddleName":"Конурбаевич",
         "Address":"Джалал-Абадская область г. Кара-Куль",
         "Telephone":"0706161944"
      },
      {
         "OrgId":"a2317371-cc1d-4eb3-8adf-d7ba33b75f87",
         "PIN":"11604198501351",
         "LastName":"Турдуева ",
         "FirstName":"Аида",
         "MiddleName":"Абдумомуновна",
         "Telephone":""
      },
      {
         "OrgId":"d62e0b83-e589-491d-b0a4-8bea42178231",
         "PIN":"20804198100619",
         "LastName":"Сабиров",
         "FirstName":"Улукбек",
         "MiddleName":"Муратжанович",
         "Address":"Ошская область Узгенский район",
         "Telephone":"0552212547"
      },
      {
         "OrgId":"c7b6aa81-a4b7-4ad6-be03-e4b54e8084da",
         "PIN":"21707197700726",
         "LastName":"Бойбабаев ",
         "FirstName":"Абдужамил",
         "MiddleName":"Абдумуминович",
         "Address":"Баткенская область Лейлекский район",
         "Telephone":"0776262023"
      },
      {
         "OrgId":"d328865b-ede4-4674-92c6-c134f55c3c6f",
         "PIN":"11710198201133",
         "LastName":"Козубаева",
         "FirstName":"Гулзат",
         "MiddleName":"Борубаевна",
         "Address":"Баткенская область г. Баткен",
         "Telephone":"0770 68-31-05, 0770 47-87-40"
      },
      {
         "OrgId":"8b6510f7-3b6b-4c9d-b8a1-2349b78b2cf3",
         "PIN":"13105197700052",
         "LastName":"Куланбаева ",
         "FirstName":"Кантчайым",
         "MiddleName":"Азыкбековна",
         "Address":"Чуйская область г. Токмок",
         "Telephone":"0558881962;0703856169"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"20401197301144",
         "LastName":"Болотбеков",
         "FirstName":"Максатбек",
         "MiddleName":"Казакович",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0700123888\r\n0709173722"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"20212199301671",
         "LastName":"АСЫЛБАЙ УУЛУ",
         "FirstName":"НУРБЕК",
         "Telephone":""
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"21403197100352",
         "LastName":"Мыйпыев",
         "FirstName":"Анарбек ",
         "MiddleName":"Кулболдуевич",
         "Address":"Чуйская область Аламединский район",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"12405198200649",
         "LastName":"Кыштобаева",
         "FirstName":"Чолпон",
         "MiddleName":"Абдыкасымовна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0708029512\r\n0777003982"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840151*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"16fd5bff-7ab4-4b40-aafb-0282ba5be32e",
         "PIN":"11511197800344",
         "LastName":"Ажыгулова",
         "FirstName":"Жылдыз",
         "MiddleName":"Озгорушова",
         "Address":"Таласская область Таласский район",
         "Telephone":"0701005209"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"20506198300956",
         "LastName":"Скворцов",
         "FirstName":"Андрей",
         "MiddleName":"Александрович",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"+996555249000"
      },
      {
         "OrgId":"23db562e-c0b7-4b7e-ac16-967c96500444",
         "PIN":"22804197600095",
         "LastName":"Кыныкеев ",
         "FirstName":"Канат",
         "MiddleName":"Касыевич",
         "Address":"Ысык-Кульская область г. Балыкчы",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"11208196901106",
         "LastName":"Батырканова",
         "FirstName":"Чынаркул",
         "MiddleName":"Шаршеевна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0500399441"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"20110198700182",
         "LastName":"Рыч",
         "FirstName":"Дмитрий",
         "MiddleName":"Вячеславович",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"+996701770710"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"22211198301192",
         "LastName":"Дүйшеев",
         "FirstName":"Уланбек",
         "MiddleName":"Догдурбаевич",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0709248954"
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"11502198501575",
         "LastName":"Шекеева ",
         "FirstName":"Фарида ",
         "MiddleName":"Асангуловна",
         "Address":"Ысык-Кульская область Жети-Огузский район",
         "Telephone":""
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"10509198100293",
         "LastName":"Акрамова",
         "FirstName":"Дилафруз",
         "MiddleName":"Акбаралиевна",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":"0554747586"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"22505197900516",
         "LastName":"Осмоналиев",
         "FirstName":"Каныбек",
         "MiddleName":"Раимбекович",
         "Address":"Чуйская область Аламединский район",
         "Telephone":""
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"22911198300053",
         "LastName":"Тулешов",
         "FirstName":"Мирлан",
         "MiddleName":"Турузбекович",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0559801480 0557884048"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"11008197000723",
         "LastName":"Мачинова",
         "FirstName":"Салима",
         "MiddleName":"Чекушевна",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0555821085"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"22803196600715",
         "LastName":"Абдрахманов",
         "FirstName":"Самат",
         "MiddleName":"Жумабекович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0709510559, 0554401206"
      },
      {
         "OrgId":"30658f5a-dc22-4a7a-aaf6-63dfb7070c50",
         "PIN":"11806196800368",
         "LastName":"Шакирова ",
         "FirstName":"Айнура ",
         "MiddleName":"Темировна ",
         "Address":"Чуйская область Чуйский район",
         "Telephone":"0700 85-61-23"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"21312196900357",
         "LastName":"Махмануров",
         "FirstName":"Абдухолик",
         "MiddleName":"Абдулхаевич",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0500131269"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"12709198300808",
         "LastName":"Субанова ",
         "FirstName":"Нургуль ",
         "MiddleName":"Акимовна",
         "Address":"Чуйская область Свердловский",
         "Telephone":"0778336646"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"12708198000187",
         "LastName":"Нурбекова",
         "FirstName":"Мээргул",
         "MiddleName":"Жолдошбековна",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0779610598"
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"11607196500451",
         "LastName":"Саликеева ",
         "FirstName":"Эльмира ",
         "MiddleName":"Нургазиевна",
         "Address":"Ысык-Кульская область Жети-Огузский район",
         "Telephone":"0550824271"
      },
      {
         "OrgId":"14eb7aef-c331-4085-a83e-0f5eebf2eaa3",
         "PIN":"12005197900370",
         "LastName":"Сарбаева",
         "FirstName":"Чолпон",
         "MiddleName":"Джаналиевна",
         "Telephone":"0700-43-23-07 "
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"22508197200787",
         "LastName":"Ашиев",
         "FirstName":"Талантбек",
         "MiddleName":"Токонович",
         "Address":"Чуйская область Свердловский",
         "Telephone":"0700373252"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"12112196400881",
         "LastName":"Ажыматова",
         "FirstName":"Уялкан",
         "MiddleName":"Боптаевна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0777831414"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840151*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"11005198800973",
         "LastName":"Бегиматова",
         "FirstName":"Айгерим",
         "MiddleName":"Эрнисбековна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0550500148\r\n0770994399"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"12406197400731",
         "LastName":"Балиева",
         "FirstName":"Виктория",
         "MiddleName":"Владимировна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0550 330194"
      },
      {
         "OrgId":"14eb7aef-c331-4085-a83e-0f5eebf2eaa3",
         "PIN":"10901197000105",
         "LastName":"Мазиева",
         "FirstName":"Венера",
         "MiddleName":"Мукашовна",
         "Address":"Ысык-Кульская область Ысык-Кульский район",
         "Telephone":"0700-19-38-32"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"11210198401637",
         "LastName":"Кошман",
         "FirstName":"Светлана",
         "MiddleName":"Витальевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0 555 240 783"
      },
      {
         "OrgId":"51c381f2-6ad6-4382-81f9-3d36e9abea08",
         "PIN":"21808197900687",
         "LastName":"Токтомушов",
         "FirstName":"Туйгунбек",
         "MiddleName":"Исабекович",
         "Address":"Нарынская область Кочкорский район",
         "Telephone":"0705208780"
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"12107198600589",
         "LastName":"Мамадалиева ",
         "FirstName":"Гулноза",
         "MiddleName":"Махамаджановна",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":"0559 86 21 07"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"11205197800707",
         "LastName":"Матраимова",
         "FirstName":"Эльнура",
         "MiddleName":"Чырмашовна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0558 12 05 78"
      },
      {
         "OrgId":"ab27c82d-8348-4dba-ba9c-ceae3a67704e",
         "PIN":"11204198400720",
         "LastName":"Асанова",
         "FirstName":"Ширин",
         "MiddleName":"Жанузаковна",
         "Address":"Ысык-Кульская область г. Каракол",
         "Telephone":""
      },
      {
         "OrgId":"ab27c82d-8348-4dba-ba9c-ceae3a67704e",
         "PIN":"12005197900370",
         "LastName":"Сарбаева",
         "FirstName":"Чолпон ",
         "MiddleName":"Джаналиевна",
         "Address":"Ысык-Кульская область г. Каракол",
         "Telephone":""
      },
      {
         "OrgId":"ab27c82d-8348-4dba-ba9c-ceae3a67704e",
         "PIN":"20210197700581",
         "LastName":"Ыдырысов",
         "FirstName":"Нурлан",
         "MiddleName":"Абдышевич",
         "Telephone":""
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"20602197400307",
         "LastName":"Иманалиев",
         "FirstName":"Эмилбек",
         "MiddleName":"Касенович",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"+996709536695\t\t\t\t\r\n"
      },
      {
         "OrgId":"5df10bbc-d256-40f8-b641-5ef31ce11540",
         "PIN":"12099198200413",
         "LastName":"Каттабекова",
         "FirstName":"Шаиргул",
         "MiddleName":"Султаналиевна",
         "Address":"Чуйская область Панфиловский район",
         "Telephone":"0777477905"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"11311197600391",
         "LastName":"Мергембаева",
         "FirstName":"Айнагуль",
         "MiddleName":"Казнаевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0 707 03 79 78"
      },
      {
         "OrgId":"c748cdb5-502a-4243-8494-48e224968019",
         "PIN":"21907197600318",
         "LastName":"Исаев",
         "FirstName":"Руслан",
         "MiddleName":"Кудайбергенович",
         "Address":"Ысык-Кульская область Тюпский район",
         "Telephone":"0702600750"
      },
      {
         "OrgId":"a598bea0-37c9-4dc2-9bc7-fb3aa1097e14",
         "PIN":"2290119900231",
         "LastName":"Мамбетов",
         "FirstName":"Мухамбетаалы",
         "MiddleName":"Момбекович",
         "Address":"Джалал-Абадская область Чаткальский район",
         "Telephone":"0771566231"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"21912197600712",
         "LastName":"Кулукеев",
         "FirstName":"Уланбек",
         "MiddleName":"Темиралиевич",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0774 28 16 79, 0709 509 366"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"11610197400528",
         "LastName":"Бостериева",
         "FirstName":"Айнура",
         "MiddleName":"Эмильевна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0551338874"
      },
      {
         "OrgId":"c8641d03-b553-4f95-adfb-ec002fd64e06",
         "PIN":"22009198500932",
         "LastName":"Абдрайымов",
         "FirstName":"Кайып",
         "MiddleName":"Асылбекович",
         "Address":"Чуйская область Московский район",
         "Telephone":"0557 50 29 02"
      },
      {
         "OrgId":"51c381f2-6ad6-4382-81f9-3d36e9abea08",
         "PIN":"21512198000037",
         "LastName":"АЛЫКУЛОВ",
         "FirstName":"иСЛАМБЕК",
         "MiddleName":"жЭЭНБЕКОВИЧ",
         "Telephone":""
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840120*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"20708199102231",
         "LastName":"Анорбоев ",
         "FirstName":"Хусниддин",
         "MiddleName":"Едгарович",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":"0772458798"
      },
      {
         "OrgId":"23db562e-c0b7-4b7e-ac16-967c96500444",
         "PIN":"20708198600979",
         "LastName":"Абыкев ",
         "FirstName":"Алмаз",
         "MiddleName":"Өзүкович",
         "Address":"Ысык-Кульская область г. Балыкчы",
         "Telephone":"0705270287"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840123*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"12204197400483",
         "LastName":"Билалова",
         "FirstName":"Гулзат",
         "MiddleName":"Билаловна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"---"
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"11802198700896",
         "LastName":"Захидова ",
         "FirstName":"Зарина",
         "MiddleName":"Захидовна",
         "Address":"Ысык-Кульская область г. Каракол",
         "Telephone":"0 559 038 566"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"21204199100926",
         "LastName":"Borubaev",
         "FirstName":"Zhakypbek",
         "MiddleName":"Zharkynbaevich",
         "Address":"Ысык-Кульская область Тонский район",
         "Telephone":""
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10412196000506",
         "LastName":"Ажикулова",
         "FirstName":"Агурук",
         "MiddleName":"Талиповна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0779 65 14 35"
      },
      {
         "OrgId":"d328865b-ede4-4674-92c6-c134f55c3c6f",
         "PIN":"22605198300870",
         "LastName":"Акматов",
         "FirstName":"Абдыбек",
         "MiddleName":"Мамадиевич",
         "Address":"Баткенская область г. Баткен",
         "Telephone":"0774 29-21-43"
      },
      {
         "OrgId":"d328865b-ede4-4674-92c6-c134f55c3c6f",
         "PIN":"22605198300870",
         "LastName":"Акматов",
         "FirstName":"Абдыбек",
         "MiddleName":"Мамадиевич",
         "Address":"Баткенская область г. Баткен",
         "Telephone":"0774 29-21-43"
      },
      {
         "OrgId":"c7b6aa81-a4b7-4ad6-be03-e4b54e8084da",
         "PIN":"21404198300705",
         "LastName":"Сапаев ",
         "FirstName":"Каныбек",
         "MiddleName":"Төрөбаевич",
         "Address":"Баткенская область Лейлекский район",
         "Telephone":"0771597071"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"10111199200804",
         "LastName":"Раяпова",
         "FirstName":"Жамал",
         "MiddleName":"Болоткановна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0703801402"
      },
      {
         "OrgId":"3277f73d-b02d-4f35-ad1a-80b349eecf6b",
         "PIN":"13004196800347",
         "LastName":"Абдыкадырова ",
         "FirstName":"Гулай",
         "MiddleName":"Шариповна",
         "Address":"Ысык-Кульская область Тонский район",
         "Telephone":"0770132074"
      },
      {
         "OrgId":"60f22545-cb84-4795-81a3-2eeeeb0fb88a",
         "PIN":"20606197000969",
         "LastName":"АСАНОВ",
         "FirstName":"АДИЛБЕК",
         "MiddleName":"РИСАЛИЕВИЧ",
         "Address":"Джалал-Абадская область Ала-Букинский район",
         "Telephone":"+996559788958; \r\n+996777260779; \r\n+996773857907."
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"12201197900682",
         "LastName":"Созинова ",
         "FirstName":"Юлия",
         "MiddleName":"Михайловна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0 555 133 106"
      },
      {
         "OrgId":"14eb7aef-c331-4085-a83e-0f5eebf2eaa3",
         "PIN":"10206198100913",
         "LastName":"Андреева",
         "FirstName":"Айнура",
         "MiddleName":"Ишенбековна",
         "Address":"Ысык-Кульская область Ысык-Кульский район",
         "Telephone":""
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"22808198100392",
         "LastName":"Молдалиев",
         "FirstName":"Тимур",
         "MiddleName":"Дуйшенкулович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0703 587828 (Т.Д.), 0779 901537 (У.У)"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"21002198502066",
         "LastName":"Асанов",
         "FirstName":"Мукай",
         "MiddleName":"Кубанычбекович",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"+996700533177"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"10110197600327",
         "LastName":"Мейманбекова",
         "FirstName":"Элиза",
         "MiddleName":"Эшимкановна",
         "Telephone":"0706297828"
      },
      {
         "OrgId":"c7b6aa81-a4b7-4ad6-be03-e4b54e8084da",
         "PIN":"22101198401007",
         "LastName":"Курбаналиев",
         "FirstName":"Шайбек",
         "MiddleName":"Тагаймуратович",
         "Address":"Баткенская область Лейлекский район",
         "Telephone":"0772105636, 0779059666"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"22510197500425",
         "LastName":"Кенжебаев",
         "FirstName":"Байгазы",
         "MiddleName":"Бобунович",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0551556116"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"11812196700050",
         "LastName":"Хижнякова",
         "FirstName":"Светлана",
         "MiddleName":"Анатольевна",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"46-34-52, 0553-13-69-18"
      },
      {
         "OrgId":"8b6510f7-3b6b-4c9d-b8a1-2349b78b2cf3",
         "PIN":"10506199000490",
         "LastName":"Касымова",
         "FirstName":"Озода",
         "MiddleName":"Равшанбековна",
         "Address":"Чуйская область г. Токмок",
         "Telephone":"0550222477"
      },
      {
         "OrgId":"3277f73d-b02d-4f35-ad1a-80b349eecf6b",
         "PIN":"11710198501013",
         "LastName":"Жумашева",
         "FirstName":"Жыпара",
         "MiddleName":"Жыргалбековна",
         "Address":"Ысык-Кульская область Тонский район",
         "Telephone":"0703309397"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"21503199002201",
         "LastName":"Бектур уулу",
         "FirstName":"Калысбек",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0708521380"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840154*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"ff3b071c-fc51-4f64-8bd4-11a5377e93d2",
         "PIN":"12008196510080",
         "LastName":"Баннанова ",
         "FirstName":"Адалатхон",
         "MiddleName":"Султанмурадовна",
         "Address":"Баткенская область г. Сулюкта",
         "Telephone":"0777 87 66 32"
      },
      {
         "OrgId":"3bb3cb8f-a747-4026-9031-c6b67ea9735b",
         "PIN":"21309197600358",
         "LastName":"Умаралиев",
         "FirstName":"Даниярбек",
         "MiddleName":"Масалбекович",
         "Address":"Таласская область Таласский район",
         "Telephone":"0700760005"
      },
      {
         "OrgId":"30658f5a-dc22-4a7a-aaf6-63dfb7070c50",
         "PIN":"21501196100837",
         "LastName":"Чомоев ",
         "FirstName":"Турусбек",
         "MiddleName":"Тентимишович",
         "Telephone":"0701555468"
      },
      {
         "OrgId":"93df61dd-a2fe-4f52-b961-f1b27bd27a8c",
         "PIN":"21001198201193",
         "LastName":"Адыбаев",
         "FirstName":"Омурбек",
         "MiddleName":"Кепилович",
         "Address":"Нарынская область Нарынский район",
         "Telephone":"0707924557"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"22103198001027",
         "LastName":"Саткынбаев",
         "FirstName":"Ырысбек",
         "MiddleName":"Назарбекович",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0550900778"
      },
      {
         "OrgId":"d328865b-ede4-4674-92c6-c134f55c3c6f",
         "PIN":"11710198201133",
         "LastName":"козубаева",
         "FirstName":"гулзат",
         "MiddleName":"борубаевна",
         "Address":"Баткенская область г. Баткен",
         "Telephone":"0770 47-87-40, 0770 68-31-05"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"1260919830089*",
         "LastName":"ДАУЗОВА",
         "FirstName":"ФАРИДА",
         "MiddleName":"ФАРХАДОВНА",
         "Address":"Баткенская область Ак-Талинский район",
         "Telephone":""
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"10807198501459",
         "LastName":"Симакина",
         "FirstName":"Анна",
         "MiddleName":"Юрьевна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0553000682, "
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"10404196400605",
         "LastName":"МУСАБАЕВА",
         "FirstName":"РИМА",
         "MiddleName":"МИСИЛИМОВНА",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0553027085"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"21404197000222",
         "LastName":"Клименко",
         "FirstName":"Олег",
         "MiddleName":"Александрович",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0555931033\r\n0555247045"
      },
      {
         "OrgId":"d328865b-ede4-4674-92c6-c134f55c3c6f",
         "PIN":"12311197700544",
         "LastName":"Маткайымова",
         "FirstName":"урматкан",
         "MiddleName":"Абдижапаровна",
         "Address":"Баткенская область г. Баткен",
         "Telephone":"0771 35 46 06"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"22012197400500",
         "LastName":"Дуйшеналиев",
         "FirstName":"Руслан",
         "MiddleName":"Сапаралиевич",
         "Address":"Нарынская область Жумгальский район",
         "Telephone":"0708912912"
      },
      {
         "OrgId":"ab27c82d-8348-4dba-ba9c-ceae3a67704e",
         "PIN":"11811197600027",
         "LastName":"Касымбаева ",
         "FirstName":"Гульбарчы",
         "MiddleName":"Шаршеновна",
         "Address":"Ысык-Кульская область г. Каракол",
         "Telephone":""
      },
      {
         "OrgId":"c7b6aa81-a4b7-4ad6-be03-e4b54e8084da",
         "PIN":"10104196301153",
         "LastName":"Ибрагимова",
         "FirstName":"Мактымай",
         "MiddleName":"Нигматовна",
         "Address":"Баткенская область Лейлекский район",
         "Telephone":"0772775063"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"21604199100802",
         "LastName":"Путилин",
         "FirstName":"Антон",
         "MiddleName":"Алексеевич",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0550517280"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"21005197700753",
         "LastName":"Суранчиев",
         "FirstName":"Сейтек",
         "MiddleName":"Таласбекович",
         "Address":"����������.Бишкек Свердловский",
         "Telephone":"0708002769"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"21507196800227",
         "LastName":"Сарманов",
         "FirstName":"Улан",
         "MiddleName":"Чыкешович",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0707834356, 0704228199"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"21403197100352",
         "LastName":"Мыйпыев",
         "FirstName":"Анарбек ",
         "MiddleName":"Кулболдуевич",
         "Address":"Чуйская область Аламединский район",
         "Telephone":""
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"11809198100192",
         "LastName":"Муса",
         "FirstName":"Рахима",
         "MiddleName":"Мухамеевна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0555180981"
      },
      {
         "OrgId":"b3ea3de1-5d76-461c-90ac-68d312b7b665",
         "PIN":"22012197400500",
         "LastName":"Дуйшеналиев",
         "FirstName":"Руслан",
         "MiddleName":"Сапаралиевич",
         "Address":"Нарынская область Жумгальский район",
         "Telephone":""
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"12103196800974",
         "LastName":"Осетрова",
         "FirstName":"Лариса",
         "MiddleName":"Александровна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"+996559500699"
      },
      {
         "OrgId":"c7b6aa81-a4b7-4ad6-be03-e4b54e8084da",
         "PIN":"10304197700135",
         "LastName":"Абдырахманова",
         "FirstName":"Мавжуда",
         "MiddleName":"Абдираимовна",
         "Address":"Баткенская область Лейлекский район",
         "Telephone":"0777310939, 0550281598"
      },
      {
         "OrgId":"8b6510f7-3b6b-4c9d-b8a1-2349b78b2cf3",
         "PIN":"21906198400161",
         "LastName":"Апиев",
         "FirstName":"Акибай",
         "MiddleName":"Куанычбекович",
         "Address":"Чуйская область Чуйский район",
         "Telephone":""
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"11702196900151",
         "LastName":"Алымбекова",
         "FirstName":"Айнура",
         "MiddleName":"Джумабаевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0 700 07 09 72"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"11102197600204",
         "LastName":"Далбаева",
         "FirstName":"Перизат",
         "MiddleName":"Сталбековна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0551 91 23 18"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"11610198101099",
         "LastName":"Карабекова ",
         "FirstName":"Аксана",
         "MiddleName":"Базаралиевна",
         "Address":"г. Ош Узгенский район",
         "Telephone":"0700356984\r\n+79268929641"
      },
      {
         "OrgId":"9d8b0ca0-d618-4157-af15-5e7cca7d9f23",
         "PIN":"11708199201873",
         "LastName":"Уланбек кызы",
         "FirstName":"Айпери",
         "Address":"Джалал-Абадская область г. Таш-Комур",
         "Telephone":"0773-68-35-02"
      },
      {
         "OrgId":"64ecd259-a848-474b-8e8d-571612a7e43b",
         "PIN":"11805197300080",
         "LastName":"Нургазиева",
         "FirstName":"Махабат",
         "MiddleName":"Калыбаевна",
         "Address":"Джалал-Абадская область Токтогульский район",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"12209196700131",
         "LastName":"Намазбаева",
         "FirstName":"Замира",
         "MiddleName":"Анарбековна",
         "Telephone":"0556444776"
      },
      {
         "OrgId":"c7b6aa81-a4b7-4ad6-be03-e4b54e8084da",
         "PIN":"11304198600868",
         "LastName":"Ашурова",
         "FirstName":"Назгуль",
         "MiddleName":"Абдуразаковна",
         "Address":"Баткенская область Лейлекский район",
         "Telephone":"0771046304"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840120*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"10307198300816",
         "LastName":"Мусуралиева",
         "FirstName":"Айжаркын",
         "MiddleName":"Жаныбековна",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0555601271"
      },
      {
         "OrgId":"14eb7aef-c331-4085-a83e-0f5eebf2eaa3",
         "PIN":"13006196900142",
         "LastName":"Шмитько",
         "FirstName":"Светлана",
         "MiddleName":"Викторовна",
         "Telephone":"0555-32-82-40"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"13006196900142",
         "LastName":"Шмитько",
         "FirstName":"Светлана",
         "MiddleName":"Викторовна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0555328240"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"13011197700294",
         "LastName":"Тюлюндиева ",
         "FirstName":"Сабира ",
         "MiddleName":"Манатовна",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0777900310"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"11012198101078",
         "LastName":"Каратаева",
         "FirstName":"Самара",
         "MiddleName":"Уметбековна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0702525683"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10110197800929",
         "LastName":"Арыстанбекова",
         "FirstName":"Мыскал",
         "MiddleName":"Абдылдаевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0700 800 491"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"12704197300315",
         "LastName":"Ураимова",
         "FirstName":"Керимбубу",
         "MiddleName":"Жакыповна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0500864616"
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"12602197700316",
         "LastName":"Назарова",
         "FirstName":"Гулнора",
         "MiddleName":"Батырбековна",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0779009877"
      },
      {
         "OrgId":"2765827a-88ef-4144-8050-9a2fd66fb136",
         "PIN":"11911198000905",
         "LastName":"Боруева",
         "FirstName":"Гүлсара",
         "MiddleName":"Мавлянкуловна",
         "Address":"Джалал-Абадская область Аксыйский район",
         "Telephone":"0777004401"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"22011197801094",
         "LastName":"Омурбеков",
         "FirstName":"Рустам",
         "MiddleName":"Жекшеналиевич",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0554131787"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10104196800142",
         "LastName":"Акыбаева",
         "FirstName":"Анаркул",
         "MiddleName":"Шаршенбековна",
         "Address":"Ысык-Кульская область Тюпский район",
         "Telephone":"0707586703"
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"11902199500269",
         "LastName":"Курманбекова ",
         "FirstName":"Ширин",
         "MiddleName":"Курманбековна ",
         "Address":"Джалал-Абадская область г. Джалал-Абад",
         "Telephone":"0704 90-90-80.\r\n0704 95-95-80"
      },
      {
         "OrgId":"ab27c82d-8348-4dba-ba9c-ceae3a67704e",
         "PIN":"11006197000862",
         "LastName":"Дуйшеева",
         "FirstName":"Венера",
         "MiddleName":"Кадыровна",
         "Address":"Ысык-Кульская область Тюпский район",
         "Telephone":""
      },
      {
         "OrgId":"8b6510f7-3b6b-4c9d-b8a1-2349b78b2cf3",
         "PIN":"21701198100400",
         "LastName":"Метуев",
         "FirstName":"Руслан",
         "MiddleName":"Исхарович",
         "Address":"Чуйская область г. Токмок",
         "Telephone":"0700329984"
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"10507199200466",
         "LastName":"Кадирова",
         "FirstName":"Малохатхон",
         "MiddleName":"Шавкатовна",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":"0553104985"
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"10308198601171",
         "LastName":"Маматеминова ",
         "FirstName":"Гулнура ",
         "MiddleName":"Мамасалиевна ",
         "Address":"Джалал-Абадская область Аксыйский район",
         "Telephone":""
      },
      {
         "OrgId":"64ecd259-a848-474b-8e8d-571612a7e43b",
         "PIN":"10208198700693",
         "LastName":"Кыдыралиева",
         "FirstName":"Айчүрөк",
         "MiddleName":"Эдилбековна",
         "Address":"Джалал-Абадская область Токтогульский район",
         "Telephone":"0702 972889"
      },
      {
         "OrgId":"c748cdb5-502a-4243-8494-48e224968019",
         "PIN":"21309196500530",
         "LastName":"Акматов",
         "FirstName":"Бекбо",
         "MiddleName":"Шамыратович",
         "Address":"Ысык-Кульская область Тюпский район",
         "Telephone":"0705003026"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840124*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"22308198100528",
         "LastName":"Нурматбек уулу",
         "FirstName":"Улан",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0708745021"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"12708198000187",
         "LastName":"Нурбекова",
         "FirstName":"Мээргул",
         "MiddleName":"Жолдошбековна",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0779610598"
      },
      {
         "OrgId":"16fd5bff-7ab4-4b40-aafb-0282ba5be32e",
         "PIN":"11509197500455",
         "LastName":"Ташмамбетова",
         "FirstName":"Жылдыз",
         "MiddleName":"Райкановна",
         "Address":"Таласская область Таласский район",
         "Telephone":"0770508373"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"11603198010016",
         "LastName":"Акимова",
         "FirstName":"Бермет",
         "MiddleName":"Куванычбековна",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0550695713"
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"10503198800",
         "LastName":"Мамышева ",
         "FirstName":"Калия ",
         "MiddleName":"Иманбаевна",
         "Address":"Ысык-Кульская область Жети-Огузский район",
         "Telephone":"0500916776"
      },
      {
         "OrgId":"ff3b071c-fc51-4f64-8bd4-11a5377e93d2",
         "PIN":"10203198300460",
         "LastName":"Халова ",
         "FirstName":"Жылдызкан",
         "MiddleName":"Шамшиддиновна",
         "Telephone":""
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2050919910253*",
         "LastName":"КАЛЫСОВ",
         "FirstName":"ТИЛЕК",
         "MiddleName":"КАЛЫСОВИЧ",
         "Address":"г.Бишкек Первомайский",
         "Telephone":""
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"12205199000175",
         "LastName":"Мейкин",
         "FirstName":"Айгеля-мишель",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0708804040"
      },
      {
         "OrgId":"b0a96501-f5f4-48a4-8103-6c7a7ba290c4",
         "PIN":"10609197200172",
         "LastName":"Субанкулова ",
         "FirstName":"Керезбубу",
         "MiddleName":"Жумабековна",
         "Address":"Джалал-Абадская область Тогуз-Тороуский район",
         "Telephone":"0703696913"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"22912197800103",
         "LastName":"Абдужаббаров ",
         "FirstName":"Нурлан",
         "MiddleName":"Якубжанович",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0778336646"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"11603198010016",
         "LastName":"Акимова",
         "FirstName":"Бермет",
         "MiddleName":"Куванычбековна",
         "Telephone":"0550635713"
      },
      {
         "OrgId":"b3ea3de1-5d76-461c-90ac-68d312b7b665",
         "PIN":"21901196800220",
         "LastName":"Качкынтаев ",
         "FirstName":"Карыбек ",
         "MiddleName":"Жумалиевич",
         "Address":"Нарынская область Жумгальский район",
         "Telephone":"0700 282364"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"22511197500492",
         "LastName":"Жусупбеков",
         "FirstName":"Ратмуслим",
         "MiddleName":"Райымбекович",
         "Address":"Джалал-Абадская область г. Кара-Куль",
         "Telephone":""
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"21007198501336",
         "LastName":"Искаков",
         "FirstName":"Акбар",
         "MiddleName":"Рыскельдиевич",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0550288045"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"12802197500364",
         "LastName":"Барыктабасова",
         "FirstName":"Токтобубу",
         "MiddleName":"Керимакуновна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0 558 282 175,  0705 28 02 75"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"21805197701136",
         "LastName":"Казыбаев",
         "FirstName":"Алмаз",
         "MiddleName":"Иманалиевич",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0700296978; 65-97-14"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"21906197300926",
         "LastName":"Апсеитов",
         "FirstName":"Бактыбек",
         "MiddleName":"Дубанбекович",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0706982044"
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"21804197800861",
         "LastName":"Жанганаев ",
         "FirstName":"Бактыбек",
         "MiddleName":"Урмамбетович",
         "Address":"Ысык-Кульская область Жети-Огузский район",
         "Telephone":"0 702 097 292"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"11007197800914",
         "LastName":"Оспатаева",
         "FirstName":"Индира ",
         "MiddleName":"Иркиновна",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0550880338\r\n0558273788"
      },
      {
         "OrgId":"c7b6aa81-a4b7-4ad6-be03-e4b54e8084da",
         "PIN":"21004198401346",
         "LastName":"Абдиллаев",
         "FirstName":"Файзулло",
         "MiddleName":"Саматович",
         "Address":"Баткенская область Лейлекский район",
         "Telephone":"0770451873"
      },
      {
         "OrgId":"23db562e-c0b7-4b7e-ac16-967c96500444",
         "PIN":"22701197700530",
         "LastName":"КЫДЫБАЕВ",
         "FirstName":"НУРЛАН",
         "MiddleName":"БЕЙШЕНАЛИЕВИЧ",
         "Address":"Ысык-Кульская область г. Балыкчы",
         "Telephone":"0703344083,0707344083"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10503197200426",
         "LastName":"Ахмедакунова",
         "FirstName":"Алтынай",
         "MiddleName":"Турсунбековна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0555 96 04 11, 0777 96 04 11"
      },
      {
         "OrgId":"8b6510f7-3b6b-4c9d-b8a1-2349b78b2cf3",
         "PIN":"12406196500465",
         "LastName":"Тынаева",
         "FirstName":"Елмира",
         "MiddleName":"Усоновна",
         "Address":"Чуйская область г. Токмок",
         "Telephone":"0550070149"
      },
      {
         "OrgId":"b0a96501-f5f4-48a4-8103-6c7a7ba290c4",
         "PIN":"22311197100672",
         "LastName":"Балапанов",
         "FirstName":"Нурланбек",
         "MiddleName":"Манапбаевич",
         "Address":"Джалал-Абадская область Тогуз-Тороуский район",
         "Telephone":"0772609 991"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"11705197700815",
         "LastName":"Джусманалиева",
         "FirstName":"Гульбара",
         "MiddleName":"Мариковна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0705328756"
      },
      {
         "OrgId":"3277f73d-b02d-4f35-ad1a-80b349eecf6b",
         "PIN":"21208197500251",
         "LastName":"Жолочиев ",
         "FirstName":"Бактыбек",
         "MiddleName":"Жумадилович",
         "Address":"Ысык-Кульская область Тонский район",
         "Telephone":"0702120875"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"20811198000064",
         "LastName":"Исмаилов ",
         "FirstName":"Өмүрбек",
         "MiddleName":"Таштемирович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0703 747489, 0555 756389"
      },
      {
         "OrgId":"3277f73d-b02d-4f35-ad1a-80b349eecf6b",
         "PIN":"22108196000579",
         "LastName":"Абдыкеримов",
         "FirstName":"Жумакадыр",
         "MiddleName":"Турумбекович",
         "Address":"Ысык-Кульская область Тонский район",
         "Telephone":""
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"11112198201119",
         "LastName":"Осмонкулова",
         "FirstName":"Гулькаир",
         "MiddleName":"Ашимбековна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0551393924"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"12410197300698",
         "LastName":"Батырканова",
         "FirstName":"Лира",
         "MiddleName":"Кадырбердиевна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0706663425"
      },
      {
         "OrgId":"7f311898-d254-4668-8184-d1794e695a19",
         "PIN":"12411196500429",
         "LastName":"Абдукеримова ",
         "FirstName":"Бурмакуль",
         "MiddleName":"Асанкуловна ",
         "Address":"Таласская область Бакай-Атинский район",
         "Telephone":"0707385252"
      },
      {
         "OrgId":"8b6510f7-3b6b-4c9d-b8a1-2349b78b2cf3",
         "PIN":"12705197250002",
         "LastName":"Жапаркулова",
         "FirstName":"Кызылгул",
         "MiddleName":"Откулбековна",
         "Address":"Чуйская область г. Токмок",
         "Telephone":"0773011575"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"21108196300031",
         "LastName":"Степаненко",
         "FirstName":"Игорь",
         "MiddleName":"Михайлович",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"+996555128585"
      },
      {
         "OrgId":"ab27c82d-8348-4dba-ba9c-ceae3a67704e",
         "PIN":"20308197700676",
         "LastName":"Коробаев",
         "FirstName":"Абайбек",
         "MiddleName":"Алымбекович",
         "Address":"Ысык-Кульская область г. Каракол",
         "Telephone":""
      },
      {
         "OrgId":"30658f5a-dc22-4a7a-aaf6-63dfb7070c50",
         "PIN":"22801196700928",
         "LastName":"Кармышев",
         "FirstName":"Телектеш",
         "MiddleName":"Зарлыкович",
         "Address":"Чуйская область Чуйский район",
         "Telephone":""
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"10903198501555",
         "LastName":"Айдарова ",
         "FirstName":"Анарбу ",
         "Address":"Джалал-Абадская область Базар-Коргонский район",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"21705196900763",
         "LastName":"Омуров",
         "FirstName":"Мухтар",
         "MiddleName":"Аманович",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0501744794"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"12203198001124",
         "LastName":"Кожоева",
         "FirstName":"Айзат",
         "MiddleName":"Орумбаевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0703 99 34 10"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"21509196900438",
         "LastName":"Ибраимкулов",
         "FirstName":"Мирбек",
         "MiddleName":"Джумаевич",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0550533528"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"12310197200776",
         "LastName":"Мамырбаева ",
         "FirstName":"Айгүль",
         "MiddleName":"Жусуповна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0709248954\r\n0553248954"
      },
      {
         "OrgId":"9d8b0ca0-d618-4157-af15-5e7cca7d9f23",
         "PIN":"11708199201873",
         "LastName":"Уланбек кызы",
         "FirstName":"Айпери",
         "Address":"Джалал-Абадская область г. Таш-Комур",
         "Telephone":""
      },
      {
         "OrgId":"23db562e-c0b7-4b7e-ac16-967c96500444",
         "PIN":"11001197600934",
         "LastName":"Шабданбекова ",
         "FirstName":"Миржана",
         "MiddleName":"Темирбековна",
         "Address":"Ысык-Кульская область г. Балыкчы",
         "Telephone":""
      },
      {
         "OrgId":"5df10bbc-d256-40f8-b641-5ef31ce11540",
         "PIN":"12007198400490",
         "LastName":"Ибраимова",
         "FirstName":"Меримкүль",
         "MiddleName":"Рыспековна",
         "Address":"Чуйская область Панфиловский район",
         "Telephone":"0505108484"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"20203197200770",
         "LastName":"Жанабилов",
         "FirstName":"Алдаяр",
         "MiddleName":"Калыкович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0706300123 А.К.\r\n0700300123 Т.М."
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10212197700226",
         "LastName":"Касымова",
         "FirstName":"Гулнара ",
         "MiddleName":"Туконбаевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0703 51 01 24"
      },
      {
         "OrgId":"c7b6aa81-a4b7-4ad6-be03-e4b54e8084da",
         "PIN":"12709196900560",
         "LastName":"Кошбакова",
         "FirstName":"Санталат",
         "MiddleName":"Маматкуловна",
         "Address":"Баткенская область Лейлекский район",
         "Telephone":"0777843197"
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"11108198550122",
         "LastName":"Прохоренко",
         "FirstName":"Ольга",
         "MiddleName":"Викторовна",
         "Telephone":"0550880512"
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"12101198600896",
         "LastName":"Турусбекова",
         "FirstName":"Элзат",
         "MiddleName":"Алмазбековна",
         "Address":"Ысык-Кульская область Жети-Огузский район",
         "Telephone":"0777813490"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2050719840040*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"14eb7aef-c331-4085-a83e-0f5eebf2eaa3",
         "PIN":"10606197200308",
         "LastName":"Попова",
         "FirstName":"Оксана",
         "MiddleName":"Сергеевна",
         "Address":"Ысык-Кульская область Ысык-Кульский район",
         "Telephone":""
      },
      {
         "OrgId":"3277f73d-b02d-4f35-ad1a-80b349eecf6b",
         "PIN":"20903196900303",
         "LastName":"Токталиев",
         "FirstName":"Нурбек",
         "MiddleName":"Макешович",
         "Address":"Ысык-Кульская область Тонский район",
         "Telephone":""
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"20904197610048",
         "LastName":"Эшимов ",
         "FirstName":"Акылбек",
         "MiddleName":"Жусупович",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0557822000"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"11601197800760",
         "LastName":"Чиркова",
         "FirstName":"Наталья",
         "MiddleName":"Георгиевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0 558 774 773"
      },
      {
         "OrgId":"64ecd259-a848-474b-8e8d-571612a7e43b",
         "PIN":"12709198600422",
         "LastName":"Аманова",
         "FirstName":"Рахат",
         "MiddleName":"Майрамбековна",
         "Address":"Джалал-Абадская область Токтогульский район",
         "Telephone":"0702 013238"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2091119950161*",
         "LastName":"САДЫРОВ",
         "FirstName":"ЭРНАЗАР",
         "MiddleName":"САДЫРОВИЧ",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":""
      },
      {
         "OrgId":"c7b6aa81-a4b7-4ad6-be03-e4b54e8084da",
         "PIN":"12303197701336",
         "LastName":"Жаныбекова",
         "FirstName":"Лола",
         "MiddleName":"Эгембердиевна",
         "Address":"Баткенская область Лейлекский район",
         "Telephone":"0777138531"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"10307198300816",
         "LastName":"Мусуралиева",
         "FirstName":"Айжаркын",
         "MiddleName":"Жаныбековна",
         "Address":"Чуйская область Аламединский район",
         "Telephone":""
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10403198100293",
         "LastName":"Кузина",
         "FirstName":"Ирина",
         "MiddleName":"Вилльевна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0551659518, 0700377757"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"11304197600492",
         "LastName":"Сыйрыкова",
         "FirstName":"Майрамгул",
         "MiddleName":"Садыкказиевна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0707083642"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"22602198300448",
         "LastName":"Огурцов",
         "FirstName":"Станислав",
         "MiddleName":"Владимирович",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0702 148676, 0700126191"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"10509197600095",
         "LastName":"Султангазиева ",
         "FirstName":"Мира",
         "MiddleName":"Алсеитовна",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0553248385\r\n0703290876"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"20205197701288",
         "LastName":"Абиев",
         "FirstName":"Эсен",
         "MiddleName":"Акматбекович",
         "Address":"г.Бишкек Сокулукский район",
         "Telephone":""
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"12110197510020",
         "LastName":"Адинаева",
         "FirstName":"Людмила",
         "MiddleName":"Валерьевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0554 77 99 54"
      },
      {
         "OrgId":"aa65c2f7-3091-40e5-939d-6a18250e125a",
         "PIN":"21603198200708",
         "LastName":"Исираилов",
         "FirstName":"Улан",
         "MiddleName":"Токтоналыевич",
         "Address":"Джалал-Абадская область г. Кара-Куль",
         "Telephone":"0777931743"
      },
      {
         "OrgId":"ab27c82d-8348-4dba-ba9c-ceae3a67704e",
         "PIN":"11604197901629",
         "LastName":"Абдувалиева",
         "FirstName":"Наталья ",
         "MiddleName":"Александровна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":""
      },
      {
         "OrgId":"ff3b071c-fc51-4f64-8bd4-11a5377e93d2",
         "PIN":"11709197700218",
         "LastName":"Рахманова",
         "FirstName":"Гульмира",
         "MiddleName":"Пайзиллаевна",
         "Address":"Чуйская область Ленинский",
         "Telephone":"0777704333"
      },
      {
         "OrgId":"c7b6aa81-a4b7-4ad6-be03-e4b54e8084da",
         "PIN":"22312198000449",
         "LastName":"Баратбаев",
         "FirstName":"Шухратжон",
         "MiddleName":"Исматуллоевич",
         "Address":"Баткенская область Лейлекский район",
         "Telephone":"0771426109"
      },
      {
         "OrgId":"c6d92271-6ff2-4641-a83f-d02610060c43",
         "PIN":"20109198902214",
         "LastName":"Кавазов ",
         "FirstName":"Изет ",
         "MiddleName":"Мамедович ",
         "Address":"Таласская область Кара-Бууринский район",
         "Telephone":"0552478342"
      },
      {
         "OrgId":"23db562e-c0b7-4b7e-ac16-967c96500444",
         "PIN":"2141119101099",
         "LastName":"Абдыкеримов",
         "FirstName":"Урмат",
         "MiddleName":"Бердибекович",
         "Address":"Ысык-Кульская область г. Балыкчы",
         "Telephone":"0701988818"
      },
      {
         "OrgId":"d328865b-ede4-4674-92c6-c134f55c3c6f",
         "PIN":"12311197700544",
         "LastName":"Маткайымова",
         "FirstName":"урматкан",
         "MiddleName":"Абдижапаровна",
         "Address":"Баткенская область г. Баткен",
         "Telephone":"0771 35-46-05"
      },
      {
         "OrgId":"b0a96501-f5f4-48a4-8103-6c7a7ba290c4",
         "PIN":"11103198100665",
         "LastName":"Умарова ",
         "FirstName":"Аселя ",
         "MiddleName":"Адинаевна",
         "Address":"Джалал-Абадская область Тогуз-Тороуский район",
         "Telephone":"0707 54 54 45"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"21512195900266",
         "LastName":"Камбаралиев",
         "FirstName":"Жээналы",
         "MiddleName":"-",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0772 711 595, 0773 640 684"
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"21802199001723",
         "LastName":"Шинкоренко",
         "FirstName":"Артем",
         "MiddleName":"Иванович",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0556336308"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"12702197700482",
         "LastName":"Сатыбалдиева ",
         "FirstName":"Айжан ",
         "MiddleName":"Монолдоровна",
         "Address":"Чуйская область Свердловский",
         "Telephone":"0553884677"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"10704197801385",
         "LastName":"Керимкулова",
         "FirstName":"Жанат ",
         "MiddleName":"Таштемирова",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0550900778"
      },
      {
         "OrgId":"23db562e-c0b7-4b7e-ac16-967c96500444",
         "PIN":"10306197300370",
         "LastName":"Абдрахманова",
         "FirstName":"Нурила",
         "MiddleName":"Кочконбаевна",
         "Address":"Ысык-Кульская область г. Балыкчы",
         "Telephone":"0701365660"
      },
      {
         "OrgId":"e5411cdd-3caf-4798-a7bf-fd57c856c82b",
         "PIN":"11106197900857",
         "LastName":"ИСРАИЛОВА",
         "FirstName":"телегей",
         "MiddleName":"исмаиловна",
         "Address":"Ошская область Алайский район",
         "Telephone":"0779937724,0755133191"
      },
      {
         "OrgId":"b382a964-bbd7-4540-b6e4-bc9250ab7005",
         "PIN":"12806199400091",
         "LastName":"Алматова ",
         "FirstName":"Рахимахон",
         "MiddleName":"Хасанбаевна",
         "Address":"Ошская область Араванский район",
         "Telephone":"0551668884\r\n0556900007"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"12007195400742",
         "LastName":"Уржанова",
         "FirstName":"Салкынбубу",
         "MiddleName":"Асаналиевна",
         "Telephone":""
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"10104198200994",
         "LastName":"Валуйская ",
         "FirstName":"Мария",
         "MiddleName":"Владимировна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0 705 47 98 34, 0553 47 98 34"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2120419910092*",
         "LastName":"Borubaev",
         "FirstName":"Zhakypbek",
         "MiddleName":"Zharkynbaevich",
         "Address":"Ысык-Кульская область Тонский район",
         "Telephone":""
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"11804196601058",
         "LastName":"Жунусова",
         "FirstName":"Марта",
         "MiddleName":"Зияевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0 555 128 158"
      },
      {
         "OrgId":"d328865b-ede4-4674-92c6-c134f55c3c6f",
         "PIN":"21404198501078",
         "LastName":"Ашуров",
         "FirstName":"Рысбек",
         "MiddleName":"Муроталиевич",
         "Address":"Баткенская область г. Баткен",
         "Telephone":"0771 07-30-56"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"10603197101039",
         "LastName":"Сайбиева",
         "FirstName":"Пасира",
         "MiddleName":"Апсатаровна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0778707865\r\n0772452525"
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"12105198800271",
         "LastName":"Ковалева ",
         "FirstName":"Александра",
         "MiddleName":"Сергеевна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0550089652"
      },
      {
         "OrgId":"668d6957-1f76-49de-b9ad-0f2c635df2dd",
         "PIN":"12808198401412",
         "LastName":"Садыралиева ",
         "FirstName":"Нурзат ",
         "MiddleName":"Асановна ",
         "Address":"Чуйская область Кеминский район",
         "Telephone":"0707536243"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840151*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"b42b4f13-2aa5-43d2-b738-cb02ca647d47",
         "PIN":"12504196700781",
         "LastName":"Наскулова",
         "FirstName":"Зарылкан",
         "MiddleName":"Токтомаматовна ",
         "Address":"Джалал-Абадская область г. Кок-Жангак",
         "Telephone":""
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"21209198301170",
         "LastName":"Омуралиев",
         "FirstName":"Максат",
         "MiddleName":"Кадырбекович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0708633933"
      },
      {
         "OrgId":"e425497a-3c82-424e-9691-71eaad4759d8",
         "PIN":"11203198700085",
         "LastName":"Базарбаева ",
         "FirstName":"Нуриза",
         "MiddleName":"Байышовна",
         "Address":"г. Ош Узгенский район",
         "Telephone":"0771686477"
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"11811197600027",
         "LastName":"Касымбаева ",
         "FirstName":"Гульбарчын",
         "MiddleName":"Шаршеновна ",
         "Telephone":"0707073215"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"22103198001027",
         "LastName":"Саткынбаев ",
         "FirstName":"Ырысбек ",
         "MiddleName":"Назарбекович ",
         "Address":"Чуйская область Аламединский район",
         "Telephone":""
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"10202198200885",
         "LastName":"Белекова",
         "FirstName":"Бакытжан",
         "MiddleName":"Шайлообековна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0555074969, 0705074969"
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"11408199601158",
         "LastName":"Улан кызы",
         "FirstName":"Жанара",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":"0772386040"
      },
      {
         "OrgId":"60f22545-cb84-4795-81a3-2eeeeb0fb88a",
         "PIN":"11912196800581",
         "LastName":"Эшалиева",
         "FirstName":"Бегаим",
         "MiddleName":"Эмильевна",
         "Address":"Джалал-Абадская область Ала-Букинский район",
         "Telephone":"0779 43 45 37"
      },
      {
         "OrgId":"668d6957-1f76-49de-b9ad-0f2c635df2dd",
         "PIN":"21912196300099",
         "LastName":"Султанов ",
         "FirstName":"Савырбек ",
         "MiddleName":"Нургазиевич ",
         "Address":"Чуйская область Чуйский район",
         "Telephone":"0556005552"
      },
      {
         "OrgId":"93df61dd-a2fe-4f52-b961-f1b27bd27a8c",
         "PIN":"20308196700958",
         "LastName":"Балтаев",
         "FirstName":"Шайлообек",
         "MiddleName":"Кудайбергенович",
         "Address":"Нарынская область г. Нарын",
         "Telephone":"0700141429"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"12611197400018",
         "LastName":"Пеннер",
         "FirstName":"Ольга",
         "MiddleName":"Владимировна",
         "Telephone":"0550898232"
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"21502198201374",
         "LastName":"Белеков",
         "FirstName":"Мирлан",
         "MiddleName":"Муктарович",
         "Address":"Ысык-Кульская область Жети-Огузский район",
         "Telephone":"0552078288"
      },
      {
         "OrgId":"c7b6aa81-a4b7-4ad6-be03-e4b54e8084da",
         "PIN":"11607198901156",
         "LastName":"Баротбаева",
         "FirstName":"Муташам",
         "MiddleName":"Исматуллоевна",
         "Address":"Баткенская область Лейлекский район",
         "Telephone":"0779531821"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"12805198000489",
         "LastName":"Донбаева ",
         "FirstName":"Аида",
         "MiddleName":"Тумбаевна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":""
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"23110197810028",
         "LastName":"Осмонов",
         "FirstName":"Уланбек",
         "MiddleName":"Садыгалиевич",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0 709 55 20 88"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"1011019800131*",
         "LastName":"АПСАЛИЕВА",
         "FirstName":"БАРНО",
         "MiddleName":"ШАХАБЖАНОВНА",
         "Address":"Баткенская область Баткенский район",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"12212197400541",
         "LastName":"Бердикулова",
         "FirstName":"Жайнагуль",
         "MiddleName":"Джолдошбековна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"21706196400161",
         "LastName":"Чонмурунов",
         "FirstName":"Асылбек",
         "MiddleName":"Джумаевич",
         "Address":"Чуйская область Аламединский район",
         "Telephone":""
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"10609197700807",
         "LastName":"Алиева ",
         "FirstName":"Зарима ",
         "MiddleName":"Асанбековна ",
         "Address":"г. Ош Аксыйский район",
         "Telephone":""
      },
      {
         "OrgId":"ab27c82d-8348-4dba-ba9c-ceae3a67704e",
         "PIN":"22105196200129",
         "LastName":"Джелденов",
         "FirstName":"Канатбек",
         "MiddleName":"Асешович",
         "Address":"Ысык-Кульская область г. Каракол",
         "Telephone":""
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"10203198300674",
         "LastName":"Темирбек кызы",
         "FirstName":"Назгул",
         "Address":"Чуйская область Аламединский район",
         "Telephone":"0703881219"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"12211197700084",
         "LastName":"Алымкулова ",
         "FirstName":"Айнура ",
         "MiddleName":"Райымбековна",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0552737711"
      },
      {
         "OrgId":"2765827a-88ef-4144-8050-9a2fd66fb136",
         "PIN":"10906197200711",
         "LastName":"Токтосунова ",
         "FirstName":"Арзыкан",
         "MiddleName":"Жоробаевна",
         "Address":"Джалал-Абадская область Аксыйский район",
         "Telephone":"0772563475"
      },
      {
         "OrgId":"64ecd259-a848-474b-8e8d-571612a7e43b",
         "PIN":"22806197300622",
         "LastName":"Батыркожоев",
         "FirstName":"Тыныбек",
         "MiddleName":"Машович",
         "Address":"Джалал-Абадская область Токтогульский район",
         "Telephone":""
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"20412197101012",
         "LastName":"Толубаев",
         "FirstName":"Руслан",
         "MiddleName":"Кагановичевич",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0705027064, 0707027064"
      },
      {
         "OrgId":"59ff6f9c-f047-4e25-b837-669aa0596b07",
         "PIN":"10209196200352",
         "LastName":"Пазылова",
         "FirstName":"Чынара",
         "MiddleName":"Адаевна",
         "Address":"Ошская область Кара-Сууский район",
         "Telephone":"0550082633"
      },
      {
         "OrgId":"ff3b071c-fc51-4f64-8bd4-11a5377e93d2",
         "PIN":"10504197800616",
         "LastName":"Худайназарова",
         "FirstName":"Гулкайыр",
         "MiddleName":"Рустамовна",
         "Telephone":"0770304909"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"21509198101223",
         "LastName":"Самаркулов ",
         "FirstName":"Нурбек",
         "MiddleName":"Дуйшенбекович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0555010681"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"20211198201318",
         "LastName":"Шаршембиев",
         "FirstName":"Сыныбек",
         "MiddleName":"Кусейинович",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0772 72 72 10"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"11201197300947",
         "LastName":"Джунушева",
         "FirstName":"Бермет",
         "MiddleName":"Омурбековна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0708160246"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"10905197300586",
         "LastName":"Момбекова",
         "FirstName":"Салтанат",
         "MiddleName":"Аскарбековна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0772249529\r\n0555700785"
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"20705198600117",
         "LastName":"Сатылбалдиев",
         "FirstName":"Даниэл",
         "MiddleName":"Анарбекович",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"12103196800974",
         "LastName":"Осетрова",
         "FirstName":"Лариса",
         "MiddleName":"Александровна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":"0559500699"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"12308197400107",
         "LastName":"Мухаметсалиева",
         "FirstName":"Жылдыз",
         "MiddleName":"Булатовна",
         "Telephone":"0550 32 20 74 ,   0700 99 02 43"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"11701196700059",
         "LastName":"Тюлюндиева",
         "FirstName":"Назира ",
         "MiddleName":"Манатовна",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"20109198200851",
         "LastName":"Эшимбеков",
         "FirstName":"Алтынбек",
         "MiddleName":"Курванбекович",
         "Address":"Ошская область Узгенский район",
         "Telephone":"0701746777"
      },
      {
         "OrgId":"7866d332-c471-4873-8f8b-6da9edc3683a",
         "PIN":"12908199101290",
         "LastName":"Олейникова",
         "FirstName":"Елена",
         "MiddleName":"Анатольевна",
         "Address":"Чуйская область Ыссык-Атинский район",
         "Telephone":"0554 226016,  0553 840439"
      },
      {
         "OrgId":"ff3b071c-fc51-4f64-8bd4-11a5377e93d2",
         "PIN":"11702197010060",
         "LastName":"Карабаева",
         "FirstName":"Гульнара",
         "MiddleName":"Джумабаева",
         "Telephone":"0778523014"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840120*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"19f7870d-4b5e-457a-b159-528f71374e1e",
         "PIN":"10101195700434",
         "LastName":"Борбиева ",
         "FirstName":"Суюнбубю",
         "Address":"Чуйская область Жайылский район",
         "Telephone":"0703 866096"
      },
      {
         "OrgId":"8b6510f7-3b6b-4c9d-b8a1-2349b78b2cf3",
         "PIN":"12203197501034",
         "LastName":"Байдылдаева",
         "FirstName":"Гульмира",
         "MiddleName":"Сатарбековна",
         "Address":"Чуйская область Чуйский район",
         "Telephone":"0709314020"
      },
      {
         "OrgId":"7f311898-d254-4668-8184-d1794e695a19",
         "PIN":"12010196000988",
         "LastName":"Шабралиева ",
         "FirstName":"Мырсагыз ",
         "MiddleName":"Чымырбаевна",
         "Address":"Таласская область Бакай-Атинский район",
         "Telephone":"0701575910"
      },
      {
         "OrgId":"52315837-1b8f-4b80-9d8c-160214f4681a",
         "PIN":"11611196700288",
         "LastName":"Алымкулова ",
         "FirstName":"Асыл ",
         "MiddleName":"Олжобаевна",
         "Address":"Чуйская область Свердловский",
         "Telephone":""
      },
      {
         "OrgId":"64ecd259-a848-474b-8e8d-571612a7e43b",
         "PIN":"12709198600422",
         "LastName":"Аманова",
         "FirstName":"Рахат",
         "MiddleName":"Майрамбековна",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"5312196173",
         "LastName":"Штоль ",
         "FirstName":"Геннадий",
         "MiddleName":"Александрович",
         "Address":"г.Бишкек Свердловский",
         "Telephone":"0550898804\r\n0551404743"
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"21002197000258",
         "LastName":"Бейшеев ",
         "FirstName":"Шайлообек",
         "MiddleName":"Турдакунович",
         "Address":"Ысык-Кульская область Жети-Огузский район",
         "Telephone":"773370354"
      },
      {
         "OrgId":"dc2174b4-479b-4f6f-9a9d-9ba28ab715cd",
         "PIN":"10203198300674",
         "LastName":"Темирбек кызы",
         "FirstName":"Нургул",
         "Address":"Чуйская область Аламединский район",
         "Telephone":""
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"12904198000539",
         "LastName":"Бийгельдиева",
         "FirstName":"Назгуль",
         "MiddleName":"Курманбековна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0556 63 35 07"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2111219840121*",
         "LastName":"Тестк",
         "FirstName":"Test",
         "MiddleName":"Test",
         "Address":"г. Ош г. Ош",
         "Telephone":""
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"10104197300113",
         "LastName":"Бейшекеева ",
         "FirstName":"Назира",
         "MiddleName":"Кубанычбековна",
         "Address":"Ысык-Кульская область Жети-Огузский район",
         "Telephone":"0772826592"
      },
      {
         "OrgId":"1f892b98-97b4-4b58-b61f-7dfecbac3b5e",
         "PIN":"21104196700766",
         "LastName":"Мукамбетов ",
         "FirstName":"Большевик ",
         "MiddleName":"Байбосунович ",
         "Address":"Ысык-Кульская область Ак-Суйский район",
         "Telephone":"0709 91 29 18"
      },
      {
         "OrgId":"e5411cdd-3caf-4798-a7bf-fd57c856c82b",
         "PIN":"21806198301285",
         "LastName":"Туйбаев",
         "FirstName":"Нурбек",
         "MiddleName":"Масабирович",
         "Address":"г. Ош Алайский район",
         "Telephone":""
      },
      {
         "OrgId":"7f311898-d254-4668-8184-d1794e695a19",
         "PIN":"12410197100338",
         "LastName":"Шамурзинова ",
         "FirstName":"Сайкал ",
         "MiddleName":"Аликовна ",
         "Address":"Таласская область Бакай-Атинский район",
         "Telephone":"0707308607"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"2051219920161*",
         "LastName":"АЛЫМБАЕВ",
         "FirstName":"НУРДИН",
         "MiddleName":"НУРЛАНОВИЧ",
         "Address":"г.Бишкек Ленинский",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"10408197200313",
         "LastName":"Төлөнбаева",
         "FirstName":"Рахат",
         "MiddleName":"Табалдиевна",
         "Address":"Ысык-Кульская область Тонский район",
         "Telephone":"0705393282\r\n0771497088\r\n0702221319"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"10905197700630",
         "LastName":"Керимкулова",
         "FirstName":"Чинара",
         "MiddleName":"Шамшиеввна",
         "Address":"г.Бишкек Ленинский",
         "Telephone":"0702118077"
      },
      {
         "OrgId":"aa65c2f7-3091-40e5-939d-6a18250e125a",
         "PIN":"10405198400919",
         "LastName":"Борбуева",
         "FirstName":"Гульмира",
         "MiddleName":"Камилжановна",
         "Address":"Джалал-Абадская область г. Кара-Куль",
         "Telephone":""
      },
      {
         "OrgId":"18dbe2be-bbb6-4bc2-9a33-8b25856c0382",
         "PIN":"11106197000513",
         "LastName":"Орозбаева ",
         "FirstName":"Гулжамал",
         "MiddleName":"Каримовна ",
         "Address":"Джалал-Абадская область Ала-Букинский район",
         "Telephone":""
      },
      {
         "OrgId":"20ff5245-db58-443a-a31b-061200e939aa",
         "PIN":"11308197900725",
         "LastName":"Кочорова",
         "FirstName":"Эльнура",
         "MiddleName":"Эсенгелдиевна",
         "Address":"г.Бишкек Октябрьский",
         "Telephone":"0558979098"
      },
      {
         "OrgId":"e425497a-3c82-424e-9691-71eaad4759d8",
         "PIN":"11610198101099",
         "LastName":"Карабекова ",
         "FirstName":"Аксана",
         "MiddleName":"Базаралиевна",
         "Address":"г. Ош Узгенский район",
         "Telephone":""
      },
      {
         "OrgId":"8b6510f7-3b6b-4c9d-b8a1-2349b78b2cf3",
         "PIN":"22407198900064",
         "LastName":"Ашимов ",
         "FirstName":"Руслан",
         "MiddleName":"Камалович",
         "Address":"Чуйская область Чуйский район",
         "Telephone":"0554418118"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"21702198300563",
         "LastName":"Айтназаров",
         "FirstName":"кубат",
         "MiddleName":"Токторбаевич",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0705776366; 0703059191"
      },
      {
         "OrgId":"e4964796-6658-46a9-bd49-a34b3275cb1f",
         "PIN":"11608197410027",
         "LastName":"Калыгулова",
         "FirstName":"Джумагул",
         "MiddleName":"Акимбаевна",
         "Address":"Джалал-Абадская область Ноокенский район",
         "Telephone":"0772777466"
      },
      {
         "OrgId":"14eb7aef-c331-4085-a83e-0f5eebf2eaa3",
         "PIN":"11208196500804",
         "LastName":"Абдрахманова ",
         "FirstName":"Кулмира",
         "MiddleName":"Муташовна",
         "Address":"Ысык-Кульская область Ысык-Кульский район",
         "Telephone":"0707438307"
      },
      {
         "OrgId":"e425497a-3c82-424e-9691-71eaad4759d8",
         "PIN":"11301198200760",
         "LastName":"Абдукадирова",
         "FirstName":"Бактыгул",
         "MiddleName":"Тожидиновна",
         "Address":"Ошская область Узгенский район",
         "Telephone":"0772182010"
      },
      {
         "OrgId":"d4c5884f-1820-4df5-9bf8-c48202acb801",
         "PIN":"12102197700640",
         "LastName":"Жунушбаева",
         "FirstName":"Назгуль",
         "MiddleName":"Джанузаковна",
         "Address":"г.Бишкек Первомайский",
         "Telephone":""
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"12502197700058",
         "LastName":"Кадацкая",
         "FirstName":"Ольга",
         "MiddleName":"Викторовна",
         "Address":"г.Бишкек Чуйский район",
         "Telephone":"0701709030"
      },
      {
         "OrgId":"96c5e2c1-d1d0-4470-a209-3fc7123632a5",
         "PIN":"1300919740031*",
         "LastName":"КАСЫМАЛИЕВА",
         "FirstName":"АЙНУРА",
         "MiddleName":"СТАМОВНА",
         "Address":"г.Бишкек Ленинский",
         "Telephone":""
      },
      {
         "OrgId":"a2317371-cc1d-4eb3-8adf-d7ba33b75f87",
         "PIN":"21202197700504",
         "LastName":"Мамасалиев",
         "FirstName":"Талант",
         "MiddleName":"Эгембердиевич",
         "Address":"Ошская область Кара-Кулжинский район",
         "Telephone":"0777211110"
      },
      {
         "OrgId":"e3bfb034-7638-4c14-8c0f-ea823cca7a5b",
         "PIN":"21905198100869",
         "LastName":"Нигматов",
         "FirstName":"Эрмек",
         "MiddleName":"Абдулазисович",
         "Telephone":"0771406593"
      },
      {
         "OrgId":"093afb08-3f18-4e5c-8eb6-86ef0dd711b9",
         "PIN":"22301198400973",
         "LastName":"Рысалиев",
         "FirstName":"Замирбек",
         "MiddleName":"Каныбекович",
         "Address":"Чуйская область Сокулукский район",
         "Telephone":"0777 479 477 (З.К.), 0556 490 590 (М.М.)"
      },
      {
         "OrgId":"64ecd259-a848-474b-8e8d-571612a7e43b",
         "PIN":"10811197900438",
         "LastName":"Мамбетова",
         "FirstName":"Акылай",
         "MiddleName":"Жолдошалиевна",
         "Address":"Джалал-Абадская область Токтогульский район",
         "Telephone":"0703 187175"
      },
      {
         "OrgId":"2765827a-88ef-4144-8050-9a2fd66fb136",
         "PIN":"12812198800193",
         "LastName":"Алишерова",
         "FirstName":"Асылгүл",
         "MiddleName":"Эшенкуловна",
         "Address":"Джалал-Абадская область Аксыйский район",
         "Telephone":"0707370661"
      },
      {
         "OrgId":"046ff80d-4d26-4e30-a41f-17873c0e2c8f",
         "PIN":"12904197010067",
         "LastName":"Чыманова",
         "FirstName":"Мира",
         "MiddleName":"Абдрахманова",
         "Address":"Ысык-Кульская область Жети-Огузский район",
         "Telephone":"703389461"
      }
   ])
  },[])
  async function buttonClick(name, candidateRequest){
    if(name === "operators"){
      console.log("Button", name, operators)
      for(let i in operators){
        let KeycloakDoc = {
          username: operators[i].Text,
          firstName: operators[i].Text,
          lastName: "уср",
          email: operators[i].Id,
          emailVerified: true,
          enabled: true,
          requiredActions: ["UPDATE_PASSWORD"],
          attributes: 
            {
              locale: "ru",
              userRole:  "operator",
              OrgId:  operators[i].Id,
              address: "адрес",
              phone: "123123123",
              expired: "2021-05-10"
            },
          credentials: [
            {
              type: "password",
              value: "12345"
            }
          ]
        }
        await request( 
          {
            headers: {
              "Authorization": "Bearer "+ keycloak.token,
              "Content-Type": "application/json"
            },
            url: "http://localhost:9190/auth/admin/realms/PortalSA/users",
            method: "POST",
            body: JSON.stringify(KeycloakDoc)
          }
        )
        .then (async function (response){
          await request(
            {
              headers: {
                "Authorization": "Bearer "+ keycloak.token,
                "Content-Type": "application/x-www-form-urlencoded"
              },
              url: "http://localhost:9190/auth/admin/realms/PortalSA/users",
              method: "GET"
            }
          )
          .then (function (response){
            var parsed = JSON.parse(response)
            for(let i=0; i<parsed.length; i++){
              if(operators[i].Text === parsed[i].username){
                request(
                  {
                    "headers": { "content-type": "application/json" },
                    "url": "http://192.168.0.111/ChildrenAdoptionApi/api/PortalUser/SetUser?userId="+parsed[i].id+"&orgId="+parsed[i].attributes.OrgId[0]+"&userName="+parsed[i].username,
                    "method": "GET"
                  }
                )
                .then (function (response){
                  console.log("USER CREATED: ", operators[i].Text)
                })
                .catch(function (error){
                  return console.log("Request error: ", error)
                })
                break
              }
            }
          })
          .catch(function (error){
            return console.log("Request error: ", error)
          }) 
        })
        .catch(function (error){
          return console.log("Request error: ", error)
        })
      }
    }
    if(name === "candidates"){
      console.log("Button", name, candidates)
      var candidatesList = []
      var successfuluCreated = 0
      for(let i in candidates){
        var canBeCreated = false
        if(candidates[i].Address !== undefined){
         let KeycloakDoc = {
            username: candidates[i].PIN,
            firstName: candidates[i].FirstName,
            lastName: candidates[i].LastName,
            email: candidates[i].PIN,
            emailVerified: true,
            enabled: true,
            requiredActions: ["UPDATE_PASSWORD"],
            attributes: 
               {
               locale: "ru",
               userRole:  "candidate",
               OrgId:  candidates[i].OrgId,
               address: candidates[i].Address,
               phone: candidates[i].Telephone,
               expired: "2021-05-10"
               },
            credentials: [
               {
               type: "password",
               value: "12345"
               }
            ]
         }
         await request(
            {
               "headers": { "content-type": "application/json" },
               "url": "http://192.168.0.111/ChildrenAdoptionApi/api/PortalUser/CanBeCreated?pin="+candidates[i].PIN,
               "method": "GET"
            }
         )
         .then (function (response){
            console.log("RESP: ", JSON.parse(response))
            if(JSON.parse(response) === true){
               canBeCreated = true
               // successfuluCreated += 1
            }
         })
         .catch(function (error){
            return console.log("Request error: ", error)
         })
         if(canBeCreated === true){
            await request( 
               {
               headers: {
                  "Authorization": "Bearer "+ keycloak.token,
                  "Content-Type": "application/json"
               },
               url: "http://localhost:9190/auth/admin/realms/PortalSA/users",
               method: "POST",
               body: JSON.stringify(KeycloakDoc)
               }
            )
            .then (async function (response){
               await request(
               {
                  headers: {
                     "Authorization": "Bearer "+ keycloak.token,
                     "Content-Type": "application/x-www-form-urlencoded"
                  },
                  url: "http://localhost:9190/auth/admin/realms/PortalSA/users",
                  method: "GET"
               }
               )
               .then (function (response){
               var parsed = JSON.parse(response)
               for(let i=0; i<parsed.length; i++){
                  if(candidates[i].PIN === parsed[i].username){
                     request(
                     {
                        "headers": { "content-type": "application/json" },
                        "url": "http://192.168.0.111/ChildrenAdoptionApi/api/PortalUser/SetUser?userId="+parsed[i].id+"&orgId="+parsed[i].attributes.OrgId[0]+"&userName="+parsed[i].username,
                        "method": "GET"
                     }
                     )
                     .then (function (response){
                     console.log("USER CREATED: ", candidates[i].PIN)
                     successfuluCreated +=1
                     candidatesList.push({
                        username: candidates[i].PIN,
                        firstName: candidates[i].FirstName,
                        lastName: candidates[i].LastName,
                        address: candidates[i].Address,
                        phone: candidates[i].Telephone
                     })
                     
                     })
                     .catch(function (error){
                     return console.log("Request error: ", error)
                     })
                     break
                  }
               }
               })
               .catch(function (error){
               return console.log("Request error: ", error)
               }) 
            })
            .catch(function (error){
               return console.log("Request error: ", error)
            })
         }
        }
      }
      console.log("CAND_S", JSON.stringify(candidatesList))

    }
    else{
      console.log("Button", name)
    }
  }
  if(operators !== null){ 
    return(
      <div>
        {/* <Button
          variant="outlined"                              
          onClick = {() => buttonClick("operators", null)}
          style={{
              margin: 1,
              fontSize: 11,
              backgroundColor: "#D1FBD1"
          }}
        >OPERATORS
        </Button>
        <Button
        variant="outlined"                              
        onClick = {() => buttonClick("candidates", null)}
        style={{
            margin: 1,
            fontSize: 11,
            backgroundColor: "#D1FBD1"
        }}
      >CNDIDATES
      </Button> */}
    </div>
    )
  }

  else{
    return(
      <div align="center" style={{paddingTop: 250}}>
        <CircularProgress />
      </div>
    )
  }
}
