import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";

var request = require("request-promise");
const styles = (theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.secondary.light,
    overflow: "hidden",
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

function AdoptedChildrenReport(props) {
  const [portalAPI] = useState(props.portalAPI)
  const [keycloakAPI] = useState(props.keycloakAPI)
  const { classes } = props;
  const [report, setReport] = useState(null);
  var adults = 0
  useEffect(() => {
    fetchReport();
  }, []);
  async function fetchReport() {
    await request({
      headers: { "content-type": "application/json" },
      url:
        portalAPI + "/ChildrenAdoptionApi/api/AdoptedChildrenReport/Get",
      method: "GET",
    })
      .then(function (response) {
        let parsedData = JSON.parse(response);
        // console.log("REPORT", parsedData);
        setReport(parsedData);
      })
      .catch(function (error) {
        return console.log("Request error: ", error);
      });
  }
  function getChildrenByAge(rep) {
    // console.log("Rep", rep[1].substring(0, 2))
    let chList = []
    let ad = 0
    for (let i = 0; i < rep.length; i++) {
      let age = parseInt(rep[i].Name.substring(0, 2))
      if (age < 18) {
        chList.push(
          <TableRow>
            <TableCell>{rep[i].No}</TableCell>
            <TableCell>{rep[i].Name}</TableCell>
            <TableCell>{rep[i].Boys}</TableCell>
            <TableCell>{rep[i].Girls}</TableCell>
            <TableCell>{rep[i].Total}</TableCell>
          </TableRow>
        )
      }
      else { adults += 1 }
    }
    // console.log("ADULTS", adults)
    return chList
  }
  if (report !== null) {
    return (
      <section className={classes.root}>
        <Container className={classes.container}>
          <Grid item xs={12}>
            <Table size="small">
              <TableHead style={{ backgroundColor: "#282828" }}>
                <TableRow>
                  <TableCell
                    style={{ fontWeight: "bold", color: "white", fontSize: 16 }}
                  >
                    Отчетные данные по государственному банку данных о детях
                    оставшихся без попечения родителей
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ fontWeight: "bold", backgroundColor: "#D1FBD1" }}
                  >
                    №
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", backgroundColor: "#D1FBD1" }}
                  >
                    Наименование
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", backgroundColor: "#D1FBD1" }}
                  >
                    Мальчики
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", backgroundColor: "#D1FBD1" }}
                  >
                    Девочки
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", backgroundColor: "#D1FBD1" }}
                  >
                    Итого
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell style={{ backgroundColor: "#D7E3DD" }}></TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", backgroundColor: "#D7E3DD" }}
                  >
                    По возрасту:
                  </TableCell>
                  <TableCell style={{ backgroundColor: "#D7E3DD" }}></TableCell>
                  <TableCell style={{ backgroundColor: "#D7E3DD" }}></TableCell>
                  <TableCell style={{ backgroundColor: "#D7E3DD" }}></TableCell>
                </TableRow>
                {getChildrenByAge(report.ByAge)}
                {/* {report.ByAge.map(
                  (rep, index) =>
                    rep.Name !== "18 лет" &&
                    rep.Name !== "19 лет" &&
                    rep.Name !== "20 лет" &&
                    rep.Name !== "21 лет" && (
                      <TableRow>
                        <TableCell>{rep.No}</TableCell>
                        <TableCell>{rep.Name}</TableCell>
                        <TableCell>{rep.Boys}</TableCell>
                        <TableCell>{rep.Girls}</TableCell>
                        <TableCell>{rep.Total}</TableCell>
                      </TableRow>
                    )
                )} */}
                <TableRow>
                  <TableCell style={{ backgroundColor: "#D7E3DD" }}></TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", backgroundColor: "#D7E3DD" }}
                  >
                    По национальности:
                  </TableCell>
                  <TableCell style={{ backgroundColor: "#D7E3DD" }}></TableCell>
                  <TableCell style={{ backgroundColor: "#D7E3DD" }}></TableCell>
                  <TableCell style={{ backgroundColor: "#D7E3DD" }}></TableCell>
                </TableRow>
                {report.ByNationalities.map((rep, index) => (
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{rep.Name}</TableCell>
                    <TableCell>{rep.Boys}</TableCell>
                    <TableCell>{rep.Girls}</TableCell>
                    <TableCell>{rep.Total}</TableCell>
                  </TableRow>
                ))}
                {/* <TableRow>
                  <TableCell style={{backgroundColor: "#D7E3DD"}}></TableCell>
                  <TableCell style={{fontWeight:"bold", backgroundColor: "#D7E3DD"}}>По месту нахождения:</TableCell>
                  <TableCell style={{backgroundColor: "#D7E3DD"}}></TableCell>
                  <TableCell style={{backgroundColor: "#D7E3DD"}}></TableCell>
                  <TableCell style={{backgroundColor: "#D7E3DD"}}></TableCell>
                </TableRow>
                {report.ByGeography.map((rep, index) =>(
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{rep.Name}</TableCell>
                    <TableCell>{rep.Boys}</TableCell>
                    <TableCell>{rep.Girls}</TableCell>
                    <TableCell>{rep.Total}</TableCell>
                  </TableRow>
                ))} */}
                <TableRow>
                  <TableCell style={{ backgroundColor: "#D7E3DD" }}></TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", backgroundColor: "#D7E3DD" }}
                  >
                    Всего
                  </TableCell>
                  <TableCell style={{ backgroundColor: "#D7E3DD" }}></TableCell>
                  <TableCell style={{ backgroundColor: "#D7E3DD" }}></TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", backgroundColor: "#D7E3DD" }}
                  >
                    {report.Total - adults}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Container>
      </section>
    );
  } else {
    return (
      <div align="center" style={{ paddingTop: 250 }}>
        <CircularProgress />
      </div>
    );
  }
}

AdoptedChildrenReport.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdoptedChildrenReport);
