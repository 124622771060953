import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MTable from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import Select from 'react-select';
import Paper from '@material-ui/core/Paper';
import { Table } from 'reactstrap';
import { useKeycloak } from '../lib';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from 'sweetalert' // https://sweetalert.js.org/guides/
import SearchUserForm from './modules/forms/searchUserForm.json';
import ViewUserForm from './modules/forms/viewUserForm.json';
import SearchUserFormOperator from './modules/forms/searchUserFormOperator.json';
import UserGridForm from './modules/forms/userGridForm.json';
import EnumData from '../EnumData.json';
import Buttons from '../Buttons.json';
import Switch from '@material-ui/core/Switch';
import CandidateMeetUpRequests from './CandidateMeetUpRequests';
var request = require("request-promise")
const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  }
}))

const useStylesnav = makeStyles(theme => ({
  select: {
    maxWidth: 250
  }
}))

export default (props) => {
  // Пользователи системы
  const { keycloak } = useKeycloak()
  const [portalAPI] = useState(props.portalAPI)
  const [keycloakAPI] = useState(props.keycloakAPI)
  const [userRole, setUserRole] = useState(null)
  const [userOrgId, setUserOrgId] = useState(null)
  const [Form, setForm] = useState(null)
  const [docList, setDocList] = useState(null)
  const [gridFormData, setGridFormData] = useState(null)
  const [gridForm] = useState(UserGridForm)
  const [gridFormButtons, setGridFormButtons] = useState(null)
  const [enumData, setEnumData] = useState(null)
  const [fieldValue, setFieldValue] = useState({})
  const [selectedOptions, setSelectedOptions] = useState([])
  const [buttons, setButtons] = useState(null)
  const [userRequests, setUserRequests] = useState(null)
  const [userMeetUpRequests, setUserMeetUpRequests] = useState(null)
  const [childId, setChildId] = useState(null)
  const [candidateId, setCandidateId] = useState(null)
  const [task, setTask] = useState(null)
  const [selectedUserId, setSelectedUserId] = useState(null)
  const [selectedUserOrgId, setSelectedUserOrgId] = useState(null)

  const classes = useStyles()
  const classesnav = useStylesnav()
  useEffect(() => {
    if (props.taskType === "viewUsers") {
      // console.log("ROLE", props.userRole)
      setTask(props.taskType)
      setUserOrgId(props.userOrgId)
      setFieldValue({ enabled: true, OrgId: props.userOrgId, userRole: "candidate" })
      let uRole = props.userRole
      setUserRole(uRole)
      fetchUsers()
      setForm(SearchUserForm)
      setButtons(Buttons[uRole].SearchUserButtons)
      setGridFormButtons(Buttons[uRole].UserGridFormButtons)
      setEnumData(EnumData, props.userOrgId)
      // console.log("ENUMS", EnumData, props.userOrgId)
      updateSOP(props.userOrgId, "candidate")
    }
  }, [])
  function updateSOP(orgId, role) {
    let updatedSOP = []
    for (let i = 0; i < EnumData.length; i++) {
      for (let l = 0; l < EnumData[i].data.length; l++) {
        if (EnumData[i].data[l].Id === orgId) {
          // console.log("DIST", EnumData[i].data[l].Id, props.userOrgId)
          updatedSOP.push({ name: "OrgId", value: EnumData[i].data[l].Id, label: EnumData[i].data[l].Text })
        }
        else if (EnumData[i].data[l].Id === role) {
          updatedSOP.push({ name: "userRole", value: EnumData[i].data[l].Id, label: EnumData[i].data[l].Text })
        }
      }
    }
    setSelectedOptions(updatedSOP)
  }
  function swalert(text, icon, swalButtons) {
    return (
      swal({
        text: text,
        icon: icon,
        buttons: swalButtons
      })
        .then((click) => {
          if (click === "yes") {
            console.log("CLICK", click)
          } else {
            console.log("CLICK", click)
          }
        })
    )
  }
  function fetchUsers() {
    request(
      {
        headers: {
          "Authorization": "Bearer " + keycloak.token,
          "Content-Type": "application/json"
        },
        url: keycloakAPI + "/auth/admin/realms/PortalSA/users/?max=20000",
        method: "GET"
      }
    )
      .then(function (response) {
        // console.log("RESPONSE", JSON.parse(response))
        console.log("USERDIST", props.userOrgId, "ROLE", props.userRole)
        var parsed = JSON.parse(response)
        var newDocList = []
        for (let i = 0; i < parsed.length; i++) {
          if (props.userRole === "admin") {
            let user = {
              id: parsed[i].id,
              enabled: parsed[i].enabled,
              username: parsed[i].username,
              firstName: parsed[i].firstName,
              lastName: parsed[i].lastName,
              email: parsed[i].email,
              OrgId: parsed[i].attributes.OrgId[0],
              expired: parsed[i].attributes.expired[0],
              userRole: parsed[i].attributes.userRole[0],
              address: parsed[i].attributes.address[0],
              phone: parsed[i].attributes.phone[0]
            }
            newDocList.push(user)
          }
          else {
            if (props.userOrgId === parsed[i].attributes.OrgId[0]) {
              let user = {
                id: parsed[i].id,
                enabled: parsed[i].enabled,
                username: parsed[i].username,
                firstName: parsed[i].firstName,
                lastName: parsed[i].lastName,
                email: parsed[i].email,
                OrgId: parsed[i].attributes.OrgId[0],
                expired: parsed[i].attributes.expired[0],
                userRole: parsed[i].attributes.userRole[0],
                address: parsed[i].attributes.address[0],
                phone: parsed[i].attributes.phone[0]
              }
              newDocList.push(user)
            }
          }
        }
        setDocList(newDocList)
        setGridFormData(parsed)
      })
      .catch(function (error) {
        return console.log("Request error: ", error)
      })
  }
  function getUUID() {
    const uuidv1 = require("uuid/v1")
    return uuidv1()
  }
  function handleChange(event) {
    // console.log("EVENT", event.target.name, event.target.value)
    fieldValue[event.target.name] = event.target.value
    setFieldValue(fieldValue)
    console.log("FIELDVALUE", fieldValue)
  }
  function handleSelectChange(event) {
    fieldValue[event.name] = event.value
    setFieldValue(fieldValue)
    let updateSelectedOptions = selectedOptions.slice()
    let noSuchOption = true
    for (let i = 0; i < updateSelectedOptions.length; i++) {
      if (event.name === updateSelectedOptions[i].name) {
        updateSelectedOptions[i] = event
        setSelectedOptions(updateSelectedOptions)
        // console.log("SO", selectedOptions)
        noSuchOption = false
        break
      }
      else {
        noSuchOption = true
      }
    }
    if (noSuchOption === true) {
      updateSelectedOptions.push(event)
      setSelectedOptions(updateSelectedOptions)
      console.log("SO", selectedOptions)
    }
  }
  const handSwitchChange = (event) => {
    setFieldValue({ ...fieldValue, [event.target.name]: event.target.checked });
  }
  function getFieldValuesKeycloakCreate() {
    let data = {
      username: fieldValue["username"],
      firstName: fieldValue["firstName"],
      lastName: fieldValue["lastName"],
      email: fieldValue["email"] !== undefined ? fieldValue["email"] : "",
      emailVerified: true,
      enabled: fieldValue["enabled"],
      requiredActions: ["UPDATE_PASSWORD"],
      attributes:
      {
        locale: "ru",
        userRole: fieldValue["userRole"],
        OrgId: fieldValue["OrgId"],
        address: fieldValue["address"] !== undefined ? fieldValue["address"] : "",
        phone: fieldValue["phone"] !== undefined ? fieldValue["phone"] : "",
        expired: fieldValue["expired"]
      },
      credentials: [
        {
          type: "password",
          value: "12345"
        }
      ],
      // realmRoles: ["admin"]
      // clientRoles: {
      //   "realm-management": [
      //     "manage-users",
      //     "view-users"
      //   ] 
      // }
    }
    return data
  }
  function getFieldValuesKeycloakUpdate() {
    let data = {
      firstName: fieldValue["firstName"],
      lastName: fieldValue["lastName"],
      email: fieldValue["email"],
      emailVerified: true,
      enabled: fieldValue["enabled"],
      attributes: {
        OrgId: fieldValue["OrgId"],
        locale: "ru",
        userRole: fieldValue["userRole"],
        address: fieldValue["address"],
        phone: fieldValue["phone"],
        expired: fieldValue["expired"]
      }
    }
    return data
  }
  function getFieldValuesKeycloakResetPassword() {
    let data = {
      firstName: fieldValue["firstName"],
      lastName: fieldValue["lastName"],
      email: fieldValue["email"],
      emailVerified: true,
      enabled: fieldValue["enabled"],
      requiredActions: ["UPDATE_PASSWORD"],
      attributes:
      {
        OrgId: fieldValue["OrgId"],
        locale: "ru",
        userRole: fieldValue["userRole"],
        address: fieldValue["address"],
        phone: fieldValue["phone"],
        expired: fieldValue["expired"]
      },
      credentials: [
        {
          type: "password",
          value: "12345"
        }
      ]
    }
    return data
  }
  // async function fetchAllUserRequests(userId){
  //   await request(
  //     {
  //       "headers": { "content-type": "application/json" },
  //       "url": portalAPI + "/ChildrenAdoptionApi/api/MeetupRequests/GetByUserId?userId=" + userId,
  //       "method": "GET"
  //     }
  //   )
  //   .then (function (response){
  //     let parsedData = JSON.parse(response)
  //     console.log("MEETUP", parsedData)
  //     // setUserMeetUpRequests(parsedData)
  //   })
  //   .catch(function (error){
  //     return console.log("Request error: ", error)
  //   })
  // }
  function getUserStatus() {
    props.getUserStatus()
  }
  async function buttonClick(name, item) {
    console.log("Button: ", name, item)
    if (name === "createUser") {
      setDocList([])
      setButtons(Buttons[userRole].CreateUserButtons)
      updateSOP(userOrgId, "candidate")
      setFieldValue({ enabled: true, OrgId: userOrgId, userRole: "candidate" })
      if (userRole === "admin") {
        setForm(SearchUserForm)
      }
      else {
        setForm(SearchUserFormOperator)
      }
    }
    else if (name === "saveUser") {
      let KeycloakDoc = getFieldValuesKeycloakCreate()
      let enabledToSend = false
      if (fieldValue["OrgId"] !== undefined && fieldValue["OrgId"] !== "") {
        if (fieldValue["userRole"] !== undefined && fieldValue["userRole"] !== "") {
          if (fieldValue["username"] !== undefined && fieldValue["username"] !== "") {
            if (fieldValue["expired"] !== undefined && fieldValue["expired"] !== "") {
              if (fieldValue["firstName"] !== undefined && fieldValue["firstName"] !== "") {
                if (fieldValue["lastName"] !== undefined && fieldValue["lastName"] !== "") {
                  if (fieldValue["email"] !== undefined && fieldValue["email"] !== "") {
                    if (fieldValue["address"] !== undefined && fieldValue["address"] !== "") {
                      if (fieldValue["phone"] !== undefined && fieldValue["phone"] !== "") {
                        for (let i = 0; i < gridFormData.length; i++) {
                          if (gridFormData[i].username === fieldValue["username"]) {
                            enabledToSend = false
                            swalert("Пользователь с таким логином уже существует", "warning")
                            break
                          }
                          else if (gridFormData[i].email === fieldValue["email"]) {
                            enabledToSend = false
                            swalert("Пользователь с таким email уже существует", "warning")
                            break
                          }
                          else enabledToSend = true
                        }
                      } else swalert("Введите Телефон", "warning")
                    } else swalert("Введите Адрес", "warning")
                  } else swalert("Введите Email", "warning")
                } else swalert("Введите Имя", "warning")
              } else swalert("Введите Фамилию", "warning")
            } else swalert("Введите Доступен до", "warning")
          } else swalert("Введите Логин", "warning")
        } else swalert("Введите Роль", "warning")
      } else swalert("Введите Район", "warning")
      if (enabledToSend === true) {
        // console.log('DOC', JSON.stringify(KeycloakDoc), "TOK", keycloak.token)
        var canBeCreated = false
        await request(
          {
            "headers": { "content-type": "application/json" },
            "url": portalAPI + "/ChildrenAdoptionApi/api/PortalUser/CanBeCreated?pin=" + fieldValue["username"] + "&orgId=" + fieldValue["OrgId"],
            "method": "GET"
          }
        )
          .then(function (response) {
            console.log("RESP: ", JSON.parse(response))
            if (JSON.parse(response) === true) {
              canBeCreated = true
            }
          })
          .catch(function (error) {
            return console.log("Request error: ", error)
          })
        if (fieldValue["userRole"] === "candidate") {
          if (canBeCreated === true) {
            await request(
              {
                headers: {
                  "Authorization": "Bearer " + keycloak.token,
                  "Content-Type": "application/json"
                },
                url: keycloakAPI + "/auth/admin/realms/PortalSA/users",
                method: "POST",
                body: JSON.stringify(KeycloakDoc)
              }
            )
              .then(async function (response) {
                // console.log("CREATED", JSON.parse(response))
                await request(
                  {
                    headers: {
                      "Authorization": "Bearer " + keycloak.token,
                      "Content-Type": "application/x-www-form-urlencoded"
                    },
                    url: keycloakAPI + "/auth/admin/realms/PortalSA/users?max=20000",
                    method: "GET"
                  }
                )
                  .then(function (response) {
                    var parsed = JSON.parse(response)
                    for (let i = 0; i < parsed.length; i++) {
                      if (fieldValue["username"] === parsed[i].username) {
                        console.log("NEW USER: ", fieldValue["username"], parsed[i].username, parsed[i].id)
                        request(
                          {
                            "headers": { "content-type": "application/json" },
                            "url": portalAPI + "/ChildrenAdoptionApi/api/PortalUser/SetUser?userId=" + parsed[i].id + "&orgId=" + parsed[i].attributes.OrgId[0] + "&userName=" + parsed[i].username,
                            "method": "GET"
                          }
                        )
                          .then(function (response) {
                            // console.log("USER CREATED: ", JSON.parse(response))
                            setFieldValue({ enabled: true, OrgId: userOrgId, userRole: "candidate" })
                            updateSOP(userOrgId, "candidate")
                            fetchUsers()
                            setButtons(Buttons[userRole].SearchUserButtons)
                            swal({
                              text: "Пользователь успешно создан!",
                              icon: "success"
                            })
                          })
                          .catch(function (error) {
                            return console.log("Request error: ", error)
                          })
                        break
                      }
                    }
                  })
                  .catch(function (error) {
                    return console.log("Request error: ", error)
                  })
              })
              .catch(function (error) {
                return console.log("Request error: ", error)
              })

          }
          else {
            swal({
              text: "Внимание! У данного кандидата не полный пакет документов, проверьте наличие всех необходимых документов!",
              icon: "warning"
            })
          }
        }
        else {
          await request(
            {
              headers: {
                "Authorization": "Bearer " + keycloak.token,
                "Content-Type": "application/json"
              },
              url: keycloakAPI + "/auth/admin/realms/PortalSA/users",
              method: "POST",
              body: JSON.stringify(KeycloakDoc)
            }
          )
            .then(async function (response) {
              // console.log("CREATED", JSON.parse(response))
              await request(
                {
                  headers: {
                    "Authorization": "Bearer " + keycloak.token,
                    "Content-Type": "application/x-www-form-urlencoded"
                  },
                  url: keycloakAPI + "/auth/admin/realms/PortalSA/users?max=20000",
                  method: "GET"
                }
              )
                .then(function (response) {
                  var parsed = JSON.parse(response)
                  for (let i = 0; i < parsed.length; i++) {
                    if (fieldValue["username"] === parsed[i].username) {
                      console.log("NEW USER: ", fieldValue["username"], parsed[i].username, parsed[i].id)
                      request(
                        {
                          "headers": { "content-type": "application/json" },
                          "url": portalAPI + "/ChildrenAdoptionApi/api/PortalUser/SetUser?userId=" + parsed[i].id + "&orgId=" + parsed[i].attributes.OrgId[0] + "&userName=" + parsed[i].username,
                          "method": "GET"
                        }
                      )
                        .then(function (response) {
                          // console.log("USER CREATED: ", JSON.parse(response))
                          setFieldValue({ enabled: true, OrgId: userOrgId, userRole: "candidate" })
                          updateSOP(userOrgId, "candidate")
                          fetchUsers()
                          setButtons(Buttons[userRole].SearchUserButtons)
                          swal({
                            text: "Пользователь успешно создан!",
                            icon: "success"
                          })
                        })
                        .catch(function (error) {
                          return console.log("Request error: ", error)
                        })
                      break
                    }
                  }
                })
                .catch(function (error) {
                  return console.log("Request error: ", error)
                })
            })
            .catch(function (error) {
              return console.log("Request error: ", error)
            })
        }
      }
    }
    else if (name === "findUser") {
      // console.log("GRIDDATA", gridFormData)
      let newDocList = []
      for (let i = 0; i < gridFormData.length; i++) {
        if (userRole === "admin") {
          let user = {
            id: gridFormData[i].id,
            username: gridFormData[i].username,
            enabled: gridFormData[i].enabled,
            firstName: gridFormData[i].firstName,
            lastName: gridFormData[i].lastName,
            email: gridFormData[i].email,
            expired: gridFormData[i].attributes.expired[0],
            OrgId: gridFormData[i].attributes.OrgId[0],
            userRole: gridFormData[i].attributes.userRole[0],
            address: gridFormData[i].attributes.address[0],
            phone: gridFormData[i].attributes.phone[0]
          }
          newDocList.push(user)
        }
        else {
          if (props.userOrgId === gridFormData[i].attributes.OrgId[0]) {
            let user = {
              id: gridFormData[i].id,
              username: gridFormData[i].username,
              enabled: gridFormData[i].enabled,
              firstName: gridFormData[i].firstName,
              lastName: gridFormData[i].lastName,
              email: gridFormData[i].email,
              expired: gridFormData[i].attributes.expired[0],
              OrgId: gridFormData[i].attributes.OrgId[0],
              userRole: gridFormData[i].attributes.userRole[0],
              address: gridFormData[i].attributes.address[0],
              phone: gridFormData[i].attributes.phone[0]
            }
            newDocList.push(user)
          }
        }
      }
      let filteredDocList = []
      for (let i = 0; i < newDocList.length; i++) {
        let matchOrgId = false
        let matchUserRole = false
        let matchUsername = false
        let matchEnabled = false
        let matchExpired = false
        let matchFirstName = false
        let matchLastName = false
        let matchEmail = false
        let matchAddress = false
        let matchPhone = false

        if (fieldValue["OrgId"] !== "" && fieldValue["OrgId"] !== undefined) {
          if (fieldValue["OrgId"] === newDocList[i].OrgId) {
            matchOrgId = true
          } else { matchOrgId = false }
        } else { matchOrgId = true }

        if (fieldValue["userRole"] !== "" && fieldValue["userRole"] !== undefined) {
          if (fieldValue["userRole"] === newDocList[i].userRole) {
            matchUserRole = true
          } else { matchUserRole = false }
        } else { matchUserRole = true }

        if (fieldValue["username"] !== "" && fieldValue["username"] !== undefined) {
          if (fieldValue["username"] === newDocList[i].username) {
            matchUsername = true
          } else { matchUsername = false }
        } else { matchUsername = true }

        if (fieldValue["enabled"] !== "" && fieldValue["enabled"] !== undefined) {
          if (fieldValue["enabled"] === newDocList[i].enabled) {
            matchEnabled = true
          } else { matchEnabled = false }
        } else { matchEnabled = true }

        if (fieldValue["expired"] !== "" && fieldValue["expired"] !== undefined) {
          if (fieldValue["expired"] === newDocList[i].expired) {
            matchExpired = true
          } else { matchExpired = false }
        } else { matchExpired = true }

        if (fieldValue["firstName"] !== "" && fieldValue["firstName"] !== undefined) {
          if (fieldValue["firstName"] === newDocList[i].firstName) {
            matchFirstName = true
          } else { matchFirstName = false }
        } else { matchFirstName = true }

        if (fieldValue["lastName"] !== "" && fieldValue["lastName"] !== undefined) {
          if (fieldValue["lastName"] === newDocList[i].lastName) {
            matchLastName = true
          } else { matchLastName = false }
        } else { matchLastName = true }

        if (fieldValue["email"] !== "" && fieldValue["email"] !== undefined) {
          if (fieldValue["email"] === newDocList[i].email) {
            matchEmail = true
          } else { matchEmail = false }
        } else { matchEmail = true }

        if (fieldValue["address"] !== "" && fieldValue["address"] !== undefined) {
          if (fieldValue["address"] === newDocList[i].address) {
            matchAddress = true
          } else { matchAddress = false }
        } else { matchAddress = true }

        if (fieldValue["phone"] !== "" && fieldValue["phone"] !== undefined) {
          if (fieldValue["phone"] === newDocList[i].phone) {
            matchPhone = true
          } else { matchPhone = false }
        } else { matchPhone = true }

        if (matchOrgId === true && matchUserRole === true && matchUsername === true &&
          matchEnabled === true && matchExpired === true && matchFirstName === true &&
          matchLastName === true && matchEmail === true && matchAddress === true && matchPhone === true) {
          filteredDocList.push(newDocList[i])
        }
      }
      setDocList(filteredDocList)
    }
    else if (name === "editUser") {
      console.log("ITEM", item)
      for (let i = 0; i < docList.length; i++) {
        if (item.id === docList[i].id) {
          setFieldValue({
            id: docList[i].id,
            enabled: docList[i].enabled,
            expired: docList[i].expired,
            username: docList[i].username,
            firstName: docList[i].firstName,
            lastName: docList[i].lastName,
            email: docList[i].email,
            userRole: docList[i].userRole,
            OrgId: docList[i].OrgId,
            address: docList[i].address,
            phone: docList[i].phone
          })
          setForm(ViewUserForm)
          setDocList([])
          setButtons(Buttons[userRole].EditUserButtons)
          updateSOP(docList[i].OrgId, docList[i].userRole)
        }
      }
    }
    else if (name === "updateUser" || name === "resetPassword") {
      if (name === "resetPassword") {
        var userData = getFieldValuesKeycloakResetPassword()
        await request(
          {
            headers: {
              "Authorization": "Bearer " + keycloak.token,
              "Content-Type": "application/json"
            },
            url: keycloakAPI + "/auth/admin/realms/PortalSA/users/" + fieldValue["id"],
            method: "PUT",
            body: JSON.stringify(userData)
          }
        )
          .then(function (response) {
            //console.log("RESPONSE", JSON.parse(response))
            setForm(SearchUserForm)
            swalert("Пароль сброшен", "success")
            setFieldValue({ enabled: true, OrgId: userOrgId, userRole: "candidate" })
            updateSOP(userOrgId, "candidate")
            fetchUsers()
            setButtons(Buttons[userRole].SearchUserButtons)
          })
          .catch(function (error) {
            return console.log("Request error: ", error)
          })

      }
      else {
        var userData = getFieldValuesKeycloakUpdate()
        await request(
          {
            headers: {
              "Authorization": "Bearer " + keycloak.token,
              "Content-Type": "application/json"
            },
            url: keycloakAPI + "/auth/admin/realms/PortalSA/users/" + fieldValue["id"],
            method: "PUT",
            body: JSON.stringify(userData)
          }
        )
          .then(function (response) {
            //console.log("RESPONSE", JSON.parse(response))
            setForm(SearchUserForm)
            swalert("Пользователь обновлен", "success")
            setFieldValue({ enabled: true, OrgId: userOrgId, userRole: "candidate" })
            updateSOP(userOrgId, "candidate")
            fetchUsers()
            setButtons(Buttons[userRole].SearchUserButtons)
          })
          .catch(function (error) {
            return console.log("Request error: ", error)
          })

      }
    }
    else if (name === "deleteUser") {
      swal({
        text: "Вы уверены?",
        icon: "warning",
        buttons: { yes: "Удалить", no: "Отмена" }
      })
        .then(async function (click) {
          if (click === "yes") {
            console.log("CLICK", click)
            await request({
              headers: {
                "Authorization": "Bearer " + keycloak.token,
                "Content-Type": "application/json"
              },
              url: keycloakAPI + "/auth/admin/realms/PortalSA/users/" + fieldValue["id"],
              method: "DELETE",
              body: JSON.stringify(userData)
            })
              // .then (function (response){
              //   //console.log("RESPONSE", JSON.parse(response))
              // })
              .catch(function (error) {
                return console.log("Request error: ", error)
              })
            fetchUsers()
            setButtons(Buttons[userRole].SearchUserButtons)
            setFieldValue({ enabled: true, OrgId: userOrgId, userRole: "candidate" })
            updateSOP(userOrgId, "candidate")
            setForm(SearchUserForm)
          }
        })
    }
    else if (name === "back") {
      fetchUsers()
      setButtons(Buttons[userRole].SearchUserButtons)
      setFieldValue({ enabled: true, OrgId: userOrgId, userRole: "candidate" })
      updateSOP(userOrgId, "candidate")
      setForm(SearchUserForm)
    }
    else if (name === "viewCanididateMeetUpRequest") {
      setSelectedUserOrgId(fieldValue["OrgId"])
      setSelectedUserId(fieldValue["id"])
      setTask("getMeetUpRequestsByUserId")
      setButtons(Buttons[userRole].ViewUserRequestData)
    }
    else if (name === "viewUserRequestDataBack") {
      // fetchAllUserRequests(fieldValue["id"]) 
    }
    else {
      console.log("Button ", name, item)
    }
  }
  function sectionBuilder(section, index) {
    // creating table and label of section and components
    return (
      <MTable size="small" key={index}>
        <TableHead>
          <TableRow style={{ height: 30 }}>
            <TableCell key={index} style={{
              color: "black",
              fontSize: 14,
              backgroundColor: "#D3D3FE"
            }}>{section.label}</TableCell>
            <TableCell style={{ backgroundColor: "#D3D3FE" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {section.contents.map((contentItem, index) => (
            <TableRow key={index} style={{ height: 30, maxWidth: 200 }}>
              {contentItem.label !== "" &&
                <TableCell
                  key={getUUID()}
                  align="left"
                  style={{ maxWidth: 200 }}>
                  {contentItem.label}
                </TableCell>
              }
              <TableCell
                key={getUUID()}
                align="left"
                style={{ maxWidth: 200, height: 30 }}
              >{contentBuilder(contentItem)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MTable>
    )
  }
  function contentBuilder(contentItem) {
    // Creating components by it's type
    if (contentItem.type === "Text") {
      return (
        <TextField
          multiline
          // onChange = {componentChange}
          onBlur={handleChange}
          name={contentItem.name}
          style={{ minWidth: 250 }}
          disabled={(contentItem.edit === false) ? true : false}
          defaultValue={(fieldValue[contentItem.name]) ? fieldValue[contentItem.name] : ""}
        />
      )
    }
    else if (contentItem.type === "DateTime") {
      return (
        <TextField
          type="date"
          name={contentItem.name}
          onBlur={handleChange}
          style={{ minWidth: 250 }}
          // defaultValue = {fieldValue[contentItem.name] !== undefined) ? fieldValue[contentItem.name] : "NaN.NaN.NaN" }
          defaultValue={fieldValue[contentItem.name]}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )
    }
    else if (contentItem.type === "Switch") {
      return (
        <Switch
          // defaultChecked
          name={contentItem.name}
          onChange={handSwitchChange}
          color="primary"
          // disabled={(contentItem.edit === false) ? true : false}
          checked={fieldValue[contentItem.name]}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      )
    }

    else if (contentItem.type === "Enum") {
      let options = []
      options.push({
        "value": "",
        "label": "",
        "name": contentItem.name
      })
      for (let i = 0; i < enumData.length; i++) {
        if (contentItem.enumDef === enumData[i].enumDef) {
          for (let l = 0; l < enumData[i].data.length; l++) {
            options.push({
              "value": enumData[i].data[l].Id,
              "label": enumData[i].data[l].Text,
              "name": contentItem.name
            })
          }
        }
      }
      let selectedOption = {
        "value": "",
        "label": "",
        "name": contentItem.name
      }
      // console.log("ORG", contentItem.name, fieldValue[contentItem.name], organizationId)
      if (fieldValue[contentItem.name] !== undefined) {
        for (let i = 0; i < options.length; i++) {
          if (fieldValue[contentItem.name] === options[i].value) {
            selectedOption = options[i]
            // console.log("OPT", options[i])
            break
          }
        }
      }
      if (selectedOptions.length !== 0) {
        for (let i = 0; i < selectedOptions.length; i++) {
          if (contentItem.name === selectedOptions[i].name) {
            selectedOption = selectedOptions[i]
          }
        }
      }
      return (
        <Select
          name={contentItem.name}
          className={classesnav.select}
          value={selectedOption}
          onChange={handleSelectChange}
          options={options}
          isDisabled={(contentItem.edit === false) ? true : false}
        />
      )
    }
  }
  function getGridFormItems(value, type) {
    if (type === "Enum") {
      for (let i = 0; i < enumData.length; i++) {
        for (let l = 0; l < enumData[i].data.length; l++) {
          if (enumData[i].data[l].Id === value) {
            return enumData[i].data[l].Text
          }
        }
      }
    }
    else {
      return value
    }
  }
  function parseDate(date) {
    var newDate = new Date(date)
    var dd = String(newDate.getDate()).padStart(2, '0')
    var mm = String(newDate.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = newDate.getFullYear()
    var convertedDate = dd + '-' + mm + '-' + yyyy
    return convertedDate
  }
  console.log("FIELDS", fieldValue)
  console.log("SOP", selectedOptions)
  if (Form !== null && docList !== null && task === "viewUsers") {
    // console.log("FORM", Form)
    return (
      <Grid>
        <Grid container direction="row" justify="flex-start" spacing={0}>
          <Grid item xs={7}>
            <Paper>
              <MTable size="small">
                <Grid container direction="row" justify="center" alignItems="center">
                  <TableHead>
                    <TableRow style={{ maxHeight: 25 }}>
                      <TableCell style={{ fontSize: 16, color: "black" }}>{Form.label}</TableCell>
                    </TableRow>
                  </TableHead>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center">
                  {Form.sections.map((section, index) => {
                    return sectionBuilder(section, index)
                  })}
                </Grid>

              </MTable>
              <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                {buttons.map((button, index) =>
                  // return createButton(button, index)
                  <Button
                    key={getUUID()}
                    name={button.name}
                    variant="outlined"
                    value={button.name}
                    onClick={() => buttonClick(button.name, null)}
                    style={{
                      margin: 3,
                      backgroundColor: button.backgroundColor,
                      height: 32,
                      fontSize: 12
                    }}
                  >{button.label}
                  </Button>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <br></br>
        {Object.keys(docList).length !== 0 &&
          <Grid container direction="row" justify="flex-start" spacing={0}>
            <Grid item sm={"auto"}>
              <Paper>
                <Table size="small">
                  <TableHead style={{ backgroundColor: "#D3D3FE" }}>
                    <tr>
                      <th colSpan="1"></th>
                      {gridForm.sections.map((section, index) => {
                        return (
                          <th colSpan={section.contents.length} key={index} style={{ color: "black", fontSize: 15 }}>{section.label}</th>
                        )
                      })}
                    </tr>
                    <tr>
                      <th rowSpan="2" key="action" style={{ color: "black", fontSize: 14, minWidth: 50 }}>Действие</th>
                    </tr>
                    <tr>
                      {gridForm.sections.map(section =>
                        section.contents.map((contentItem, index) => {
                          return (
                            <th rowSpan="2" key={index} style={{ color: "black", fontSize: 14 }}>{contentItem.label}</th>
                          )
                        })
                      )}
                    </tr>
                  </TableHead>
                  <TableBody>
                    {docList.map((dataItem, index) => (
                      <tr key={index} style={{ height: 30 }}>
                        <td key={getUUID()} style={{ maxWidth: 50 }}>
                          {gridFormButtons !== null &&
                            gridFormButtons.map((button, index) =>
                              <Button
                                key={index}
                                name={button.name}
                                variant="outlined"
                                // id={dataItem.id}
                                value={button.name}
                                onClick={() => buttonClick(button.name, dataItem)}
                                style={{
                                  margin: 1,
                                  height: 24,
                                  fontSize: 9,
                                  maxWidth: 32,
                                  backgroundColor: button.backgroundColor
                                }}
                              >{button.label}
                              </Button>
                            )}
                        </td>
                        {gridForm.sections.map(section => {
                          return (
                            section.contents.map((contentItem, index) => {
                              for (let key in dataItem) {
                                if (key === contentItem.name) {
                                  if (dataItem[key] === null || dataItem[key] === "") {
                                    return (
                                      <td key={index} align="center" style={{ color: "black", fontSize: 12 }}>-</td>
                                    )
                                  }
                                  else {
                                    return (
                                      <td key={index} align="center" style={{ color: "black", fontSize: 12 }}>
                                        {getGridFormItems(dataItem[key], contentItem.type)}
                                      </td>
                                    )
                                  }
                                }
                              }
                            })
                          )
                        })}
                      </tr>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        }
      </Grid>
    )
  }
  else if (task === "getMeetUpRequestsByUserId" && selectedUserOrgId !== null && selectedUserId !== null) {
    return (
      <CandidateMeetUpRequests
        portalAPI={portalAPI}
        keycloakAPI={keycloakAPI}
        getUserStatus={getUserStatus}
        userId={selectedUserId}
        userRole={"admin"}
        taskType={"getMeetUpRequestsByUserId"}
        userOrgId={selectedUserOrgId}
      />
    )
  }
  else {
    return (
      <div align="center" style={{ paddingTop: 250 }}>
        <CircularProgress />
      </div>
    )
  }
};
