import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import MTable from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import swal from "sweetalert"; // https://sweetalert.js.org/guides/
import Buttons from "../Buttons.json";
import ViewChildData from "./ViewChildData";
import ViewCandidateData from "./ViewCandidateData";
// import DownloadPDF from './DownloadPDF';
import Pdf from "react-to-pdf";

var request = require("request-promise");

const useStyles = makeStyles((theme) => ({
  select: {
    maxWidth: 250,
  },
}));

export default (props) => {
  const [portalAPI] = useState(props.portalAPI)
  const [keycloakAPI] = useState(props.keycloakAPI)
  // Просмотр запросов и на встречу с ребенком и скачивание направления
  const [userRole, setUserRole] = useState(null);
  const [userOrgId, setUserOrgId] = useState(props.userOrgId);
  const [userId, setUserId] = useState(null);
  const [buttons, setButtons] = useState(null);
  const [task, setTask] = useState(null);
  const [currentMeetUpRequest, setCurrentMeetUpRequest] = useState(null);
  const [meetUpRequestsList, setMeetUpRequestsList] = useState(null);
  const [childId, setChildId] = useState(null);
  const [candidateId, setCandidateId] = useState(null);
  const [candidateData, setCandidateData] = useState(null);
  const [childData, setChildData] = useState(null);
  const [meetUpRequestAcceptedDate, setMeetUpRequestAcceptedDate] = useState(null);
  var buttonDisabled = false

  const ref = React.createRef();
  var prevCandidateSolutionId = null;

  const classes = useStyles();
  useEffect(() => {
    console.log("MEETCOMP", props);
    setUserRole(props.userRole);
    setUserOrgId(props.userOrgId);
    setUserId(props.userId);
    if (props.taskType === "getMeetUpRequestsByUserId") {
      fetchMeetUpRequestsByUserId(props.userId);
      setButtons(Buttons[props.userRole].GetMeetUpRequestsByUserIdGridButtons);
      setTask(props.taskType);
    }
    else if (props.taskType === "getMeetUpRequestsByOrgId") {
      fetchMeetUpRequestsByOrgId(props.userOrgId);
      setButtons(Buttons[props.userRole].GetMeetUpRequestsByOrgIdGridButtons);
      setTask(props.taskType);
    }
  }, []);
  async function fetchMeetUpRequestsByOrgId(userOrgId) {
    console.log("FETCH MEET REQ BY ORG", userOrgId)
    await request({
      headers: { "content-type": "application/json" },
      url:
        portalAPI + "/ChildrenAdoptionApi/api/MeetupRequests/GetRequestsByOrgId?orgId=" +
        userOrgId,
      method: "GET",
    })
      .then(function (response) {
        let parsedData = JSON.parse(response);
        // console.log("MEETUP", parsedData)
        setMeetUpRequestsList(parsedData);
      })
      .catch(function (error) {
        return console.log("Request error: ", error);
      });
  }
  async function fetchMeetUpRequestsByUserId(userId) {
    await request({
      headers: { "content-type": "application/json" },
      url:
        portalAPI + "/ChildrenAdoptionApi/api/MeetupRequests/GetByUserId?userId=" +
        userId,
      method: "GET",
    })
      .then(function (response) {
        let parsedData = JSON.parse(response);
        // console.log("MEETUP LIST", parsedData)
        setMeetUpRequestsList(parsedData);
      })
      .catch(function (error) {
        return console.log("Request error: ", error);
      });
  }
  async function fetchCandidateData(candidateId) {
    // console.log("PIN")
    await request({
      headers: { "content-type": "application/json" },
      url:
        portalAPI + "/ChildrenAdoptionApi/api/PortalUser/GetCandidateById?userId=" +
        candidateId,
      method: "GET",
    })
      .then(function (response) {
        let parsedData = JSON.parse(response);
        console.log("CANDIDATE", parsedData);
        setCandidateData(parsedData);
      })
      .catch(function (error) {
        return console.log("Request error: ", error);
      });
  }
  async function fetchChildData(childId) {
    await request({
      headers: { "content-type": "application/json" },
      url:
        portalAPI + "/ChildrenAdoptionApi/api/AvailableChildren/GetChildDetails?childId=" +
        childId,
      method: "GET",
    })
      .then(function (response) {
        let parsedData = JSON.parse(response);
        console.log("CHILD", parsedData);
        setChildData(parsedData);
      })
      .catch(function (error) {
        return console.log("Request error: ", error);
      });
  }
  function parseDate(date) {
    var newDate = new Date(date);
    var dd = String(newDate.getDate()).padStart(2, "0");
    var mm = String(newDate.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = newDate.getFullYear();
    var hour = newDate.getHours();
    var minutes = newDate.getMinutes();
    var convertedDate =
      dd + "-" + mm + "-" + yyyy + "  " + hour + ":" + minutes;
    return convertedDate;
  }
  function parseDateComponent(date) {
    var newDate = new Date(date);
    var dd = String(newDate.getDate()).padStart(2, "0");
    var mm = String(newDate.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = newDate.getFullYear();
    var convertedDate = yyyy + "-" + mm + "-" + dd;
    return convertedDate;
  }
  function getUserStatus() {
    props.getUserStatus();
  }
  async function meetUpRequestAccept(requestItemId) {
    await request({
      headers: { "content-type": "application/json" },
      url:
        portalAPI + "/ChildrenAdoptionApi/api/MeetupRequests/Accept?requestItemId=" + requestItemId,
      method: "GET",
    }).catch(function (error) {
      return console.log("Request error: ", error);
    })
  }
  async function buttonClick(name, candidateMeetUpRequest) {
    console.log("Button", name, candidateMeetUpRequest);
    if (name === "createMeetUpRequest") {
      if (buttonDisabled === false) {
        swal({
          text: "Вы уверены, что хотите создать запрос на встречу с ребенком?",
          icon: "warning",
          buttons: { yes: "Да", no: "Нет" }
        }).then(async function (click) {
          if (click === "yes") {
            buttonDisabled = true
            await request({
              headers: { "content-type": "application/json" },
              url:
                portalAPI + "/ChildrenAdoptionApi/api/MeetupRequests/Create?requestId=" +
                candidateMeetUpRequest.Id,
              method: "GET",
            })
              .then(function (response) {
                let parsedData = JSON.parse(response)
                console.log("MEETUP REQ", parsedData)
                if (parsedData.isSuccess === false) {
                  swal({
                    text: "Вы не можете повторно создать запрос!",
                    icon: "warning",
                  })
                }
                else {
                  swal({
                    text: "Запрос на встречу с ребенком успешно создан!",
                    icon: "success",
                  })
                }
                buttonDisabled = false
              })
              .catch(function (error) {
                return console.log("Request error: ", error);
              })
          }
        })
      }

      console.log("Button acceptRequest", candidateMeetUpRequest);
    } else if (name === "viewMeetUpRequest") {
      // Просмотр запроса на встречу кандидатом
      setChildId(candidateMeetUpRequest.ChildId);
      // setCandidateId(candidateMeetUpRequest.CandidateRequest.PortalUserId)
      setTask("viewCandidateMeetUpRequestData");
      setCurrentMeetUpRequest(candidateMeetUpRequest);
      setButtons(Buttons[userRole].ViewMeetUpRequestsButtons);
    } else if (name === "openCandidateMeetUpRequest") {
      // Просмотр запроса на встречу
      // setCandidateId(candidateMeetUpRequest.CandidateRequest.PortalUserId)
      setChildId(candidateMeetUpRequest.ChildId);
      setTask("viewCandidateMeetUpRequestData");
      setCurrentMeetUpRequest(candidateMeetUpRequest);
      setButtons(Buttons[userRole].ViewCandidateMeetUpRequestData);
    } else if (name === "viewMeetUpRequestsBack") {
      fetchMeetUpRequestsByUserId(userId);
      setButtons(Buttons[userRole].GetMeetUpRequestsByUserIdGridButtons);
      setTask("getMeetUpRequestsByUserId");
    } else if (name === "viewCandidateMeetUpRequestBack") {
      fetchMeetUpRequestsByOrgId(userOrgId);
      setButtons(Buttons[userRole].GetMeetUpRequestsByOrgIdGridButtons);
      setTask("getMeetUpRequestsByOrgId");
    } else if (name === "downloadPDF") {
      let deadline = new Date(candidateMeetUpRequest.Deadline);
      let remainingTime = deadline.getTime() - Date.now();
      console.log("REMAINING", remainingTime);
      if (remainingTime > 0) {
        // еще не прошло 7 суток с момента подачи первой заявки
        swal({
          text: "Вы пока не можете скачать направление, еще не прошло 7 суток с момента подачи первой заявки!",
          icon: "warning",
        });
      }
      else {
        // прошло 7 суток с момента подачи первой заявки
        for (let i = 0; i < candidateMeetUpRequest.MeetupRequestItems.length; i++) {
          if (candidateMeetUpRequest.MeetupRequestItems[i].PortalUserId === userId) {
            if (i === 0) {
              // кандидат 1й в списке
              meetUpRequestAccept(candidateMeetUpRequest.MeetupRequestItems[i].Id)
              setTask("downloadPDF")
              fetchCandidateData(userId)
              fetchChildData(candidateMeetUpRequest.ChildId)
              setMeetUpRequestAcceptedDate(parseDate(candidateMeetUpRequest.Deadline))
            }
            else {
              // кандидат 2й и более в списке
              if (candidateMeetUpRequest.MeetupRequestItems[i - 1].VisitResult !== null) {
                // Предыдущий кандидат заполнял результат встречи
                let prevCandSolId = candidateMeetUpRequest.MeetupRequestItems[i - 1].VisitResult.CandidateSolutionId
                if (prevCandSolId === "0d7921d2-e8ac-40d0-aad6-93ff5bd7be66" || prevCandSolId === "00000000-0000-0000-0000-000000000000") {
                  // Предыдущий кандидат отказался, дать доступ на скачивание следующему кандидату
                  meetUpRequestAccept(candidateMeetUpRequest.MeetupRequestItems[i].Id)
                  setTask("downloadPDF")
                  fetchCandidateData(userId)
                  fetchChildData(candidateMeetUpRequest.ChildId)
                  setMeetUpRequestAcceptedDate(parseDate(candidateMeetUpRequest.Deadline))
                }
                else {
                  // Предыдущий кандидат согласился
                  swal({
                    text: "Данный ребенок уже усыновлен(удочерена)!",
                    icon: "warning",
                  })
                }
              }
              else {
                // Предыдущий кандидат еще НЕ заполнял результат встречи
                swal({
                  text: "Вы пока не можете скачать направление, предыдущий кандидат НЕ заполнил свое решение!",
                  icon: "warning",
                })
              }
            }
          }
        }
      }
    }
    else if (name === "deleteCandidateMeetUpRequests") {
      swal({
        text:
          "Вы уверены что хотите удалить заявку?",
        icon: "warning",
        buttons: { yes: "Да", no: "Нет" },
      }).then(async function (click) {
        if (click === "yes") {
          for (let i = 0; i < candidateMeetUpRequest.MeetupRequest.MeetupRequestItems.length; i++) {
            if (candidateMeetUpRequest.MeetupRequest.MeetupRequestItems[i].PortalUserId === userId) {
              // if(i > 0){
              //   if(candidateMeetUpRequest.MeetupRequestItems[i-1].VisitResult === null &&
              //     candidateMeetUpRequest.MeetupRequestItems[i].VisitResult === null){
              // console.log("MEET", candidateMeetUpRequest.MeetupRequest.MeetupRequestItems[i].CandidateFullName)
              request({
                headers: { "content-type": "application/json" },
                url:
                  portalAPI + "/ChildrenAdoptionApi/api/MeetupRequests/Delete?requestItemId=" +
                  candidateMeetUpRequest.MeetupRequest.MeetupRequestItems[i].Id,
                method: "GET",
              })
                .then(function (response) {
                  swal({
                    text: "Заявка удалена",
                    icon: "success",
                  });
                  getUserStatus();
                  setTask(null);
                })
                .catch(function (error) {
                  return console.log("Request error: ", error);
                });

              // }
              // else if(candidateMeetUpRequest.MeetupRequestItems[i].VisitResult === null &&
              //   candidateMeetUpRequest.MeetupRequestItems[i-1].VisitResult.CandidateSolutionId !== "0d7921d2-e8ac-40d0-aad6-93ff5bd7be66"){
              //   request(
              //   {
              //     "headers": { "content-type": "application/json"},
              //     "url": portalAPI + "/ChildrenAdoptionApi/api/MeetupRequests/Delete?requestItemId=" + candidateMeetUpRequest.MeetupRequestItems[i].Id,
              //     "method": "GET"
              //   })
              //   .then (function (response){
              //     swal({
              //       text: "Заявка удалена",
              //       icon: "success",
              //     })
              //     getUserStatus()
              //     setTask(null)
              //   })
              //   .catch(function (error){
              //     return console.log("Request error: ", error)
              //   })

              // }
              //   else{
              //     swal({
              //       text: "Вы не можете удалить запрос!",
              //       icon: "warning",
              //     })
              //   }
              // }
              // else{
              //   swal({
              //     text: "Вы не можете удалить запрос!",
              //     icon: "warning",
              //   })
              // }
            }
          }
        }
      });
    }
    else {
      console.log("Button", name);
    }
  }
  function getStyleByStatus(candidate, index) {
    console.log("CAND", candidate, "INDEX ", parseInt(index))
    if (index === 0) {
      // Кандидат 1й в списке
      if (candidate.VisitResult === null) {
        // Кандидат посещает ребенка, решения еще нет
        prevCandidateSolutionId = null;
        return { backgroundColor: "#AFB3FC" }; // Blue
      }
      else {
        // console.log("CAND:0", candidate, "INDEX ", index)
        if (candidate.VisitResult.CandidateSolutionId === "46cb0b76-2a82-4e20-9f55-cdfae5393bdf") {
          // Согласие
          prevCandidateSolutionId = candidate.VisitResult.CandidateSolutionId;
          return { backgroundColor: "#7BFC72" }; // Green
        }
        else if (candidate.VisitResult.CandidateSolutionId === "0d7921d2-e8ac-40d0-aad6-93ff5bd7be66" || candidate.VisitResult.CandidateSolutionId === "00000000-0000-0000-0000-000000000000") {
          // Отказ
          prevCandidateSolutionId = candidate.VisitResult.CandidateSolutionId;
          return { backgroundColor: "#FB6464" }; // Red
        }
      }
    }
    else {
      // Кандидат 2й и ниже в списке
      if (candidate.VisitResult === null) {
        if (
          prevCandidateSolutionId === null ||
          prevCandidateSolutionId === "46cb0b76-2a82-4e20-9f55-cdfae5393bdf"
        ) {
          // Предыдущий кандидат согласился либо еще не решил
          prevCandidateSolutionId = null;
          return { backgroundColor: "#F9F784" }; // Yellow
        } else if (
          prevCandidateSolutionId === "0d7921d2-e8ac-40d0-aad6-93ff5bd7be66" || prevCandidateSolutionId === "00000000-0000-0000-0000-000000000000"
        ) {
          // Предыдущий кандидат отказался
          prevCandidateSolutionId = null;
          return { backgroundColor: "#AFB3FC" }; // Blue
        }
      }
      else {
        if (
          candidate.VisitResult.CandidateSolutionId ===
          "46cb0b76-2a82-4e20-9f55-cdfae5393bdf"
        ) {
          // Согласие
          prevCandidateSolutionId = candidate.VisitResult.CandidateSolutionId;
          return { backgroundColor: "#7BFC72" }; // Green
        } else if (
          candidate.VisitResult.CandidateSolutionId ===
          "0d7921d2-e8ac-40d0-aad6-93ff5bd7be66"
        ) {
          // Отказ
          prevCandidateSolutionId = candidate.VisitResult.CandidateSolutionId;
          return { backgroundColor: "#FB6464" }; // Red
        }
      }
    }
  }
  function getStatus(visitResult) {
    if (visitResult === null) {
      return "-";
    } else {
      if (
        visitResult.CandidateSolutionId ===
        "46cb0b76-2a82-4e20-9f55-cdfae5393bdf"
      ) {
        return "Согласие";
      } else if (
        visitResult.CandidateSolutionId ===
        "0d7921d2-e8ac-40d0-aad6-93ff5bd7be66"
      ) {
        return "Отказ";
      }
    }
  }
  function strReplace(str) {
    let beginStr = str.substring(0, 10);
    let midStr = str.substring(10, 28);
    let finStr = str.substring(29, 36);
    let hideMidStr = midStr.replace(/[a-z, 0-9]/g, "*");
    let joinStr = beginStr + hideMidStr + finStr;
    return joinStr;
  }
  // console.log("CAND PROPS", props)
  if (
    meetUpRequestsList !== null &&
    (task === "getMeetUpRequestsByOrgId" ||
      task === "getMeetUpRequestsByUserId")
  ) {
    if (Object.keys(meetUpRequestsList).length === 0) {
      return (
        <div align="center" style={{ paddingTop: 250, fontSize: 20 }}>
          Список запросов пуст
        </div>
      );
    } else {
      try {
        console.log("MEETLIST", meetUpRequestsList);
        return meetUpRequestsList.map((meetUpRequest, index) => {
          return (
            <Grid>
              <Paper>
                <MTable size="small">
                  <TableHead style={{ backgroundColor: "#D7E3DD" }}>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>
                        №
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Дата запроса
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Дата окнчания
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Количество заявок
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{parseDate(meetUpRequest.CreatedAt)}</TableCell>
                      <TableCell>{parseDate(meetUpRequest.Deadline)}</TableCell>
                      <TableCell>
                        {meetUpRequest.MeetupRequestItems.length}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </MTable>
                {buttons.map((button, index) => (
                  <Button
                    key={index}
                    name={button.name}
                    variant="outlined"
                    value={button.name}
                    onClick={() => buttonClick(button.name, meetUpRequest)}
                    style={{
                      margin: 1,
                      fontSize: 11,
                      backgroundColor: button.backgroundColor,
                    }}
                  >
                    {button.label}
                  </Button>
                ))}
              </Paper>
              <br></br>
            </Grid>
          );
        });
      }
      catch (er) {
        console.log("ER", er)
      }
    }
  } else if (childId !== null && task === "viewCandidateMeetUpRequestData") {
    return (
      <Grid>
        <Paper>
          <ViewChildData
            portalAPI={portalAPI}
            keycloakAPI={keycloakAPI}
            childId={childId}
            viewAddress={false}
          />
          {/* <ViewCandidateData
            candidateId={candidateId}
          /> */}
          <Grid>
            <Paper>
              <MTable size="small">
                <TableHead style={{ backgroundColor: "#D7E3DD" }}>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Действие
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      № очереди
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Район</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Id кандидата
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Дата выдачи заключения
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Дата заявки
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Статус</TableCell>
                  </TableRow>
                </TableHead>
                {currentMeetUpRequest.MeetupRequestItems.map((meetupRequest, index) => (
                  <TableBody>
                    <TableRow style={getStyleByStatus(meetupRequest, index)}>
                      <TableCell>
                        {meetupRequest.PortalUserId === userId &&
                          <Button
                            variant="outlined"
                            onClick={() => buttonClick("deleteCandidateMeetUpRequests", meetupRequest)}
                            style={{
                              margin: 0,
                              fontSize: 9,
                              backgroundColor: "#FF4F4F",
                            }}
                          >
                            Удалить
                      </Button>
                        }

                      </TableCell>

                      <TableCell
                        style={index === 0 ? { fontWeight: "bold" } : null}
                      >
                        {meetupRequest.OrderNo}
                      </TableCell>
                      <TableCell
                        style={index === 0 ? { fontWeight: "bold" } : null}
                      >
                        {meetupRequest.OrgName}
                      </TableCell>
                      <TableCell
                        style={index === 0 ? { fontWeight: "bold" } : null}
                      >
                        {strReplace(meetupRequest.PortalUserId)}
                      </TableCell>
                      <TableCell
                        style={index === 0 ? { fontWeight: "bold" } : null}
                      >
                        {parseDate(meetupRequest.CertificateDate)}
                      </TableCell>
                      <TableCell
                        style={index === 0 ? { fontWeight: "bold" } : null}
                      >
                        {parseDate(meetupRequest.CreatedAt)}
                      </TableCell>
                      <TableCell
                        style={index === 0 ? { fontWeight: "bold" } : null}
                      >
                        {getStatus(meetupRequest.VisitResult)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
                )}
              </MTable>
            </Paper>
            <br></br>
          </Grid>
          {buttons.map((button, index) => (
            <Button
              key={index}
              name={button.name}
              variant="outlined"
              value={button.name}
              onClick={() => buttonClick(button.name, currentMeetUpRequest)}
              style={{
                margin: 1,
                fontSize: 11,
                backgroundColor: button.backgroundColor,
              }}
            >
              {button.label}
            </Button>
          ))}
        </Paper>
      </Grid>
    );
  } else if (
    childData !== null &&
    candidateData !== null &&
    task === "downloadPDF"
  ) {
    return (
      <Grid container direction="row" justify="flex-start" spacing={0}>
        <Paper>
          <div style={{ padding: 70 }} id="root" ref={ref} align="left">
            <p align="center" style={{ fontSize: 18, fontWeight: "bold" }}>
              Направление на посещение ребенка
            </p>
            <p style={{ paddingLeft: 60 }}>
              Настоящим, {candidateData.OrgName}
            </p>
            <p>
              направляет кандидата в усыновители гр.{" "}
              {candidateData.ApplicantPerson.LastName +
                " " +
                candidateData.ApplicantPerson.FirstName +
                " " +
                candidateData.ApplicantPerson.MiddleName +
                ","}
            </p>
            <p>
              паспорт:
              {candidateData.ApplicantPerson.PassportNo +
                ", " +
                candidateData.ApplicantPerson.PassportOrg +
                ", от " +
                candidateData.ApplicantPerson.PassportDate}
            </p>
            <p>
              на посещение:
              {childData.LastName + " " + childData.FirstName}
            </p>
            <p>
              находящегося в учреждении{" "}
              <b>{childData.HouseName + ", по адресу" + childData.Address}</b>
            </p>
            <p style={{ paddingLeft: 30 }}>
              Дата выдачи: {parseDate(new Date())}
            </p>
            {/* <p style={{paddingLeft: 30}}>Подпись:________________      {candidateData.ManagerName}</p> */}
            {/* <p>(начальник или заместитель начальника территориального подразделения Министерства)</p> */}
            <p>
              Направление считается недействительным по истечении 30 дней с даты
              выдачи
            </p>
            <br></br>
            <br></br>
            {/* <p>М.П.</p> */}
          </div>
          <Pdf targetRef={ref} filename="Направление.pdf">
            {({ toPdf }) => (
              <Button
                key={"index"}
                variant="outlined"
                onClick={toPdf}
                style={{
                  margin: 1,
                  fontSize: 11,
                  backgroundColor: "#D1FBD1",
                }}
              >
                Скачать напрвление на встречу
              </Button>
            )}
          </Pdf>
        </Paper>
      </Grid>
    );
  } else {
    return (
      <div align="center" style={{ paddingTop: 250 }}>
        <CircularProgress />
      </div>
    );
  }
};
