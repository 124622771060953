import React, {useState, useEffect} from 'react';
import { makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
// import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from 'sweetalert' // https://sweetalert.js.org/guides/
import Buttons from '../Buttons.json';
// import MenuItem from '@material-ui/core/MenuItem';
// import MaterialSelect from '@material-ui/core/Select';
// import Pdf from "react-to-pdf";
import {useKeycloak} from '../lib';
var request = require("request-promise");

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 250
  }
}))

export default (props) => {
  const [portalAPI] = useState(props.portalAPI)
  const [keycloakAPI] = useState(props.keycloakAPI)
  // Просмотр и отклонение приказов
  const {keycloak} = useKeycloak()
  const [userRole, setUserRole] = useState(null)
  const [userId, setUserId] = useState(null)
  const [fieldValue, setFieldValue] = useState({})
  const [buttons, setButtons] = useState(null)
  const [task, setTask] = useState(10)
  const [ordersList, setOrdersList] = useState(null)
  const [childrenOrgNames, setChildrenOrgNames] = useState(null)
  const [childData, setChildData] = useState(null)
  const [candidateData, setCandidateData] = useState(null)
  const [currentOrder, setCurrentOrder] = useState(null)
  const [userExpired, setUserExpired] = useState(null)
  
  const ref = React.createRef();
  
  const classes = useStyles()
  useEffect(()=>{
      let uRole = props.userRole
      setUserRole(uRole)
      setUserId(props.userId)
      setUserExpired(props.userExpired)
      if(props.taskType === "getOrdersByUserId"){
        fetchOrdersByUserId(props.userId)
        setButtons(Buttons[props.userRole].GetOrdersGridButtons)
        setTask(props.taskType)
      }
      else if(props.taskType === "getOrdersByOrgId"){
        fetchOrdersByOrgId(props.userOrgId)
        setButtons(Buttons[props.userRole].GetOrdersGridButtons)
        setTask(props.taskType)
      }
  },[])
  async function fetchOrdersByUserId(userId){
    await request(
      {
        "headers": { "content-type": "application/json" },
        "url": portalAPI + "/ChildrenAdoptionApi/api/AdoptionOrders/GetByUserId?userId=" + userId,
        "method": "GET"
      }
    )
    .then (function (response){
      let parsedData = JSON.parse(response)
      console.log("ORDERS", parsedData)
      setOrdersList(parsedData)
      if(Object.keys(parsedData).length === 0){
        setChildrenOrgNames([])
      }
      else{
        for(let i=0; i<parsedData.length; i++){
          fetchChildOrgId(parsedData[i].MeetupRequestItem.MeetupRequest.ChildId)
        }
      }
      
    })
    .catch(function (error){
      return console.log("Request error: ", error)
    })
  }
  async function fetchOrdersByOrgId(userOrgId){
    await request(
      {
        "headers": { "content-type": "application/json" },
        "url": portalAPI + "/ChildrenAdoptionApi/api/AdoptionOrders/GetByOrgId?orgId=" + userOrgId,
        "method": "GET"
      }
    )
    .then (function (response){
      let parsedData = JSON.parse(response)
      console.log("ORDERS ORG", parsedData)
      setOrdersList(parsedData)
      if(Object.keys(parsedData).length === 0){
        setChildrenOrgNames([])
      }
      else{
        for(let i=0; i<parsedData.length; i++){
          fetchChildOrgId(parsedData[i].MeetupRequestItem.MeetupRequest.ChildId)
        }
      }
      
    })
    .catch(function (error){
      return console.log("Request error: ", error)
    })
  }
  async function fetchChildOrgId(childId){
    console.log("CHILD ID", childId)
    await request(
      {
        "headers": {"content-type": "application/json"},
        "url": portalAPI + "/ChildrenAdoptionApi/api/AvailableChildren/GetChildDetails?childId=" + childId,
        "method": "GET"
      }
    )
    .then (function (response){
      let parsedData = JSON.parse(response)
      console.log("Child", parsedData.OrgName)
      if(childrenOrgNames == null){
        let newChildrenOrgNames = []
        newChildrenOrgNames.push({childId: childId, orgName: parsedData.OrgName})
        setChildrenOrgNames(newChildrenOrgNames)
      }
      else{
        let newChildrenOrgNames = childrenOrgNames.slice()
        newChildrenOrgNames.push({childId: childId, orgName: parsedData.OrgName})
        setChildrenOrgNames(newChildrenOrgNames)
      }
      
    })
    .catch(function (error){
      return console.log("Request error: ", error)
    })
  }
  function getChildOrgId(childId){
    for(let i=0; i<childrenOrgNames.length; i++){
      if(childId === childrenOrgNames[i].childId){
        return childrenOrgNames[i].orgName
      }
    }
  }
  async function fetchCandidateData(candidateId){
    console.log("CAND ID", candidateId)
    await request(
      {
        "headers": { "content-type": "application/json" },
        "url": portalAPI + "/ChildrenAdoptionApi/api/PortalUser/GetCandidateById?userId=" + candidateId,
        "method": "GET"
      }
    )
    .then (function (response){
      let parsedData = JSON.parse(response)
      console.log("CANDIDATE", parsedData)
      setCandidateData(parsedData)
    })
    .catch(function (error){
      return console.log("Request error: ", error)
    })
  }
  async function fetchChildData(childId){
    console.log("CHILD ID", childId)
    await request(
      {
        "headers": { "content-type": "application/json" },
        "url": portalAPI + "/ChildrenAdoptionApi/api/AvailableChildren/GetChildDetails?childId=" + childId,
        "method": "GET"
      }
    )
    .then (function (response){
      let parsedData = JSON.parse(response)
      console.log("CHILD", parsedData)
      setChildData(parsedData)
    })
    .catch(function (error){
      return console.log("Request error: ", error)
    })
  }
  function handleChange(event){
    // console.log("EVENT", event)
    fieldValue[event.target.name] = event.target.value
    setFieldValue(fieldValue)
    console.log("FIELDVALUE", fieldValue)
  }
  async function buttonClick(name, candidateOrder){
    console.log("Button ", name, candidateOrder)
    if(name === "openOrder"){
      await fetchChildData(candidateOrder.MeetupRequestItem.MeetupRequest.ChildId)
      await fetchCandidateData(candidateOrder.MeetupRequestItem.PortalUserId)
      await setTask("viewOrderData")
      await setButtons(Buttons[props.userRole].ViewOrderDataButtons)
      await setCurrentOrder(candidateOrder)
    }
    else if(name === "openOrderBack"){
      setButtons(Buttons[props.userRole].GetOrdersGridButtons)
      setTask(props.taskType)
    }
    else if(name === "fillOrderRejection"){
      let deadline = new Date(currentOrder.Date)
      let remainingTime =  deadline.getTime() - Date.now()
      // let remainingTime = 1
      console.log("REMAINING", remainingTime)
      if(remainingTime < 0){
        swal({
          text: "Срок отклонения приказа истек!",
          icon: "warning",
        })
      }
      else{
        setTask("fillOrderRejection")
        setButtons(Buttons[props.userRole].OrderRejectionButtons)
        setFieldValue({OrederRejectionDate: parseDateComponent(new Date())})
      }
    }
    else if(name === "orderRejectionBack"){
      setTask("viewOrderData")
      setButtons(Buttons[props.userRole].ViewOrderDataButtons)
    }
    else if(name === "saveOrderRejection"){
      await request(
        {
          "headers": { "content-type": "application/json" },
          "url": portalAPI + "/ChildrenAdoptionApi/api/AdoptionOrders/Reject?orderId="+ currentOrder.Id+"&reason=" + fieldValue["RejectReason"],
          "method": "GET"
        }
      )
      .then (function (response){
        // let parsedData = JSON.parse(response)
        // console.log("RESP", parsedData)
        setTask("viewOrderData")
        setButtons(Buttons[props.userRole].ViewOrderDataButtons)
        swal({
          text: "Приказ отклонен!",
          icon: "success",
        })
      })
      .catch(function (error){
        return console.log("Request error: ", error)
      })
    }
  }
  function parseDate(date){
    var newDate = new Date(date)
    var dd = String(newDate.getDate()).padStart(2, '0')
    var mm = String(newDate.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = newDate.getFullYear()
    var hour = newDate.getHours()
    var minutes = newDate.getMinutes()
    var convertedDate = dd + '-' + mm + '-' + yyyy + '  ' + hour + ':' + minutes
    return convertedDate
  }
  function parseDateComponent(date){
    var newDate = new Date(date)
    var dd = String(newDate.getDate()).padStart(2, '0')
    var mm = String(newDate.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = newDate.getFullYear()
    var convertedDate = yyyy + '-' + mm + '-' + dd
    return convertedDate
  }
  function getExpiredDate(){
    var newDate = new Date()
    var dd = String(newDate.getDate()).padStart(2, '0') + 3
    var mm = String(newDate.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = newDate.getFullYear()
    var convertedDate = yyyy + '-' + mm + '-' + dd
    return convertedDate
  }
  function checkRejectedOrder(){
    let deadline = new Date(currentOrder.Date)
    let remainingTime =  deadline.getTime() - Date.now()
    // let userExpired =  deadline.getTime() - Date.now()
    console.log("REMAINING ORDER", remainingTime, currentOrder)
    if(remainingTime > 0){
      if(currentOrder.IsRejected === false){
        return "Приказ в обработке"
      }
      else{
        return "Отклонен оператором"
      }
    }
    else{
      if(currentOrder.IsRejected === true){
        return "Отклонен оператором"
      }
      else{
        if(userRole === "candidate"){
          swal({
            text: "ВНИМАНИЕ приказ был одобрен и ваш доступ к порталу скоро будет заблокирован!",
            icon: "warning"
          })
        }
        return "Одобрено"
      }
    }
    // if(remainingTime > 0 && currentOrder.IsRejected === false){
    //   return "Приказ в обработке"   
    // }
    // else if(remainingTime < 0 && currentOrder.IsRejected === true){
    //   return "Отклонен оператором"
    // }
    // else if(remainingTime < 0 && currentOrder.IsRejected === false && userRole === "candidate"){
    //   swal({
    //     text: "ВНИМАНИЕ приказ был одобрен и ваш доступ к порталу скоро будет заблокирован!",
    //     icon: "warning"
    //   })
    //   return "Одобрено"
    // }
  }
  function checkForUndefined(data){
    if(data === undefined || data === null){
      return " "
    }
    else{
      return data
    }
  }
  // console.log("FIELDS", fieldValue)
  console.log("CURORDER", currentOrder)
  if(ordersList !== null && childrenOrgNames !== null && (task === "getOrdersByUserId" || task === "getOrdersByOrgId")){
    if(Object.keys(ordersList).length === 0){
      return(
        <div align="center" style={{paddingTop: 250, fontSize: 20}}>
          Список приказов пуст
        </div>
      )
    }
    else{
      console.log("ORDERS", ordersList)
      // console.log("childrenOrgNames", childrenOrgNames)
      return(
        ordersList.map((order, index) =>{
          return(
            <Grid>
              <Card>
              <Table size="small" >
                  <TableHead style={{backgroundColor: "#D7E3DD"}}>
                    <TableRow>
                      <TableCell style={{fontWeight:"bold"}}>Район ребенка</TableCell>
                      <TableCell style={{fontWeight:"bold"}}>№ приказа</TableCell>
                      <TableCell style={{fontWeight:"bold"}}>Дата приказа</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{getChildOrgId(order.MeetupRequestItem.MeetupRequest.ChildId)}</TableCell>
                      <TableCell>{order.No}</TableCell>
                      <TableCell>{parseDate(order.Date)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table> 
                {buttons.map((button, index)=>
                  <Button
                    key={index}
                    name={button.name}
                    variant="outlined"
                    value={button.name}                               
                    onClick = {() => buttonClick(button.name, order)}
                    style={{
                        margin: 1,
                        fontSize: 11,
                        backgroundColor: button.backgroundColor
                    }}
                  >{button.label}
                  </Button>
                )}
              </Card>
              <br></br>
            </Grid> 
          )
        })
      )
    }
  }
  else if(task === "fillOrderRejection"){
    return(
      <Grid>
        <Grid container direction="row" justify="flex-start" spacing={0}>
          <Grid item xs={10}>
            <Card>
              <Table>
                <TableHead style={{backgroundColor: "#282828"}}>
                  <TableRow style={{maxHeight: 25}}>
                    <TableCell style={{fontSize: 16, color: "white"}}>Форма ввода отказа на приказ об усыновлении</TableCell>
                  </TableRow>
                </TableHead>
              </Table>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Дата</TableCell>
                    <TableCell>
                      <TextField
                        type="date"
                        name = "OrederRejectionDate"
                        style={{minWidth: 250}}
                        disabled
                        defaultValue = {fieldValue["OrederRejectionDate"]}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Причина отказа</TableCell>
                    <TableCell>
                      <TextField
                        multiline
                        name = "RejectReason"
                        onBlur = {handleChange}
                        style={{minWidth: 250}}
                        defaultValue = {fieldValue["RejectReason"]}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Grid container  direction="row" justify="flex-start" alignItems="flex-start">
                {buttons.map((button, index) =>
                  <Button
                    key={index}
                    name={button.name}
                    variant="outlined"
                    value={button.name}                               
                    onClick = {() => buttonClick(button.name, null)}
                    style={{
                      margin: 3, 
                      backgroundColor: button.backgroundColor,
                      height: 32, 
                      fontSize: 12
                    }}
                  >{button.label}
                  </Button>
                )}
              </Grid>  
            </Card>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  else if(childData !== null && candidateData !== null && currentOrder !== null && task === "viewOrderData"){
    return(
      <Grid container direction="row" justify="flex-start" spacing={0}>
        <Card>
          <div style={{padding: 80}} id="root" ref={ref} align="left">
            <p align="center" style={{fontSize: 18, fontWeight: "bold"}}>ПРИКАЗ</p>
            <p align="center" style={{fontSize: 15, fontWeight: "bold"}}>о передаче ребенка на предварительную опеку</p>
            <p align="center">{
              checkForUndefined(childData.LastName) + " " + 
              checkForUndefined(childData.FirstName) + " " + 
              checkForUndefined(childData.MiddleName) + " " + 
              checkForUndefined(childData.Birthday)
              // childData.LastName + " " + 
              // childData.FirstName + " " + 
              // childData.MiddleName + " " + 
              // childData.Birthday
            }</p>
            <p>{
              checkForUndefined(candidateData.ApplicantPerson.LastName) + " " + 
              checkForUndefined(candidateData.ApplicantPerson.FirstName) + " " + 
              checkForUndefined(candidateData.ApplicantPerson.MiddleName) +", "+
              checkForUndefined(candidateData.SpousePerson.LastName) + " " + 
              checkForUndefined(candidateData.SpousePerson.FirstName) + " " + 
              checkForUndefined(candidateData.SpousePerson.MiddleName)
              }
            </p>
            <br></br>
            <p style={{paddingLeft: 20}}>1. Общая характеристика кандидатов в усыновитиели</p>
            <p>Фамилия: {checkForUndefined(candidateData.ApplicantPerson.LastName)} 
              Имя: {checkForUndefined(candidateData.ApplicantPerson.FirstName)}</p>
            <p>Отчество: {checkForUndefined(candidateData.ApplicantPerson.MiddleName)} Дата рождения: {checkForUndefined(candidateData.ApplicantPerson.Birthday)}</p>
            <p>Место работы: {checkForUndefined(candidateData.ApplicantJobDetails)} Должность: {checkForUndefined(candidateData.ApplicantPerson.Position)}</p>
            {/* <p>Образование: {candidateData.ApplicantPerson.Education} Гражданство: {candidateData.ApplicantPerson.Citizenship}</p> */}
            <br></br>
            <p>Фамилия: {checkForUndefined(candidateData.SpousePerson.LastName)} Имя: {checkForUndefined(candidateData.SpousePerson.FirstName)}</p>
            <p>Отчество: {checkForUndefined(candidateData.SpousePerson.MiddleName)} Дата рождения: {checkForUndefined(candidateData.SpousePerson.Birthday)}</p>
            <p>Место работы: {checkForUndefined(candidateData.SpouseJobDetails)} Должность: {checkForUndefined(candidateData.SpousePerson.Position)}</p>
            {/* <p>Образование: {candidateData.SpousePerson.Education} Гражданство: {candidateData.SpousePerson.Citizenship}</p> */}
            <p>Домашний адрес: {
            checkForUndefined(candidateData.AddressDetails.Area) + " " + 
            checkForUndefined(candidateData.AddressDetails.Area) + " " + 
            checkForUndefined(candidateData.AddressDetails.District) + " " + 
            checkForUndefined(candidateData.AddressDetails.Settlement) + " " + 
            checkForUndefined(candidateData.AddressDetails.Village) + ", улица: " + 
            checkForUndefined(candidateData.AddressDetails.Street) + ", дом: " +  
            checkForUndefined(candidateData.AddressDetails.House) + ", квартира: " + 
            checkForUndefined(candidateData.AddressDetails.Apartment)}</p>
            {/* <p>В браке: {candidateData.SpousePerson.Education} с: {candidateData.SpousePerson.Citizenship}</p> */}
            {/* <p>Предыдущие браки у мужа: {} с: {} по: {}</p>
            <p>Предыдущие браки у жены: {} с: {} по: {}</p>
            <p>Детей: {}</p>
            <p>Совокупный доход семьи составляет: {}</p>
            <p>Медицинские противопоказания для усыновления:</p>
            <p>Муж(имеет, не имеет) Жена(имеет, не имеет)</p>
            <p>Жилищные условия {} установленным санитарным и техническим требованиям: {}</p> */}
            {/* <p style={{paddingLeft: 20}}>2. Информация о ребенке (о его личности, происхождении, причинах, по которым
              ребенок остался без попечения родителей, наличииу него родных братьев и сестер,
              взаимоотношениях и психического развития, каких-либо особых нуждах ребенка)
            </p> */}
            {/* <p style={{paddingLeft: 20}}>3. Информация о факте личного знакомства кандидатов в усыновители с ребенком
              (когда и как познакомились с ребенком, установили ли с ним хорошийконтакт, были ли 
              ознакомлены с документами, подтверждающими правовой статус ребенка, с заключением
              врачебно - консультационной комиссии о состоянии здоровья ребенка)
            </p> */}
            <Table size="small">
              <TableHead style={{backgroundColor: "#D7E3DD"}}>
                <TableRow>
                  <TableCell style={{fontWeight:"bold"}}>Статус приказа</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Причина отклонения</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{checkRejectedOrder()}</TableCell>
                  <TableCell>{checkForUndefined(currentOrder.RejectReason)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
           
          </div>
          {buttons.map((button, index)=>
            <Button
              key={index}
              name={button.name}
              variant="outlined"
              value={button.name}                               
              onClick = {() => buttonClick(button.name, null)}
              style={{
                  margin: 1,
                  fontSize: 11,
                  backgroundColor: button.backgroundColor
              }}
            >{button.label}
            </Button>
          )}
          {/* <Pdf targetRef={ref} filename="Приказ.pdf">
            {({ toPdf }) => <Button 
             key={"index"}
             variant="outlined"                          
             onClick={toPdf}
             style={{
              margin: 1,
              fontSize: 11,
              backgroundColor: "#D1FBD1"
             }}
            >Скачать приказ</Button>}
          </Pdf> */}
        </Card>
      </Grid>
    )  
              
  }
  else{
    return(
      <div align="center" style={{paddingTop: 250}}>
        <CircularProgress />
      </div>
    )
  }
};
