import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import AppBar from "@material-ui/core/AppBar";
import Toolbar, { styles as toolbarStyles } from "../components/Toolbar";
import { useKeycloak } from "../../../lib";

const styles = (theme) => ({
  title: {
    fontSize: 16,
  },
  placeholder: toolbarStyles(theme).root,
  toolbar: {
    justifyContent: "space-between",
    backgroundColor: "#272724",
  },
  left: {
    flex: 1,
  },
  leftLinkActive: {
    color: theme.palette.common.white,
  },
  right: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
  rightLink: {
    fontSize: 14,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3),
  },
  linkSecondary: {
    color: theme.palette.secondary.main,
  },
});

function MainAppBar(props) {
  const { classes } = props;
  const { keycloak } = useKeycloak();

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <div className={classes.left} />
          <Link
            variant="h4"
            underline="none"
            color="inherit"
            className={classes.title}
            // href="/premium-themes/onepirate/"
            style={{ "font-family": "Times New Roman" }}
          >
            {"Портал предоставления услуг по усыновлению"}
          </Link>
          <div className={classes.right}>
            <Link
              color="inherit"
              variant="h4"
              underline="none"
              className={classes.rightLink}
              onClick={() => keycloak.logout()}
              href="/login"
            >
              {"Войти"}
            </Link>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.placeholder} />
    </div>
  );
}

MainAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MainAppBar);
