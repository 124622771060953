import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function AppFooter() {

  return (
    <Typography variant="body2" color="textSecondary" align="center" style={{paddingBottom: 40}}>
        {"Copyright © " + new Date().getFullYear() +" Intersoft Ltd.All rights reserved."}
      </Typography>
  );
}
