import React, { useState} from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
// import Typography from '../components/Typography';
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import Container from "@material-ui/core/Container";
import { Button } from "@material-ui/core";

const backgroundImage = "/mainBackgroundImage.png";
const styles = (theme) => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: "#7fc7d9", // Average color of the background image.
    backgroundPosition: "center",
  },
  h5: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(8),
    },
  },
  root: {
    color: theme.palette.common.white,
    position: "relative",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      height: "80vh",
      minHeight: 700,
      maxHeight: 1300,
    },
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(14),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  backdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.5,
    zIndex: -1,
  },
  backgroundClass: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    zIndex: -2,
  },
});

function MainVisitCardComponent(props) {
  const { classes } = props;
  const [portalAPI] = useState(props.portalAPI)
  const [keycloakAPI] = useState(props.keycloakAPI)

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Typography
          color="inherit"
          align="center"
          variant="h2"
          marked="center"
          style={{ "font-family": "Times New Roman" }}
        >
          Добро пожаловать
        </Typography>
        <Typography
          color="inherit"
          align="center"
          variant="h4"
          className={classes.h5}
          style={{ "font-family": "Times New Roman" }}
        >
          На портал предоставления услуг по усыновлению.
        </Typography>
        <Button
          component="a"
          href={portalAPI + "/ChildrenAdoptionApi/CreateApplication"}
          variant="outlined"
          style={{
            // backgroundColor: "#3F3FBF",
            background: "linear-gradient(80deg, #020D26 30%, #0D4BD2 90%)",
            "border-radius": "8px",
            fontSize: 19,
            color: "white",
          }}
        >
          Подать заявку
        </Button>
        <div className={classes.backdrop} />
        <div className={clsx(classes.background, classes.backgroundClass)} />
      </Container>
    </section>
  );
}

MainVisitCardComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MainVisitCardComponent);
